import PropTypes from 'prop-types';

function ImageSection({ isLoginPage, imgSrc }) {
  return (
    <main className={`${isLoginPage ? 'md:h-[58.8rem]' : 'md:h-[59.9rem]'} h-[15rem] w-full md:w-1/2`}>
      <figure className="w-full h-full shadow-containerShadow rounded-md md:rounded-2xl">
        <img
          src={imgSrc}
          alt="Creative Ideas"
          className="w-full h-full object-cover rounded-md md:rounded-2xl"
        />
      </figure>
    </main>
  );
}

ImageSection.propTypes = {
  isLoginPage: PropTypes.bool,
  imgSrc: PropTypes.string,
};

export default ImageSection;
