import templateImage from '../assets/images/template-image.png';
import templateImage2 from '../assets/images/template-image-2.png';
import templateImage3 from '../assets/images/template-image-3.png';
import templateImage4 from '../assets/images/template-image-4.png';
import templateImage5 from '../assets/images/template-image-5.png';
import templateImage6 from '../assets/images/template-image-6.png';
import templateImage7 from '../assets/images/template-image-7.png';
import templateImage8 from '../assets/images/template-image-8.png';

const templatePageDataUnNested = [
  {
    id: 1,
    url: templateImage,
  },
  {
    id: 2,
    url: templateImage2,
  },
  {
    id: 3,
    url: templateImage3,
  },
  {
    id: 4,
    url: templateImage4,
  },
  {
    id: 5,
    url: templateImage5,
  },
  {
    id: 6,
    url: templateImage6,
  },
  {
    id: 7,
    url: templateImage7,
  },
  {
    id: 8,
    url: templateImage8,
  },
  {
    id: 9,
    url: templateImage,
  },
];

const templateTabsData = [
  {
    id: 1,
    linkText: 'General',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
      {
        id: 5,
        url: templateImage5,
      },
      {
        id: 6,
        url: templateImage6,
      },
      {
        id: 7,
        url: templateImage7,
      },
      {
        id: 8,
        url: templateImage8,
      },
      {
        id: 9,
        url: templateImage,
      },
    ],
  },
  {
    id: 2,
    linkText: 'Business',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 3,
    linkText: 'Education',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 4,
    linkText: 'Fashion',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 5,
    linkText: 'Food',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 6,
    linkText: 'Real Estate',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 7,
    linkText: 'Technology',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 8,
    linkText: 'Health',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 9,
    linkText: 'Sports',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 10,
    linkText: 'Travel',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 11,
    linkText: 'Science',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 12,
    linkText: 'Art',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 13,
    linkText: 'Music',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 14,
    linkText: 'Movies',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 15,
    linkText: 'Books',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 16,
    linkText: 'Gaming',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 17,
    linkText: 'Nature',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 18,
    linkText: 'Pets',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 19,
    linkText: 'Home Decor',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
  {
    id: 20,
    linkText: 'Automotive',
    images: [
      {
        id: 1,
        url: templateImage,
      },
      {
        id: 2,
        url: templateImage2,
      },
      {
        id: 3,
        url: templateImage3,
      },
      {
        id: 4,
        url: templateImage4,
      },
    ],
  },
];

const tabStyling = {
  content: 'before:content-[""] before:block before:w-full before:absolute before:left-0 before:top-0',
  active: 'before:h-[4px] before:bg-stemGreen',
  inactive: 'before:h-[.3px] before:bg-[#686B6E]',
};

const activeTabStyling = `${tabStyling.content} ${tabStyling.active}`;
const inactiveTabStyling = `${tabStyling.content} ${tabStyling.inactive}`;

const numbersArray = Array.from({ length: 20 }, (_, index) => index);

export { templatePageDataUnNested, templateTabsData, activeTabStyling, inactiveTabStyling, numbersArray };
