import { createElement } from 'react';
import featuresPageImgOne from '../assets/images/features-img-1.png';
import featuresPageImgTwo from '../assets/images/features-img-2.png';
import featuresPageImgThree from '../assets/images/features-img-3.png';
import { FeaturesPageSvg1, FeaturesPageSvg2, FeaturesPageSvg3 } from '../assets/svgs/svgs';

const featuresPageData = [
  {
    id: 1,
    imgSrc: featuresPageImgOne,
    alText: 'first image on features page',
    svgIcon: createElement(FeaturesPageSvg1),
    h3Text: 'Powerful editing tools',
    pText: 'We are dedicated to putting the power of professional-grade editing in your hands. Our platform is equipped with a comprehensive rage of powerful editing tools that cater to all levels of expertise.',
    flexOrder: '',
  },
  {
    id: 2,
    imgSrc: featuresPageImgTwo,
    alText: 'Second image on features page',
    svgIcon: createElement(FeaturesPageSvg2),
    h3Text: 'Dynamic animation studio',
    pText:
      'Elevate your videos with motion graphics. With our cutting-edge dynamic animation studio, you now have the power to infuse your videos with captivating motion graphics that make your content come alive...',
    flexOrder: 1,
  },
  {
    id: 3,
    imgSrc: featuresPageImgThree,
    alText: 'first img on features page',
    svgIcon: createElement(FeaturesPageSvg3),
    h3Text: 'Intuitive user interface',
    pText: 'Welcome to Swiftsketch where creativity meets simplicity! With our intuitive user interface, we have reimagined the video editing process to ensure that everyone, from beginners, to experts, can effortlessly bring their vision to life.',
    flexOrder: '',
  },
];

export default featuresPageData;
