import ContactUsLeft from '../components/contact-us-components/ContactUsLeft';
import ContactUsRight from '../components/contact-us-components/ContactUsRight';

function ContactUsPage() {
  return (
    <main className="flex w-fit lg:w-full flex-col lg:flex-row gap-12 px-4 md:px-16 pt-56 pb-16 bg-generalBlack">
      <ContactUsLeft />
      <ContactUsRight />
    </main>
  );
}

export default ContactUsPage;
