import { createElement } from "react";
import { FaFacebook, FaGooglePlay } from "react-icons/fa";

const getStartedButtonData = [
  {
    id: 1,
    BtnExtaStyles: "bg-stemGreen text-white whitespace-nowrap",
    BtnFirstChild: createElement(FaGooglePlay, { className: "mr-2" }),
    Btnh4Text: "Get It on",
    Btnh5Text: "Google Play",
  },
  {
    id: 2,
    BtnExtaStyles:
      "bg-white outline outline-[1px] outline-white text-stemGreen whitespace-nowrap",
    BtnFirstChild: createElement(FaFacebook, { className: "mr-2" }),
    Btnh4Text: "Download on",
    Btnh5Text: "Apple Store",
  },
];

export default getStartedButtonData;
