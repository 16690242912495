import { createElement } from 'react';
import PropTypes from 'prop-types';
import { DividerLine } from '../../pages/RegisterPage';

const EmailRegister = ({ isCheckOut, checkOutText }) => (
  <div className={`${isCheckOut ? '-translate-y-4' : 'mt-4 mb-8'} flex items-center`}>
    {createElement(DividerLine)}
    <h4 className="mx-4 text-gray-500 font-nunitoLight">{checkOutText}</h4>
    {createElement(DividerLine)}
  </div>
);

EmailRegister.propTypes = {
  isCheckOut: PropTypes.bool,
  checkOutText: PropTypes.string,
};

export default EmailRegister;
