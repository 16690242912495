import { createElement, useEffect, useState } from "react";
import PricingCard from "./PricingCard";
import { FaToggleOn, FaToggleOff } from "react-icons/fa";
import { PricingSvg } from "../../assets/svgs/svgs";
import { useDispatch } from "react-redux";
import { getPackages } from "../../baseURL";
import { setPackages } from "../../redux/slices/packageSlice";
import { jwtDecode } from "jwt-decode";

function Package({ user, page }) {
  const [isToggled, setIsToggled] = useState(false);
  const [loading, setloading] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchPackages = async () => {
      const response = await getPackages();
      if (response) {
        dispatch(setPackages(response));
        setloading(false);
      }
    };
    fetchPackages();
  }, [dispatch]);
  const handleToggle = () => {
    setIsToggled(!isToggled);
  };

  return (
    <div className="bg-generalBlack pt-24 md:pt-16 pb-8">
      <div className="flex flex-col md:flex-row gap-4 md:gap-6 items-center justify-center">
        <div className="w-fit flex flex-row gap-6">
          <h4 className="text-base font-nunitoSemiBold text-white">
            Pay Monthly
          </h4>
          <div
            className="relative w-fit hover:cursor-pointer"
            onClick={handleToggle}
          >
            {isToggled ? (
              <FaToggleOn
                size={32}
                className="-translate-y-[0.2rem] text-stemGreen"
              />
            ) : (
              <FaToggleOff
                size={32}
                className="-translate-y-[0.2rem] text-white"
              />
            )}
          </div>
          <h4 className="text-base font-nunitoSemiBold text-white">
            Pay Yearly
          </h4>
        </div>

        <div className="flex flex-row items-end">
          {createElement(PricingSvg)}
          <p className="text-stemGreen text-xs font-nunitoBold pb-5">
            Save 20%
          </p>
        </div>
      </div>
      <PricingCard
        isToggled={isToggled}
        loading={loading}
        user={user}
        page={page}
      />
    </div>
  );
}

export default Package;
