import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { DropdownIcon } from "../../assets/svgs/svgs";
import { pathArr } from "../../data/navbar-data";
import { FaPlus } from "react-icons/fa";

function Dropdown(props) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);

  const location = useLocation();
  const { pathname } = location;

  const useOutsideClick = (ref, callback) => {
    const handleClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        callback();
      }
    };

    useEffect(() => {
      document.addEventListener("click", handleClick);
      return () => document.removeEventListener("click", handleClick);
    }, [ref, callback]);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
    props.getDropdownClickedState(isDropdownOpen);
  };

  useOutsideClick(dropdownRef, closeDropdown);

  return (
    <div className="relative order-4 md:order-2" ref={dropdownRef}>
      <div
        className={`${
          !props.create
            ? "w-fit flex items-center cursor-pointer pl-4 pr-2 md:pl-4 md:px-4 py-2 hover:bg-white hover:rounded-md hover:text-stemGreen"
            : "bg-stemGreen text-white text-sm px-8 py-[10px] flex items-center gap-3 rounded cursor-pointer"
        }`}
        onClick={() => toggleDropdown()}
      >
        {props.create && (
          <span>
            <FaPlus />
          </span>
        )}
        <span
          className={`${
            props.text === "Products" && pathArr[0].includes(pathname)
              ? "text-stemGreen"
              : props.text === "Discover" && pathArr[1].includes(pathname)
              ? "text-stemGreen"
              : props.text === "Create New Project" && "text-white"
          } mr-2`}
        >
          {props.text}
        </span>
        {!props.create && (
          <span>
            <DropdownIcon />
          </span>
        )}
      </div>
      {isDropdownOpen && (
        <ul className="absolute top-full left-0 bg-black text-white rounded shadow-lg mt-1 z-[999]">
          {props.dropdownListData.map((listData) => (
            <Link
              to={listData.linkPath}
              key={listData.id}
              className={`${
                listData.id === 1
                  ? "hover:rounded-t"
                  : listData.id === 3
                  ? "hover:rounded-b"
                  : "hover:rounded-none"
              } py-2 pl-4 pr-3 md:pl-6 md:pr-[1.28rem] hover:bg-white hover:text-stemGreen transition flex flex-row whitespace-nowrap`}
              onClick={() => closeDropdown()}
            >
              <span>{listData.linkText}</span>
            </Link>
          ))}
        </ul>
      )}
    </div>
  );
}

Dropdown.propTypes = {
  text: PropTypes.string,
  create: PropTypes.bool,
  dropdownListData: PropTypes.array,
  getDropdownClickedState: PropTypes.func,
};

export default Dropdown;
