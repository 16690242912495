import { Bounce, toast } from 'react-toastify';

const RenderErrorToast = (displayText) => {
  toast.error(displayText, {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
};

const RenderSuccessToast = (displayText) => {
  toast(displayText, {
    position: 'top-right',
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
    transition: Bounce,
  });
};

export { RenderErrorToast, RenderSuccessToast };
