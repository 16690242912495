import PropTypes from 'prop-types';

function BonusNotification({ isFreeText, isBlogsPage }) {
  return (
    <div className={`${isBlogsPage ? 'left-4 top-4 ' : '-right-3 -top-5 '} animate-pulse absolute h-fit w-fit`}>
      <button
        className={`${
          isBlogsPage ? '' : ''
        } outline-none rounded-xl px-2 py-3 text-xs font-nunitoRegular text-white bg-stemGreen transition`}
      >
        {isFreeText}
      </button>
    </div>
  );
}

BonusNotification.propTypes = {
  isFreeText: PropTypes.string,
  isBlogsPage: PropTypes.bool,
};

export default BonusNotification;
