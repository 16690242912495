import PropTypes from "prop-types";
import { SocialData } from "../../data/register-data";
import { googleAuth, loginfacebook, logingoogle } from "../../baseURL";
import axios from "axios";

const SocialButton = ({
  icon,
  text,
  bgColor,
  isCheckOut,
  checkoutText,
  checkoutBgColor,
  handleSubmit,
}) => {
  return (
    <button
      className={`w-full ${
        isCheckOut ? `${checkoutBgColor} md:w-full` : `${bgColor} md:w-1/2`
      } flex justify-center items-center space-x-2  text-black py-4 px-3 font-nunitoRegular rounded-md`}
      onClick={handleSubmit}
    >
      {icon}
      <span>{isCheckOut ? checkoutText : text}</span>
    </button>
  );
};

const SocialButtons = ({ isCheckOut, baseurl }) => {
  const handleLogin = async (e, type) => {
    e.preventDefault();
    if (type == "google") {
      try {
        const response = await googleAuth();
        if (response.status == 200) {
          window.open(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    } else window.open(`${baseurl}/auth/facebook`);
    // const response = (await type) == "google" ? logingoogle() : loginfacebook();
    // console.log(response);

    // location.href = "https://swiftsketch.onrender.com/api/v1/auth/google";
  };

  return (
    <form
      className={`${
        isCheckOut
          ? "flex-col space-y-3 md:gap-4"
          : "flex-col space-y-4 md:flex-row md:space-x-4"
      } mb-8 flex md:space-y-0`}
    >
      {SocialData().map((data, index) => (
        <SocialButton
          key={index}
          handleSubmit={(e) => handleLogin(e, data.type)}
          {...data}
          isCheckOut={isCheckOut}
        />
      ))}
    </form>
  );
};

SocialButton.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.string,
  bgColor: PropTypes.string,
  checkoutText: PropTypes.string,
  isCheckOut: PropTypes.bool,
  checkoutBgColor: PropTypes.string,
  handleSubmit: PropTypes.func,
};

SocialButtons.propTypes = {
  isCheckOut: PropTypes.bool,
};

export default SocialButtons;
