import { useState } from 'react';
import ForgotPasswordInputs from '../data/forgot-password-data';
import { CgMail } from 'react-icons/cg';
import ForgotPasswordTop from '../components/forgot-password-components/ForgotPasswordTop';
import { resetPassword } from '../baseURL';
import { FullLoader } from '../components/globals/Loader';
import GlobalModal from '../components/globals/Modal';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ForgotPasswordPage() {
  const [formData, setFormData] = useState({
    email: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [text, setText] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log({ formData });
    if (formData.email.trim() == '') {
      return;
    }
    setIsLoading(true);
    const response = await resetPassword(formData);
    if (response.status == 200 || response.status == 201) {
      setIsLoading(false);
      setText(response.data.message);
      setShowModal(true);
    } else {
      toast.error('User does not exist', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <main className="h-[100vh] flex bg-generalBlack items-center justify-center">
        {showModal && <GlobalModal modalText={text} clickFunction={() => setShowModal(false)} />}
        {isLoading && <FullLoader loaderText="Please wait" />}
        <div className="bg-[#140B2A99] shadow-containerShadow w-[93%] mx-auto md:w-fit rounded-md py-12 px-8 md:px-8 flex flex-col gap-4">
          <ForgotPasswordTop
            h1Text="Forgot Password"
            h4Text="Enter your email to start reset password process"
            route="/login"
          />
          <form className="pt-4 flex flex-col gap-6" onSubmit={handleSubmit}>
            <aside className="font-nunitoRegular mb-4">
              {ForgotPasswordInputs().map((input) => (
                <div key={input.key} className="w-full flex flex-col">
                  <label className="mb-3 text-white">{input.name}</label>
                  <div className="relative flex">
                    <input
                      className="flex-1 text-black rounded-sm pl-10 pr-3 py-3"
                      type={input.type}
                      placeholder={input.placeholder}
                      name={input.key}
                      value={formData[input.key]}
                      onChange={handleChange}
                      required
                    />
                    <CgMail className="absolute left-2 top-[12px] text-generalBlack text-2xl" />
                  </div>
                </div>
              ))}
            </aside>
            <button type="submit" className="font-nunitoBold w-full bg-stemGreen text-white p-4 rounded-md">
              Get Password Reset link
            </button>
          </form>
        </div>
      </main>
    </>
  );
}

export default ForgotPasswordPage;
