import PropTypes from "prop-types";

function DashboardHeaderText({ text, isColored }) {
  return (
    <h2 className="md:text-3xl sm:text-lg font-nunitoExtraBold text-[#CCCCCC]">
      {text}
    </h2>
  );
}

DashboardHeaderText.propTypes = {
  text: PropTypes.string,
  isColored: PropTypes.bool,
};

export default DashboardHeaderText;
