import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  packages: [],
};

const packageSlice = createSlice({
  name: "packages",
  initialState,
  reducers: {
    setPackages(state, action) {
      state.packages = action.payload;
    },
  },
});

export const { setPackages } = packageSlice.actions;
export default packageSlice.reducer;
