import { useSelector } from 'react-redux';
import { IoMdArrowDropright } from 'react-icons/io';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function LeftSidebar({ leftSidebarData }) {
  const { pathname } = window.location;

  const { isLeftSidebarOpen } = useSelector((state) => state.leftSidebar);

  return (
    <section
      className={`${isLeftSidebarOpen ? 'flex' : 'hidden'} fixed h-screen w-[22%] bg-editorLight text-white py-4 px-8`}
    >
      <main className="h-fit flex flex-col ">
        <aside className="pl-3 pb-2 flex items-center justify-between overflow-x-hidden">
          <h4 className="font-nunitoBold text-xl text-stemGreen">Menu</h4>
        </aside>
        {leftSidebarData.map((leftData) => (
          <Link
            to={leftData.path}
            key={leftData.id}
            className={`py-[.85rem] flex gap-12 transition-all hover:bg-[#686B6E]/80 hover:text-white hover:text-opacity-60 hover:rounded-md items-center justify-between ${
              pathname.startsWith(leftData.path) && 'bg-[#686B6E]/50 text-white text-opacity-60 rounded-md'
            }`}
          >
            <div className="pl-3 font-nunitoRegular whitespace-nowrap text-sm">{leftData.text}</div>
            <div className="pr-2">
              <IoMdArrowDropright size={20} />
            </div>
          </Link>
        ))}
      </main>
    </section>
  );
}

LeftSidebar.propTypes = {
  leftSidebarData: PropTypes.array,
};

export default LeftSidebar;
