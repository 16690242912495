import { combineReducers } from "@reduxjs/toolkit";
import leftSidebarReducer from "./slices/left-sidebar-slice";
import videoExportOrShareReducer from "./slices/export-video-slice";
import packageReducer from "./slices/packageSlice";
import recentActivitiesReducer from "./slices/recent-activities";
import loaderSlice from "./slices/loader-slice";

const rootReducer = combineReducers({
  leftSidebar: leftSidebarReducer,
  videoExportOrShare: videoExportOrShareReducer,
  packages: packageReducer,
  recentActivities: recentActivitiesReducer,
  loader: loaderSlice,
});

export default rootReducer;
