import Button from '../globals/Button';
import blogsBottomImg from '../../assets/images/blogs-bottom-img.png';

// Added flex-1 to the two divs nested within the main tags (for 2xl screen)
function BlogsBottom() {
  return (
    <section className="bg-generalBlack">
      <aside className="bg-waveBg h-[80px] md:h-[160px] bg-bottom bg-contain md:bg-cover bg-no-repeat"></aside>
      <aside className="bg-editorLight pb-24">
        <main className="w-[80%] mx-auto flex flex-col md:flex-row gap-8 md:gap-20">
          <div className="flex-1 m-2 pt-6 md:pt-12">
            <h5 className="font-nunitoBold text-white text-lg pb-8 md:pb-6">
              Get our stories delivered from us to your inbox weekly. Subscribe.
            </h5>
            <form className="flex flex-col md:flex-row md:justify-between md:items-center gap-4">
              <input
                type="text"
                className="flex-1 text-black rounded-sm px-3 py-[0.9rem] font-nunitoRegular placeholder:font-nunitoRegular"
              />
              <div>
                <Button btnText="Get Started" isLight isBig />
              </div>
            </form>
          </div>
          <div className="flex-1 p-3 rounded-lg bg-white">
            <figure className="pb-4">
              <img src={blogsBottomImg} alt="Blogs bottom Image" className="h-full w-full" />
            </figure>
            <h6 className="font-nunitoBold text-base text-[#183B56]">The best articles every week</h6>
          </div>
        </main>
      </aside>
    </section>
  );
}

export default BlogsBottom;
