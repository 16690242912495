import { Fragment, useState } from 'react';
import { policyContentData, termsButtonData } from '../data/termsAndConditionsData';

function TermsAndConditionsPage() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="px-2 md:px-32 py-32 pb-1 bg-generalBlack">
      <div className="w-full py-10 md:p-20 px-2">
        <div className="mx-auto grid grid-cols-3 gap-2 md:gap-8">
          {termsButtonData.map((button) => (
            <button
              key={button.id}
              className={`${
                activeTab === button.step ? 'bg-[#eb01ea]' : 'bg-[#541b5c]'
              } text-sm md:text-base font-nunitoSemiBold text-white px-4 py-2 rounded transition duration-200 ease-in-out hover:scale-103 focus:outline-none`}
              onClick={() => handleTabClick(button.step)}
            >
              {button.btnText}
            </button>
          ))}
        </div>
      </div>
      <div className="bg-[#140B2A99] tab-content px-4 md:px-16 pt-16 pb-8 md:pb-8 rounded-md">
        {policyContentData.map((policy) => (
          <Fragment key={policy.id}>{activeTab === policy.id && policy.PolicyTag}</Fragment>
        ))}
      </div>
    </div>
  );
}

export default TermsAndConditionsPage;
