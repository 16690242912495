const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-4 w-4">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-5.2-5.2" />
    <circle cx="10" cy="10" r="7" />
  </svg>
);

const DropdownIcon = () => (
  <svg className="w-4 h-4 fill-current text-gray-600" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path d="M10 12l-6-6h12l-6 6z" />
  </svg>
);

const FeaturesPageSvg1 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="70" height="71" viewBox="0 0 70 71" fill="none">
    <path
      d="M56.1972 0.664978H13.8028C6.17973 0.664978 0 6.84471 0 14.4678V56.8622C0 64.4852 6.17973 70.665 13.8028 70.665H56.1972C63.8203 70.665 70 64.4852 70 56.8622V14.4678C70 6.84471 63.8203 0.664978 56.1972 0.664978Z"
      fill="white"
    />
    <path
      d="M45.0968 19.8319V50.9589C45.0968 53.9168 42.6903 56.323 39.7318 56.323H15.2241C12.2657 56.323 9.85915 53.9168 9.85915 50.9589V19.8319C9.85915 16.874 12.2657 14.4678 15.2241 14.4678H39.7317C42.6902 14.4678 45.0968 16.874 45.0968 19.8319Z"
      fill="#FF5C5C"
    />
    <path
      d="M45.0968 19.8319V50.9589C45.0968 53.9168 42.6903 56.323 39.7318 56.323L27.4785 56.323V14.4678H39.7317C42.6902 14.4678 45.0968 16.874 45.0968 19.8319Z"
      fill="#E20059"
    />
    <path
      d="M40.5911 20.5368V37.6303L27.4785 40.4961L14.3649 43.3619V20.5368C14.3649 19.7077 15.0369 19.0348 15.8672 19.0348H39.0889C39.9191 19.0348 40.5911 19.7077 40.5911 20.5368Z"
      fill="white"
    />
    <path
      d="M40.5911 20.5368V37.6303L27.4785 40.4961V19.0348H39.0888C39.9191 19.0348 40.5911 19.7077 40.5911 20.5368Z"
      fill="#C8FFFF"
    />
    <path
      d="M40.5911 37.6303V50.3962C40.5911 51.2263 39.9191 51.8982 39.0888 51.8982H15.8672C15.037 51.8982 14.3649 51.2263 14.3649 50.3962V43.3619L20.3639 36.4197C20.9087 35.858 21.794 35.8079 22.398 36.3056L26.2097 38.6206L32.1996 30.3758C32.473 29.9993 32.9046 29.771 33.3704 29.757C33.8371 29.7419 34.2808 29.9462 34.5761 30.3057L40.5911 37.6303Z"
      fill="#ACE233"
    />
    <path
      d="M20.9669 23.3099C18.9482 23.3099 17.3059 24.9493 17.3059 26.9646C17.3059 28.9797 18.9482 30.6192 20.9669 30.6192C22.9856 30.6192 24.6279 28.9797 24.6279 26.9646C24.6279 24.9493 22.9857 23.3099 20.9669 23.3099Z"
      fill="#FFDC48"
    />
    <path
      d="M61.1268 35.4074C61.1268 46.8134 58.4948 55.6811 58.4688 55.7693C58.3696 56.0977 58.0661 56.323 57.7227 56.323H55.4262C55.0817 56.323 54.7793 56.0977 54.6802 55.7693C54.6541 55.6811 52.0222 46.8134 52.0222 35.4074C52.0222 31.1768 53.0577 27.483 53.5084 26.0771L56.574 22.0718L59.6405 26.0771C60.0902 27.483 61.1268 31.1778 61.1268 35.4074Z"
      fill="#FFDC48"
    />
    <path
      d="M61.1268 35.4074C61.1268 46.8134 58.4948 55.6811 58.4688 55.7693C58.3696 56.0977 58.0661 56.323 57.7227 56.323H56.574V22.0718L59.6405 26.0771C60.0902 27.483 61.1268 31.1778 61.1268 35.4074Z"
      fill="#FFC200"
    />
    <path
      d="M40.5911 37.6303V50.3962C40.5911 51.2263 39.9191 51.8982 39.0888 51.8982H27.4785V36.8743L32.1995 30.3758C32.4729 29.9993 32.9045 29.771 33.3703 29.757C33.837 29.7419 34.2807 29.9462 34.576 30.3057L40.5911 37.6303Z"
      fill="#00CB75"
    />
    <path
      d="M61.1268 22.4703C61.1268 22.5935 61.1157 24.8905 59.6405 26.0771H53.5083C52.0281 24.8865 52.0211 22.5785 52.0221 22.4693C52.0221 20.9924 52.701 19.2311 54.0411 17.2405C55.0086 15.8026 55.967 14.7802 56.007 14.7372C56.1542 14.5809 56.3596 14.4918 56.5739 14.4918C56.7891 14.4918 56.9945 14.5809 57.1417 14.7372C57.1818 14.7802 58.1392 15.8026 59.1066 17.2405C60.4468 19.2301 61.1258 20.9894 61.1268 22.4703Z"
      fill="#2A5959"
    />
    <path
      d="M61.1268 22.4703C61.1268 22.5935 61.1157 24.8905 59.6405 26.0771H56.574V14.4918C56.7892 14.4918 56.9946 14.5809 57.1418 14.7372C57.1819 14.7802 58.1393 15.8026 59.1067 17.2405C60.4468 19.2301 61.1258 20.9894 61.1268 22.4703Z"
      fill="#2B4747"
    />
  </svg>
);

const FeaturesPageSvg2 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="70" height="76" viewBox="0 0 70 76" fill="none">
    <path
      d="M4.42884 72.3242C3.40837 71.271 3.55883 30.2689 4.42884 29.1503C5.02739 28.3849 65.4545 28.3849 66.6581 29.1503C67.7702 29.8567 67.4693 71.6471 66.6581 72.3242C64.8592 73.8123 5.6979 73.6325 4.42884 72.3242Z"
      fill="#191919"
    />
    <path
      d="M36.8093 75.9907H35.3407C27.4582 75.9907 20.0074 75.8762 14.3523 75.7028C3.73872 75.3758 3.36585 74.9898 2.54816 74.1492C1.73048 73.3086 1.06324 72.6054 1.10249 50.7568C1.10249 45.2979 1.18426 40.1073 1.30201 36.1431C1.52769 28.6204 1.78935 28.2933 2.36827 27.5443C3.22194 26.4519 4.36016 26.4061 7.1174 26.2916C8.79203 26.2229 11.1731 26.1608 14.192 26.1117C19.785 26.0201 27.275 25.9678 35.3178 25.9678C43.3606 25.9678 50.8898 26.0201 56.5548 26.1117C67.0212 26.2818 67.2992 26.465 68.0613 26.949C69.3696 27.7765 69.5135 29.1928 69.6803 31.8355C70.0074 37.1669 70.0434 47.944 69.9649 55.5517C69.7817 73.1386 69.4154 73.4427 68.3295 74.3422C67.5314 74.9964 67.0016 75.4412 56.4338 75.7552C51.1809 75.9056 44.2568 75.9907 36.8093 75.9907ZM6.70202 70.1033C15.3466 70.9602 55.8189 70.9733 64.3359 70.1262C64.7971 63.5095 64.9901 38.3999 64.4373 31.5314C55.5507 31.0669 15.7555 31.0669 6.71837 31.5314C6.20159 38.5504 6.18851 63.5847 6.70202 70.1033Z"
      fill="#191919"
    />
    <path
      d="M4.42884 72.3242C3.40837 71.271 3.55883 30.2689 4.42884 29.1503C5.02739 28.3849 65.4545 28.3849 66.6581 29.1503C67.7702 29.8567 67.4693 71.6471 66.6581 72.3242C64.8592 73.8123 5.6979 73.6325 4.42884 72.3242Z"
      fill="#E83A2A"
    />
    <path
      d="M67.0441 36.1398C67.2861 35.7801 67.3515 25.9351 66.717 25.6734C64.8854 24.8688 5.81237 24.9244 4.3111 25.8337C3.74199 26.1608 3.6962 35.7474 3.95131 36.1529C4.20643 36.5585 66.802 36.4996 67.0441 36.1398Z"
      fill="#191919"
    />
    <path
      d="M33.4077 39.0475C24.1286 39.0475 14.5093 39.0148 8.75605 38.9167C7.20898 38.8938 6.00862 38.8676 5.18439 38.8415C3.63405 38.7892 2.51218 38.7532 1.74029 37.5332C1.36415 36.9346 1.1352 36.5716 1.20061 31.6458C1.22678 29.5624 1.32163 26.8149 1.62254 25.559C1.70129 25.1546 1.8617 24.7706 2.09396 24.4303C2.32623 24.0901 2.62546 23.8008 2.97336 23.5802C3.70274 23.1419 4.10504 22.8998 14.3981 22.6872C19.9584 22.5728 27.4059 22.5008 35.3996 22.4845C43.2723 22.4845 50.7557 22.5106 56.4599 22.6022C66.6385 22.7625 67.1651 22.9914 67.7702 23.2564C68.1668 23.4337 68.5178 23.6991 68.7966 24.0323C69.0755 24.3655 69.2748 24.7579 69.3794 25.1796C69.4841 25.5393 69.8079 26.6285 69.834 31.4856C69.8602 36.6272 69.6214 36.9902 69.2191 37.5888C68.4276 38.7695 67.538 38.7924 65.1536 38.8513C63.9467 38.8807 62.2099 38.9102 59.9596 38.9331C55.7731 38.9789 50.0395 39.0116 43.3737 39.0312C40.2436 39.0443 36.8518 39.0475 33.4077 39.0475ZM6.41746 33.6573C16.0073 33.8634 54.9358 33.8503 64.5877 33.641C64.6172 31.9435 64.5878 29.5951 64.5027 28.0546C55.5278 27.5639 15.2975 27.6555 6.54502 28.1886C6.4469 29.703 6.40438 31.9958 6.41746 33.6573Z"
      fill="#191919"
    />
    <path
      d="M67.0441 36.1398C67.2861 35.7801 67.3515 25.9351 66.717 25.6734C64.8854 24.8688 5.81237 24.9244 4.3111 25.8337C3.74199 26.1608 3.6962 35.7474 3.95131 36.1529C4.20643 36.5585 66.802 36.4996 67.0441 36.1398Z"
      fill="white"
    />
    <path
      d="M3.27754 36.8267C3.27754 36.8267 15.9975 24.9081 15.8209 24.5254C15.5625 23.9825 3.39202 24.0283 3.11727 24.3848C2.84253 24.7413 0.971661 37.664 3.27754 36.8267Z"
      fill="#191919"
    />
    <path
      d="M66.4324 12.6101C66.6189 12.2176 65.2157 2.81417 64.5583 2.64082C62.622 2.12404 4.08869 10.6607 2.72805 11.7695C2.21455 12.2012 3.57845 21.6766 3.89244 22.0396C4.20643 22.4027 66.2493 13.0026 66.4324 12.6101Z"
      fill="#191919"
    />
    <path
      d="M4.03308 24.6562C3.63149 24.6816 3.22955 24.6123 2.85969 24.4538C2.48983 24.2952 2.1624 24.052 1.90382 23.7437C1.44592 23.2171 1.16791 22.89 0.503944 18.0003C0.222659 15.9364 -0.0880623 13.2021 0.0231432 11.9167C0.0409769 11.5048 0.142715 11.1009 0.322123 10.7297C0.501532 10.3585 0.754834 10.0278 1.06651 9.75797C1.72066 9.21503 2.09026 8.91739 12.2623 7.22314C17.7375 6.3106 25.1196 5.16911 33.0479 4.00472C40.8454 2.85996 48.2634 1.8264 53.9382 1.09376C64.0448 -0.214543 64.5943 -0.0608183 65.2321 0.112531C65.654 0.230138 66.0435 0.442378 66.3711 0.733122C66.6987 1.02387 66.9557 1.38546 67.1226 1.79043C67.5445 2.69969 68.0089 4.71121 68.5028 7.76936C69.2943 12.6755 69.1079 13.0778 68.7972 13.7319C68.1889 15.0141 67.3581 15.1613 64.8952 15.593C63.6654 15.8089 61.8828 16.1065 59.5966 16.4761C55.3184 17.1662 49.4867 18.0788 42.7326 19.1123C30.9317 20.9276 15.7129 23.1844 8.3145 24.1754C7.0062 24.3521 5.99226 24.4796 5.29559 24.5581C4.87689 24.6141 4.45541 24.6469 4.03308 24.6562ZM5.28578 13.7875C5.41007 15.3019 5.70771 17.5653 5.9661 19.2039C15.5003 17.9839 54.0854 12.1587 63.6261 10.507C63.4037 8.90431 63.0407 6.74888 62.73 5.32284C53.8106 6.11763 13.9075 11.9984 5.28578 13.7875Z"
      fill="#191919"
    />
    <path
      d="M66.4324 12.6101C66.6189 12.2176 65.2157 2.81417 64.5583 2.64082C62.622 2.12404 4.08869 10.6607 2.72805 11.7695C2.21455 12.2012 3.57845 21.6766 3.89244 22.0396C4.20643 22.4027 66.2493 13.0026 66.4324 12.6101Z"
      fill="white"
    />
    <path
      d="M2.72805 11.7695C2.72805 11.7695 15.9288 21.0421 15.8111 21.4411C15.6377 22.0167 3.5948 23.7601 3.26773 23.4461C2.94065 23.1321 0.320781 11.292 2.72805 11.7695Z"
      fill="#191919"
    />
    <path
      d="M15.1765 8.47911C15.1177 8.80618 29.4991 19.7207 30.222 19.5997L42.5952 17.7615C43.2232 17.6666 27.9096 6.25173 27.1769 6.35966C26.4443 6.4676 15.2354 8.14876 15.1765 8.47911Z"
      fill="#191919"
    />
    <path
      d="M40.6557 4.69158C40.5968 5.01866 54.9783 15.9331 55.7011 15.8121L68.0744 13.974C68.7024 13.8791 53.3887 2.4642 52.6561 2.57214C51.9234 2.68007 40.7145 4.36451 40.6557 4.69158Z"
      fill="#191919"
    />
    <path
      d="M17.1128 36.879C17.0081 36.552 29.6234 23.6554 30.3626 23.6554H42.8732C43.5078 23.6554 30.0454 37.1963 29.3029 37.1963C28.5604 37.1963 17.2175 37.1963 17.1128 36.879Z"
      fill="#191919"
    />
    <path
      d="M42.8732 36.879C42.7686 36.552 55.3839 23.6554 56.1231 23.6554C56.8622 23.6554 67.3614 23.868 67.3614 24.5025C67.3614 24.7151 55.8058 37.1996 55.0633 37.1996C54.3209 37.1996 42.9779 37.1963 42.8732 36.879Z"
      fill="#191919"
    />
    <path
      d="M36.3187 67.0092C31.3733 67.0092 26.4508 66.9699 22.8137 66.9045C16.2003 66.7835 15.9844 66.6429 15.5985 66.391C14.9639 65.9822 14.8004 65.3607 14.6728 62.9044C14.5616 60.7064 14.5289 57.2362 14.5943 54.0734C14.7154 48.1402 14.9901 47.9178 15.4905 47.5319C15.9386 47.1721 15.9746 47.1426 22.3591 47.0772C25.813 47.0413 30.4477 47.0216 35.4061 47.0216C54.4779 47.0216 54.9946 47.2277 55.2694 47.3487C56.1917 47.7183 56.3226 48.6341 56.4403 50.976C56.5482 53.151 56.5711 56.6442 56.4959 59.8757C56.4599 61.4326 56.4043 62.7768 56.3357 63.7581C56.2277 65.3313 56.1394 66.1032 55.3839 66.4957C55.0404 66.6363 54.3209 67.0092 36.3187 67.0092Z"
      fill="white"
    />
    <path
      d="M35.4061 48.3038C44.8913 48.3038 54.4124 48.379 54.7853 48.5262C55.4722 48.8009 55.289 65.0337 54.7853 65.2953C54.2816 65.557 45.3983 65.7042 36.3219 65.7042C26.647 65.7042 16.7138 65.5602 16.3082 65.2953C15.677 64.8865 15.7685 48.9612 16.3082 48.5262C16.4946 48.379 25.9275 48.3038 35.4061 48.3038ZM35.4061 45.6872C30.4411 45.6872 25.8032 45.7068 22.3427 45.7428C20.4751 45.7624 19.0033 45.7853 17.9665 45.8147C16.0629 45.8638 15.4218 45.8801 14.6663 46.4885C13.6262 47.3258 13.5412 48.5425 13.4365 50.0863C13.3678 51.0937 13.3155 52.4609 13.2828 54.0472C13.2173 57.2525 13.2501 60.7621 13.3645 62.9862C13.4725 65.1056 13.551 66.633 14.8854 67.4965C15.6083 67.9642 15.8012 68.0885 22.7908 68.2194C26.4345 68.288 31.3667 68.324 36.3219 68.324C41.1038 68.324 45.5356 68.288 48.8064 68.2259C55.0699 68.1016 55.3773 67.9414 56.002 67.6241C57.4412 66.8751 57.5458 65.3902 57.6538 63.8169C57.7225 62.8161 57.778 61.4555 57.8173 59.892C57.8925 56.6213 57.8696 53.1052 57.7584 50.8975C57.7159 50.0634 57.6636 49.4158 57.5982 48.935C57.5164 48.3365 57.3038 46.7436 55.7665 46.132C55.2138 45.9128 55.047 45.8442 48.548 45.7787C45.045 45.7428 40.3711 45.7231 35.4159 45.7231L35.4061 45.6872Z"
      fill="#191919"
    />
    <path
      d="M21.3484 52.6146C19.6313 52.6146 19.5495 55.1494 21.1849 55.2737C22.8203 55.398 47.1481 55.398 48.2929 55.2737C49.4376 55.1494 50.01 52.8207 48.1719 52.6996C46.3337 52.5786 23.4744 52.6146 21.3484 52.6146Z"
      fill="#191919"
    />
    <path
      d="M21.3484 58.0113C19.6313 58.0113 19.5495 60.5462 21.1849 60.6705C22.8203 60.7948 35.3734 60.7948 36.5182 60.6705C37.6629 60.5462 38.2353 58.2174 36.3972 58.0964C34.559 57.9754 23.4744 58.0113 21.3484 58.0113Z"
      fill="#191919"
    />
  </svg>
);

const FeaturesPageSvg3 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="70" height="71" viewBox="0 0 70 71" fill="none">
    <g clipPath="url(#clip0_696_3422)">
      <path
        d="M61.6948 1.51135C65.6101 1.51135 68.8135 4.71481 68.8135 8.63002V62.0198C68.8135 58.1046 65.6101 54.9011 61.6948 54.9011C57.7796 54.9011 54.5762 58.1046 54.5762 62.0198V8.63002C54.5762 4.71467 57.7796 1.51135 61.6948 1.51135Z"
        fill="#FDCC00"
      />
      <path
        d="M54.5763 9.81653V62.02C54.5763 58.1048 57.7797 54.9013 61.695 54.9013C65.6102 54.9013 68.8136 58.1048 68.8136 62.02V62.1387C68.8136 66.0539 65.6102 69.1387 61.8136 69.1387H1.18652V9.81653H54.5763Z"
        fill="#FFE100"
      />
      <path
        d="M61.6947 1.51135C61.1015 1.51135 60.5083 1.63002 59.915 1.7487C62.9998 2.57926 65.254 5.30803 65.254 8.63002V55.8504C67.3896 57.0368 68.8134 59.4097 68.8134 62.0198V8.63002C68.8134 4.71467 65.6101 1.51135 61.6947 1.51135Z"
        fill="#FFA800"
      />
      <path
        d="M61.6948 54.9011C61.1016 54.9011 60.5084 55.0198 59.9151 55.1385C62.9999 55.969 65.2541 58.6978 65.2541 62.0198V62.1385C65.2541 66.0537 62.0507 69.1385 58.2542 69.1385H61.8135C65.7287 69.1385 68.8135 65.935 68.8135 62.1385V62.0198C68.8135 58.1046 65.6102 54.9011 61.6948 54.9011Z"
        fill="#FFA800"
      />
      <path d="M1.18652 59.6469C9.01709 59.6469 15.4239 53.2401 15.4239 45.4095H1.18652V59.6469Z" fill="#00DA6C" />
      <path d="M1.18652 69.1385H4.74586V9.81653H1.18652V69.1385Z" fill="white" />
      <path
        d="M68.8134 63.2063C68.1015 63.2063 67.627 62.7317 67.627 62.0198C67.627 58.6978 65.0168 56.0876 61.6948 56.0876C58.3728 56.0876 55.7625 58.6978 55.7625 62.0198C55.7625 62.7317 55.288 63.2063 54.5761 63.2063C53.8642 63.2063 53.3896 62.7317 53.3896 62.0198V8.63007C53.3896 4.00296 57.0677 0.324951 61.6948 0.324951C66.3219 0.324951 69.9999 4.00296 69.9999 8.63007V62.0198C69.9999 62.7317 69.5253 63.2063 68.8134 63.2063ZM61.6948 53.7147C64.0677 53.7147 66.0845 54.6638 67.627 56.2063V8.63007C67.627 5.30808 65.0168 2.69784 61.6948 2.69784C58.3728 2.69784 55.7625 5.30808 55.7625 8.63007V56.2063C57.305 54.664 59.3219 53.7147 61.6948 53.7147Z"
        fill="black"
      />
      <path
        d="M61.8136 70.325H1.18645C0.474551 70.325 0 69.8505 0 69.1386V9.81657C0 9.10468 0.474551 8.63013 1.18645 8.63013H54.5762C55.2881 8.63013 55.7627 9.10468 55.7627 9.81657V56.2063C57.305 54.664 59.322 53.7148 61.6949 53.7148C66.322 53.7148 70 57.3928 70 62.0199C70 66.647 66.322 70.325 61.8136 70.325ZM2.37289 67.9521H61.8136C65.017 67.9521 67.6271 65.3419 67.6271 62.1386C67.6271 58.8166 65.0169 56.0877 61.6949 56.0877C58.3729 56.0877 55.7627 58.6979 55.7627 62.0199C55.7627 62.7318 55.2881 63.2063 54.5762 63.2063C53.8643 63.2063 53.3898 62.7318 53.3898 62.0199V11.003H2.37289V67.9521Z"
        fill="black"
      />
      <path
        d="M17.7965 39.4776H1.18645C0.474551 39.4776 0 39.003 0 38.2911V9.81657C0 9.10468 0.474551 8.63013 1.18645 8.63013H17.7967C18.5086 8.63013 18.9831 9.10468 18.9831 9.81657V19.3081C18.9831 20.02 18.5086 20.4946 17.7967 20.4946C17.0848 20.4946 16.6102 20.02 16.6102 19.3081V11.003H2.37289V37.1047H16.6102V28.7996C16.6102 28.0877 17.0848 27.6131 17.7967 27.6131C18.5086 27.6131 18.9831 28.0877 18.9831 28.7996V38.2911C18.983 39.003 18.5084 39.4776 17.7965 39.4776Z"
        fill="black"
      />
      <path
        d="M20.1695 20.4943H15.4238C14.7119 20.4943 14.2373 20.0198 14.2373 19.3079C14.2373 18.596 14.7119 18.1215 15.4238 18.1215H20.1695C20.8814 18.1215 21.356 18.596 21.356 19.3079C21.356 20.0198 20.8814 20.4943 20.1695 20.4943Z"
        fill="black"
      />
      <path
        d="M20.1695 29.9859H15.4238C14.7119 29.9859 14.2373 29.5114 14.2373 28.7995C14.2373 28.0876 14.7119 27.613 15.4238 27.613H20.1695C20.8814 27.613 21.356 28.0876 21.356 28.7995C21.356 29.5114 20.8814 29.9859 20.1695 29.9859Z"
        fill="black"
      />
      <path
        d="M32.0339 27.6131C31.322 27.6131 30.8474 27.1385 30.8474 26.4266V21.6808C30.8474 20.9689 31.322 20.4944 32.0339 20.4944C32.7458 20.4944 33.2203 20.9689 33.2203 21.6808V26.4266C33.2203 27.1385 32.7458 27.6131 32.0339 27.6131Z"
        fill="black"
      />
      <path
        d="M41.5256 27.6131C40.8137 27.6131 40.3391 27.1385 40.3391 26.4266V21.6808C40.3391 20.9689 40.8137 20.4944 41.5256 20.4944C42.2375 20.4944 42.712 20.9689 42.712 21.6808V26.4266C42.712 27.1385 42.2375 27.6131 41.5256 27.6131Z"
        fill="black"
      />
      <path
        d="M47.4578 70.325H18.9831C18.2712 70.325 17.7966 69.8504 17.7966 69.1385V54.9012C17.7966 54.1893 18.2712 53.7147 18.9831 53.7147H28.4746C29.1865 53.7147 29.6611 54.1893 29.6611 54.9012C29.6611 55.6131 29.1865 56.0876 28.4746 56.0876H20.1695V67.9521H46.2712V56.0876H37.9661C37.2542 56.0876 36.7796 55.6131 36.7796 54.9012C36.7796 54.1893 37.2542 53.7147 37.9661 53.7147H47.4576C48.1695 53.7147 48.6441 54.1893 48.6441 54.9012V69.1385C48.6442 69.8504 48.1695 70.325 47.4578 70.325Z"
        fill="black"
      />
      <path
        d="M28.4745 58.4605C27.7626 58.4605 27.2881 57.9859 27.2881 57.274V52.5282C27.2881 51.8163 27.7626 51.3418 28.4745 51.3418C29.1864 51.3418 29.661 51.8163 29.661 52.5282V57.274C29.661 57.9859 29.1864 58.4605 28.4745 58.4605Z"
        fill="black"
      />
      <path
        d="M37.9662 58.4605C37.2543 58.4605 36.7798 57.9859 36.7798 57.274V52.5282C36.7798 51.8163 37.2543 51.3418 37.9662 51.3418C38.6781 51.3418 39.1527 51.8163 39.1527 52.5282V57.274C39.1527 57.9859 38.6781 58.4605 37.9662 58.4605Z"
        fill="black"
      />
      <path
        d="M48.6442 25.2402H41.5255C40.8136 25.2402 40.3391 24.7657 40.3391 24.0538C40.3391 23.3419 40.8136 22.8673 41.5255 22.8673H47.4577V11.0029H27.2882V22.8673H32.034C32.7458 22.8673 33.2204 23.3419 33.2204 24.0538C33.2204 24.7657 32.7458 25.2402 32.034 25.2402H26.1017C25.3898 25.2402 24.9153 24.7657 24.9153 24.0538V9.81657C24.9153 9.10468 25.3898 8.63013 26.1017 8.63013H48.6441C49.3559 8.63013 49.8305 9.10468 49.8305 9.81657V24.0539C49.8306 24.7657 49.3559 25.2402 48.6442 25.2402Z"
        fill="black"
      />
      <path
        d="M1.18645 60.8334C0.474551 60.8334 0 60.3588 0 59.6469V45.4097C0 44.6978 0.474551 44.2233 1.18645 44.2233H15.4238C16.1357 44.2233 16.6102 44.6978 16.6102 45.4097C16.6102 53.952 9.72877 60.8334 1.18645 60.8334ZM2.37289 46.5962V58.4606C8.661 57.8674 13.6441 52.8844 14.2373 46.5962H2.37289Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_696_3422">
        <rect width="70" height="70" fill="white" transform="translate(0 0.324951)" />
      </clipPath>
    </defs>
  </svg>
);

const AboutSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="426" height="56" viewBox="0 0 426 56" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7636 8.91555C46.9674 32.4724 117.84 40.3965 154.82 41.2702C244.015 43.3756 340.095 23.718 426 0C352.652 24.5329 275.413 45.2424 198.301 53.106C144.042 58.6397 48.7498 59.3399 4.06186 26.2742C-0.758738 22.237 -1.37572 15.0798 2.68455 10.285C6.74482 5.49186 13.943 4.87838 18.7652 8.91555H18.7636Z"
      fill="white"
    />
  </svg>
);

const PricingSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="107" height="88" viewBox="0 0 107 88" fill="none">
    <path
      d="M95.4462 61.5974C83.1572 64.6611 68.4837 65.2432 57.6837 57.5059C50.7819 52.5613 47.117 42.5627 49.6963 34.4471C52.1323 26.7825 57.821 20.0481 66.3456 20.2534C70.7889 20.3604 74.62 22.4047 75.4289 27.084C76.6646 34.2328 69.533 41.6308 63.8628 44.7404C46.1671 54.4451 21.134 53.9051 4.27674 42.6406"
      stroke="#9AD37F"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M11.7065 55.8447C9.64458 52.9634 5.14184 46.2418 3.62657 42.4054"
      stroke="#9AD37F"
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M3.62695 42.4054C7.1396 41.942 15.124 40.6362 18.9603 39.1209"
      stroke="#9AD37F"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

const MoveSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_417_3840)">
      <path
        d="M5.71351 13.7124L1.12088 2.69011C0.644223 1.54614 0.405895 0.974148 0.690022 0.690022C0.974148 0.405895 1.54614 0.644223 2.69011 1.12088L13.7124 5.71351C14.7064 6.12767 15.2034 6.33475 15.2245 6.71026C15.2456 7.08577 14.775 7.34725 13.8337 7.87019L10.2997 9.83349C10.1508 9.9162 10.0764 9.95756 10.017 10.017C9.95756 10.0764 9.9162 10.1508 9.83349 10.2997L7.87019 13.8337C7.34725 14.775 7.08577 15.2456 6.71026 15.2245C6.33475 15.2034 6.12767 14.7064 5.71351 13.7124Z"
        fill="#9B9C9E"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_3840">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const FrameSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_417_3843)">
      <path d="M2 14V2H14V14H2Z" fill="#686B6E" />
      <path d="M2 0V16M14 0V16M16 2H0M0 14H16" stroke="#9B9C9E" strokeWidth="2" />
    </g>
    <defs>
      <clipPath id="clip0_417_3843">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const PenSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_417_3846)">
      <rect
        x="13.5173"
        y="8.21875"
        width="8"
        height="4"
        rx="1"
        transform="rotate(-135 13.5173 8.21875)"
        fill="#686B6E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.599497 15.0554L5.98298 9.67196C5.59712 9.08955 5.66076 8.29712 6.17389 7.78398C6.75968 7.1982 7.70943 7.1982 8.29522 7.78398C8.881 8.36977 8.881 9.31952 8.29522 9.9053C7.78208 10.4184 6.98965 10.4821 6.40724 10.0962L1.00161 15.5019C1.12519 15.5664 1.26704 15.6022 1.41924 15.6005C4.1523 15.5697 9.14758 14.7696 10.3379 14.0693C13.3228 12.313 15.6489 8.06655 11.8444 4.26211C8.03997 0.45767 3.93515 2.71498 2.03724 5.76865C1.31523 6.93033 0.62813 12.0033 0.536028 14.6869C0.53153 14.818 0.554379 14.9427 0.599497 15.0554Z"
        fill="#9B9C9E"
      />
    </g>
    <defs>
      <clipPath id="clip0_417_3846">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const TextSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M2 5V3C2 2.44772 2.44772 2 3 2H8M14 5V3C14 2.44772 13.5523 2 13 2H8M8 2V15M5 15H11"
      stroke="#9B9C9E"
      strokeWidth="2"
    />
  </svg>
);

const HandSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M3.47508 13.0961L4.52623 14.4514C5.2839 15.4283 6.45073 16 7.68703 16H9.4004C10.7862 16 12.0689 15.268 12.7739 14.075C13.5766 12.7166 14 11.1677 14 9.58981V4.61039C14 3.95769 13.4709 3.42857 12.8182 3.42857C12.1655 3.42857 11.6364 3.95769 11.6364 4.61039V2.32468C11.6364 1.67198 11.1072 1.14286 10.4545 1.14286C9.80185 1.14286 9.27273 1.67198 9.27273 2.32468V1.18182C9.27273 0.529118 8.74361 0 8.09091 0C7.43821 0 6.90909 0.529118 6.90909 1.18182V2.8961C6.90909 2.2434 6.37997 1.71429 5.72727 1.71429C5.07457 1.71429 4.54545 2.2434 4.54545 2.8961V7.58686C4.54545 7.98345 4.01296 8.11297 3.83081 7.76068L3.04256 6.23617C2.87682 5.91561 2.54609 5.71429 2.18521 5.71429C1.57356 5.71429 1.11616 6.27596 1.24004 6.87494L1.75443 9.36206C2.03562 10.7217 2.6242 11.999 3.47508 13.0961Z"
      fill="#9B9C9E"
    />
  </svg>
);

const ProfileSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <circle cx="5" cy="4" r="3" fill="#9B9C9E" />
    <path d="M10 7H16M13 4V10" stroke="#9B9C9E" strokeWidth="1.5" />
    <rect y="9" width="10" height="6" rx="3" fill="#9B9C9E" />
  </svg>
);

const ShareSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_1225_19)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 3C2 2.44772 2.44772 2 3 2H8V0H3C1.34315 0 0 1.34315 0 3V13C0 14.6569 1.34315 16 3 16H13C14.6569 16 16 14.6569 16 13V8H14V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3ZM15 0H11V2H12.5858L7.29289 7.29289L8.70711 8.70711L14 3.41421V5H16V1V0H15Z"
        fill="#9B9C9E"
      />
    </g>
    <defs>
      <clipPath id="clip0_1225_19">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const PlaySvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M3 12.263V3.73703C3 2.13964 4.78029 1.18686 6.1094 2.07293L12.5038 6.3359C13.6913 7.12754 13.6913 8.87246 12.5038 9.6641L6.1094 13.9271C4.78029 14.8131 3 13.8604 3 12.263Z"
      fill="#9B9C9E"
    />
  </svg>
);

const AngleSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M1 0V5M12 11H7M1 5V11H7M1 5C5 5 7 7 7 11" stroke="#686B6E" strokeWidth="2" />
  </svg>
);

const CircumferenceSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path
      d="M11 4V3C11 1.89543 10.1046 1 9 1H8M1 4V3C1 1.89543 1.89543 1 3 1H4M1 9V9C1 10.1046 1.89543 11 3 11H4M11 9V9C11 10.1046 10.1046 11 9 11H8"
      stroke="#686B6E"
      strokeWidth="2"
    />
  </svg>
);

const ClockSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_463_11915)">
      <circle cx="8" cy="8" r="7" stroke="#CDCECF" strokeWidth="2" />
      <path d="M8 3.5V8L10 10" stroke="#CDCECF" strokeWidth="2" />
    </g>
    <defs>
      <clipPath id="clip0_463_11915">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const DocumentSvg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 0C2.89543 0 2 0.89543 2 2V13C2 14.1046 2.89543 15 4 15H12C13.1046 15 14 14.1046 14 13V6.5V6.07893C14 5.52279 13.7684 4.99177 13.3609 4.61334L8.96821 0.534413C8.59828 0.190906 8.11213 0 7.60731 0H7.5H4ZM7.5 2H4V13H12V6.5H7.5V2Z"
      fill="#CDCECF"
    />
  </svg>
);

export {
  SearchIcon,
  DropdownIcon,
  FeaturesPageSvg1,
  FeaturesPageSvg2,
  FeaturesPageSvg3,
  AboutSvg,
  PricingSvg,
  MoveSvg,
  FrameSvg,
  PenSvg,
  TextSvg,
  HandSvg,
  ProfileSvg,
  ShareSvg,
  PlaySvg,
  AngleSvg,
  CircumferenceSvg,
  ClockSvg,
  DocumentSvg,
};
