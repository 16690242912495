import ClipLoader from "react-spinners/ClipLoader";
import PropTypes from "prop-types";

const Spinner = () => {
  return (
    <div className="flex items-center justify-center py-8">
      <ClipLoader
        color={"#800190"}
        size={40}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

const Loader = ({ loaderText, isColored }) => (
  <div
    className={`${
      isColored ? "text-stemGreen" : "text-white"
    } font-nunitoRegular flex items-center justify-center text-base gap-3`}
  >
    <span className={isColored ? "text-stemGreen" : "text-white"}>
      {loaderText}
    </span>
    <Spinner />
  </div>
);

const FullLoader = ({ loaderText }) => {
  return (
    <div className="fixed w-full left-0 top-0 h-screen z-[99999] bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <Loader loaderText={loaderText} isColored />
    </div>
  );
};

Loader.propTypes = {
  loaderText: PropTypes.string,
  isColored: PropTypes.bool,
};

FullLoader.propTypes = {
  loaderText: PropTypes.string,
};

export { Loader, FullLoader };
