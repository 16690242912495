import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { allDesignsDefaultSidebarData } from '../../data/allDesigns-page-data';

function AllDesignsDefaultSidebar() {
  const { pathname } = window.location;

  const { isLeftSidebarOpen } = useSelector((state) => state.leftSidebar);

  return (
    <section className={`${isLeftSidebarOpen ? 'hidden' : 'flex'} fixed h-screen w-[18%] bg-editorLight text-white`}>
      <main className="h-fit flex-1 flex flex-col">
        {allDesignsDefaultSidebarData.map((leftData) => (
          <Link
            to={leftData.path}
            key={leftData.id}
            className={`py-[1.2rem] flex gap-6 transition-all hover:bg-[#686B6E]/80 hover:text-white hover:text-opacity-60 flex-row justify-start ${
              pathname === leftData.path && 'bg-[#686B6E]/50 text-white text-opacity-60'
            } `}
          >
            <div className="pl-8">{leftData.icon}</div>
            <div className="font-nunitoRegular whitespace-nowrap text-sm flex justify-start">
              <div>{leftData.text}</div>
            </div>
          </Link>
        ))}
      </main>
    </section>
  );
}

export default AllDesignsDefaultSidebar;
