import Button from './Button';
import PropTypes from 'prop-types';

const GlobalModal = ({ modalText, clickFunction, path, extraText }) => {
  return (
    <main className="fixed top-0 left-0 w-full h-full flex z-10 items-center justify-center bg-gray-800 font-nunitoRegular font-normal">
      <section className="bg-[#140B2A99] p-6 z-50 rounded-sm w-[250px] h-96 lg:h-[250px] lg:w-[520px] flex justify-center items-center text-center">
        <aside className="flex flex-col items-center gap-8">
          <p className="text-[18px] pb-4 text-[#737373]">{modalText}</p>
          <div className="w-fit text-center">
            <Button path={path} isLight extraText={extraText ? extraText : 'Close'} handleClick={clickFunction} />
          </div>
        </aside>
      </section>
    </main>
  );
};

GlobalModal.propTypes = {
  modalText: PropTypes.string,
  path: PropTypes.string,
  extraText: PropTypes.string,
  clickFunction: PropTypes.func,
};

export default GlobalModal;
