import AllDesignsRootLayout from '../components/dashboard-components/AllDesignsRootLayout';
import DashboardHeaderText from '../components/dashboard-components/DashboardHeaderText';
import PreferencesComponent from '../components/dashboard-components/PreferencesComponent';

function AllDesignsPreferencesPage() {
  return (
    <AllDesignsRootLayout>
      <section className="ml-[22%] px-8 py-8 font-nunitoSemiBold text-white">
        <aside className="flex justify-start">
          <DashboardHeaderText text="Preferences" />
        </aside>
        <PreferencesComponent />
      </section>
    </AllDesignsRootLayout>
  );
}

export default AllDesignsPreferencesPage;
