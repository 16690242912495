import { useState, useEffect } from "react";
import {
  RiDeleteBinLine,
  RiPencilLine,
  RiShareForwardLine,
  RiFileCopyLine,
} from "react-icons/ri";
import { HiDotsVertical } from "react-icons/hi";
import ReactPaginate from "react-paginate";
import PropTypes from "prop-types";
import { projectsData, searchOptions } from "../../data/projects-data";
import { FaPlus } from "react-icons/fa";
import DashboardHeaderText from "./DashboardHeaderText";
import { getProjects } from "../../baseURL";
import { useQuery } from "@tanstack/react-query";
import { FullLoader, Loader } from "../globals/Loader";
import Dropdown from "../nav-components/Dropdown";
import { dropdownListData } from "../../data/navbar-data";
import useResize from "../../custom-hooks/useResize";

function ProjectCard({ project, onCardClick, selectedProject }) {
  const handleOptionsClick = (e) => {
    e.stopPropagation();
    // Handle options click logic here
  };

  function formatDate(isoString) {
    const date = new Date(isoString);
    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getUTCFullYear();

    const ordinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${ordinalSuffix(day)} ${month} ${year}`;
  }

  return (
    <aside className="md:w-[calc(34.3%-1.7rem)]">
      <div
        className=" flex flex-col bg-[#333333] border border-gray-300 rounded-t-lg pl-4 pt-4 pb-1 cursor-pointer"
        onClick={() => onCardClick(project)}
      >
        <figure className="flex pr-4">
          {project.resourceType == "image" && (
            <img src={project.url} alt="" className="rounded h-48" />
          )}
          {project.resourceType == "video" && (
            <video src={project.url} className="rounded h-48" controls />
          )}
        </figure>
        <div className="pt-3 flex justify-between">
          {/* <h3 className="text-lg text-hoverGreen font-semibold mb-2">
            {project.title}
          </h3> */}
          {/* <div className="flex items-center pr-2">
            <HiDotsVertical
              className="text-gray-500"
              onClick={handleOptionsClick}
            />
          </div> */}
        </div>
      </div>
      <div className="flex flex-wrap gap-2 text-xs text-white mb-2 py-3 pl-4 border-x border-b border-gray-300 rounded-b-lg">
        <span className="md:w-[calc(48.3%-1.0rem)]">
          Created: {formatDate(project.uploadDate)}
        </span>
        {/* <span className="md:w-[calc(48.3%-1.0rem)]">
          Updated: {project.lastUpdated}
        </span> */}
        {/* <span className="md:w-[calc(48.3%-1.0rem)]">
          Status: {project.completionStatus}
        </span> */}
        <span>
          Type: <span className="font-semibold">{project.resourceType}</span>
        </span>
        <div className="flex-1 flex  justify-end pr-3">
          <HiDotsVertical
            className="text-gray-500"
            onClick={handleOptionsClick}
          />
        </div>
      </div>

      {selectedProject &&
        Object.keys(selectedProject).length > 0 &&
        selectedProject.projectId == project.projectId && (
          <section>{project.title} is clicked</section>
        )}
    </aside>
  );
}

function ProjectsComponent() {
  const auth = localStorage.getItem("token");
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [projects, setProjects] = useState([]);

  const projectsPerPage = 9;
  const { isOpen, setIsOpen } = useResize();

  const { isPending, error, data } = useQuery({
    queryKey: ["projects"],
    queryFn: async () => {
      const response = await getProjects(auth);
      setFilteredProjects(response.data);
      setProjects(response.data);
      return response.data;
    },
  });
  const pageCount = data ? Math.ceil(data.length / projectsPerPage) : 0;

  useEffect(() => {
    handleSearch();
  }, []);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleCardClick = (project) => {
    console.log({ project });
    // Logic to handle card click and show detailed view
    setSelectedProject(project);
  };

  const handleCloseModal = () => {
    // Logic to handle modal close
    setSelectedProject(null);
  };

  const handleSearch = () => {
    // Logic for searching
    const result = projects.filter((project) =>
      project.resourceType.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProjects(result);
    setCurrentPage(0); // Reset to the first page after searching
  };

  const handleFilter = (filterOption) => {
    // Logic for filtering

    if (filterOption == "all") {
      setFilteredProjects(projects);
      return;
    }
    const result = projects.filter(
      (project) => project.resourceType == filterOption
    );
    setFilteredProjects(result);
    setCurrentPage(0); // Reset to the first page after filtering
  };

  const slicedProjects = filteredProjects.slice(
    currentPage * projectsPerPage,
    (currentPage + 1) * projectsPerPage
  );

  const getDropdownClickedState = (isClicked) => {
    isClicked && setIsOpen(!isOpen);
  };

  return (
    <section className="md:ml-[18%] sm:ml-0 flex flex-col p-8 font-nunitoRegular">
      {isPending ? (
        <div className="mt-24">
          <Loader loaderText="Fetching Projects..." />
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center mb-6">
            <DashboardHeaderText text="All projects" />

            {data && data.length > 0 && (
              <aside className="flex space-x-4">
                <div className="flex space-x-1">
                  <input
                    type="text"
                    placeholder="Search project"
                    className="border border-gray-300 p-2 font-nunitoRegular text-sm rounded"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button
                    className="bg-stemGreen text-white text-sm px-4 py-2 rounded"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                </div>
                <select
                  className="border border-gray-300 pl-2 rounded text-sm w-40"
                  onChange={(e) => handleFilter(e.target.value)}
                >
                  {searchOptions.map((option) => (
                    <option
                      key={option.id}
                      className="py-4 my-4 text-sm"
                      value={option.value}
                    >
                      {option.optionText}
                    </option>
                  ))}
                </select>
              </aside>
            )}

            <aside>
              <Dropdown
                text="Create New Project"
                create
                dropdownListData={dropdownListData[2]}
                getDropdownClickedState={getDropdownClickedState}
              />
              {/* <button className="bg-stemGreen text-white text-sm px-8 py-[10px] flex items-center gap-3 rounded">
                <FaPlus />
                <span>Create New Project</span>
              </button> */}
            </aside>
          </div>
          {data.length > 0 ? (
            <div className="flex flex-wrap gap-5 mt-6 mb-8">
              {slicedProjects.map((project, index) => (
                <ProjectCard
                  key={index}
                  project={project}
                  onCardClick={handleCardClick}
                  selectedProject={selectedProject}
                />
              ))}
            </div>
          ) : (
            <div className="mt-24">
              <p className="text-stemGreen text-center">No Projects</p>
            </div>
          )}
          {data.length > 6 && (
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName={
                "flex justify-center gap-4 text-stemGreen mt-4 pagination"
              }
              activeClassName={"active font-nunitoExtraBold"}
            />
          )}
        </>
      )}
    </section>
  );
}

ProjectCard.propTypes = {
  project: PropTypes.object,
  onCardClick: PropTypes.func,
  selectedProject: PropTypes.object,
};

export default ProjectsComponent;
