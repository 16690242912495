import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { SidePanel } from "polotno/side-panel";
import { Workspace } from "polotno/canvas/workspace";
import { DownloadButton } from "polotno/toolbar/download-button";
import store from "../../polontoStore";
import { Button } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import { FaDownload } from "react-icons/fa6";
import { BiDownload } from "react-icons/bi";
import { LuDownload } from "react-icons/lu";
import { Bounce, toast, ToastContainer } from "react-toastify";
import { checkDownload, uploadImages } from "../baseURL";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
// import logo from "../../public/favicon.png";
import { FullLoader } from "../components/globals/Loader";
import { useDispatch, useSelector } from "react-redux";
import { onLoading } from "../redux/actions";

const ActionControls = ({ store }) => {
  const auth = JSON.parse(localStorage.getItem("userswiftsketch"));
  const token = localStorage.getItem("token");
  // console.log(token);
  const dispatch = useDispatch();

  const saveImageToDbAndDownload = async () => {
    try {
      dispatch(onLoading(true));

      const response = await checkDownload(token);
      if (response.status == 200) {
        if (response.data.downloadable) {
          store.saveAsImage();
          const canvas = await store.toDataURL();
          // const reader = new FileReader();
          const data = {
            file: canvas,
            upload_preset: "mjr7evpg",
            folder: `imageexport/${auth.email.split("@")[0]}${auth.userId}`,
          };
          const sendingInformation = {
            data: { ...data },
            user_id: auth.userId,
          };
          const response = await uploadImages(sendingInformation);
          console.log(response);
          if (response.status == 200) {
            dispatch(onLoading(false));

            toast.success(`${response.data.message}`, {
              position: "top-right",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          }
        } else {
          dispatch(onLoading(false));

          toast.error(`Download limit reached, upgrade plan`, {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      } else {
        dispatch(onLoading(false));
      }
    } catch (error) {
      dispatch(onLoading(false));

      toast.error(`An error occured`, {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };
  return (
    <div>
      <Button onClick={saveImageToDbAndDownload} icon={<LuDownload />}>
        Download
      </Button>
    </div>
  );
};
// const HomeIcon = () => {
//   return (
//     <div className="w-3 absolute">
//       <img src={logo} className="w-3" />
//     </div>
//   );
// };

export const PolontoCanvas = () => {
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.loader.loading);
  const auth = JSON.parse(localStorage.getItem("userswiftsketch"));
  useEffect(() => {
    if (!auth) {
      navigate("/login", { replace: true });
    }
  }, [auth, navigate]);
  return (
    <>
      {isLoading && <FullLoader loaderText="saving to projects" isColored />}
      <div className="bp5-dark">
        <ToastContainer />
        <PolotnoContainer style={{ width: "100vw", height: "100vh" }}>
          <SidePanelWrap>
            {/* <HomeIcon /> */}
            <SidePanel store={store} />
          </SidePanelWrap>
          <WorkspaceWrap>
            <Toolbar
              store={store}
              // downloadButtonEnabled={false}
              components={{
                ActionControls,
              }}
            />

            <Workspace store={store} backgroundColor="#1f1f1f" />
            <ZoomButtons store={store} />
          </WorkspaceWrap>
        </PolotnoContainer>
      </div>
    </>
  );
};
