import axios from "axios";

function switchApiURL() {
  // if (import.meta.env.VITE_ENV === 'dev') return import.meta.env.VITE_API_URL_DEV;
  if (import.meta.env.VITE_ENV === "dev")
    return import.meta.env.VITE_API_URL_DEV;
  return import.meta.env.VITE_API_URL_PROD;
}

// const baseurl = import.meta.env.VITE_API_URL_PROD;
// const baseurl = 'https://swiftsketch.onrender.com/api/v1';
const baseurl = switchApiURL();
export const resetPassword = async (data) => {
  try {
    const response = await axios.post(`${baseurl}/auth/forgot-password`, data);
    return response;
  } catch (error) {
    return error;
  }
};
export const googleAuth = async () => {
  try {
    const response = await axios.get(`${baseurl}/auth/google`);
    return response;
  } catch (error) {
    return error;
  }
};

export const sendNewPassword = async (token, data) => {
  console.log({ token, data });

  try {
    const response = await axios.post(
      `${baseurl}/auth/reset-password/${token}`,
      { newPassword: data }
    );
    return response;
  } catch (error) {
    return error;
  }
};
export const createUser = async (data) => {
  try {
    const response = await axios.post(`${baseurl}/auth/register`, data);
    return response;
  } catch (error) {
    return error;
  }
};
export const checkUser = async (data) => {
  try {
    const response = await axios.post(`${baseurl}/users/check`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const verifyPayment = async (data) => {
  try {
    const response = await axios.post(
      `${baseurl}/pricing/verify_paystack`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createStripePayment = async (data) => {
  try {
    const response = await axios.post(
      `${baseurl}/pricing/stripe_payment`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const logingoogle = async () => {
  try {
    const response = await axios.get(`${baseurl}/auth/google`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getPackages = async () => {
  try {
    const response = await axios.get(`${baseurl}/pricing`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getIp = async () => {
  try {
    const response = await axios.get(`https://api.ipify.org?format=json`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getIpInfo = async () => {
  try {
    const response = await axios.get(`${baseurl}/pricing/ip`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getExchangeRate = async () => {
  try {
    const response = await axios.get(
      `https://api.exchangeratesapi.io/v1/latest?access_key=${
        import.meta.env.VITE_EXCHANGE_API_KEY
      }&symbols=NG&base=USD`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const loginfacebook = async () => {
  try {
    const response = await axios.get(`${baseurl}/auth/facebook`);
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteUser = async (authToken) => {
  try {
    const response = await axios.delete(`${baseurl}/users`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
export const savevideo = async (data) => {
  try {
    const response = await axios.post(`${baseurl}/video/video`, data);

    return response;
  } catch (error) {
    return error;
  }
};
export const saveimage = async (data) => {
  try {
    const response = await axios.post(`${baseurl}/photo/photo`, data);

    return response;
  } catch (error) {
    return error;
  }
};

export const updateImageUploads = async (data) => {
  try {
    const response = await axios.post(`${baseurl}/photo/upload`, data);

    return response;
  } catch (error) {
    return error;
  }
};
export const updateVideoUploads = async (data) => {
  try {
    const response = await axios.post(`${baseurl}/video/upload`, data);

    return response;
  } catch (error) {
    return error;
  }
};
export const uploadVideos = async (source) => {
  try {
    const response = await axios.post(
      "https://api.cloudinary.com/v1_1/swiftsketch/video/upload/",
      source.data
    );
    const finallySaved = await savevideo({
      userId: source.user_id,
      size: response.data.bytes,
      asset_id: response.data.asset_id,
      url: response.data.secure_url,
      resource_type: response.data.resource_type,
    });
    return finallySaved;
  } catch (error) {
    console.log(error);
  }
};
export const getProjects = async (token) => {
  try {
    const response = await axios.get(`${baseurl}/project/getAll`, {
      headers: { Authorization: token },
    });
    return response;
  } catch (e) {
    return e;
  }
};
export const getOverview = async (token) => {
  try {
    const response = await axios.get(`${baseurl}/project/getProjectsInfo`, {
      headers: { Authorization: token },
    });
    return response;
  } catch (e) {
    return e;
  }
};
export const getRecent = async (token) => {
  try {
    const response = await axios.get(`${baseurl}/project/getRecent`, {
      headers: { Authorization: token },
    });
    return response;
  } catch (e) {
    return e;
  }
};
export const checkDownload = async (token) => {
  try {
    const response = await axios.get(`${baseurl}/project/getDownloads`, {
      headers: { Authorization: token },
    });
    return response;
  } catch (e) {
    return e;
  }
};
export const getSizeOverview = async (token) => {
  try {
    const response = await axios.get(`${baseurl}/project/getAllSize`, {
      headers: { Authorization: token },
    });
    return response;
  } catch (e) {
    return e;
  }
};
export const getUploadOverview = async (token) => {
  try {
    const response = await axios.get(`${baseurl}/project/getAllUploads`, {
      headers: { Authorization: token },
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const uploadImages = async (source) => {
  try {
    const response = await axios.post(
      "https://api.cloudinary.com/v1_1/swiftsketch/image/upload/",
      source.data
    );
    const finallySaved = await saveimage({
      userId: source.user_id,
      asset_id: response.data.asset_id,
      size: response.data.bytes,
      url: response.data.secure_url,
      resource_type: response.data.resource_type,
    });
    return finallySaved;
  } catch (error) {
    console.log(error);
  }
};

export default switchApiURL;
