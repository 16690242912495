import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import EmailRegister from "../components/register-components/EmailRegister";
import MobileLogo from "../components/register-components/MobileLogo";
import HeaderSection from "../components/register-components/HeaderSection";
import SocialButtons from "../components/register-components/SocialButtons";
import { LoginFormInputs } from "../data/login-data";
import NoAccount from "../components/login-components/NoAccount";
import ImageSection from "../components/register-components/ImageSection";
import loginImg from "../assets/images/login-illustration.png";
import axios from "axios";
import PropTypes from "prop-types";
import { jwtDecode } from "jwt-decode";
import { FullLoader } from "../components/globals/Loader";
import { Bounce, ToastContainer, toast } from "react-toastify";
import useResize from "../custom-hooks/useResize";

function LoginPage({ baseurl }) {
  const [formResponse, setFormResponse] = useState("");
  const [responseColor, setResponseColor] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { width, breakpoint } = useResize();
  const navigate = useNavigate();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log({ formData });
    setIsLoading(true);
    axios
      .post(`${baseurl}/auth/login`, formData)
      .then((res) => {
        setFormResponse(res.data.message);
        setIsLoading(false);
        const decoded = jwtDecode(res.data.token);
        localStorage.setItem("userswiftsketch", JSON.stringify(decoded));
        localStorage.setItem("token", res.data.token);
        if (width < breakpoint) {
          navigate("/image-editor");
        } else {
          navigate("/dashboard");
        }
        setResponseColor("text-stemGreen");
      })
      .catch((err) => {
        setIsLoading(false);
        setFormResponse(err.response.data.message);
        // toast.error('User does not exist, Please register', {
        toast.error(`Login error: ${err.response.data.message}`, {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });

        // console.log({ formResponse });
        setResponseColor("text-orange");
      });
  };

  return (
    <>
      {isLoading && <FullLoader loaderText="Logging in" isColored />}
      <section className="pb-16 xl:py-16 bg-generalBlack flex flex-col md:flex-row gap-12 md:gap-6 px-8 md:px-16">
        <ToastContainer />

        <main className=" md:h-[51.8rem] w-full md:w-1/2 md:pr-8 flex flex-col gap-3">
          <MobileLogo />
          <HeaderSection
            isLoginPage
            text="Log in to SwiftSketch to start creating magic."
          />
          <SocialButtons baseurl={baseurl} />
          <EmailRegister checkOutText="Or continue with email" />

          <form className="pt-4 flex flex-col gap-4" onSubmit={handleSubmit}>
            <aside className="font-nunitoRegular flex flex-col space-y-8">
              {LoginFormInputs().map((input, index) => (
                <div key={index} className="w-full flex flex-col">
                  <label className="mb-2 text-white">{input.name}</label>
                  <div className="relative flex">
                    <input
                      className="flex-1 text-black rounded-sm pl-12 pr-3 py-[0.9rem] placeholder:font-nunitoRegular"
                      type={input.type}
                      required
                      placeholder={input.placeholder}
                      name={input.key}
                      value={formData[input.key]}
                      onChange={handleChange}
                    />
                    {input.icon}
                    {input.visionIcon && input.visionIcon}
                  </div>
                </div>
              ))}
            </aside>
            <aside className="mb-4 md:flex flex-col space-y-8 pt-8">
              <div className="flex items-center space-x-2">
                <input className="h-4 w-4" type="checkbox" id="agreeTerms" />
                <label
                  htmlFor="agreeTerms"
                  className="flex-1 flex gap-3 flex-row justify-between pl-3 text-white font-nunitoRegular"
                >
                  <span>Remember me</span>
                  <Link to="/forgot-password" className="text-stemGreen">
                    Forgot Password?
                  </Link>
                </label>
              </div>
              {formResponse && (
                <p className={`${responseColor} text-base font-nunitoRegular`}>
                  {formResponse}!
                </p>
              )}
              <div>
                <button className="font-nunitoBold w-full bg-stemGreen text-white p-4 rounded-md">
                  Login
                </button>
              </div>
            </aside>
          </form>
          <NoAccount
            pText="Don't have an account?"
            linkText="Sign up"
            linkPath="/register"
          />
        </main>

        <ImageSection isLoginPage imgSrc={loginImg} />
      </section>
    </>
  );
}

LoginPage.propTypes = {
  baseurl: PropTypes.string,
};

export default LoginPage;
