import { Fragment } from 'react';
import TopSection from '../components/globals/TopSection';
import TopSectionImages from '../components/about-components/TopSectionImages';
import BottomContainer from '../components/about-components/BottomContainer';
import OurClients from '../components/about-components/OurClients';
import OurTeam from '../components/about-components/OurTeam';

function AboutPage() {
  return (
    <Fragment>
      <TopSection heroText="Creativity Meets Expression" isColoredHeroText />
      <TopSectionImages />
      <BottomContainer />
      <OurClients />
      <OurTeam />
    </Fragment>
  );
}

export default AboutPage;
