import { useState, useEffect } from "react";

const useResize = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [breakpoint, setBreakpoint] = useState(800);
  const [isOpen, setIsOpen] = useState(false);

  const handleResize = () => {
    setWidth(window.innerWidth);
    setIsOpen(false);
  };

  const handleMenubarToggle = () => {
    width < breakpoint && setIsOpen(!isOpen);
  };

  useEffect(() => {
    const updateBreakpoint = () => {
      setBreakpoint(800);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("click", updateBreakpoint);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("click", updateBreakpoint);
    };
  }, [isOpen]);

  return { width, breakpoint, isOpen, setIsOpen, handleMenubarToggle };
};

export default useResize;
