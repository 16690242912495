import PropTypes from 'prop-types';

function SectionRowOne({ h4text, h3text, isTestimonial }) {
  return (
    <main className="mb-2 md:mb-4 text-center">
      <h4 className="text-orange text-2xl font-nunitoBold">{h4text}</h4>
      <h3
        className={`${
          isTestimonial ? 'mt-5 mb-16' : 'mt-8 mb-6'
        } text-white text-3xl md:text-4xl font-nunitoExtraBold leading-snug md:leading-normal w-[92%] mx-auto md:w-full`}
      >
        {h3text}
      </h3>
    </main>
  );
}

SectionRowOne.propTypes = {
  h4text: PropTypes.string,
  h3text: PropTypes.string,
  isTestimonial: PropTypes.bool,
};

export default SectionRowOne;
