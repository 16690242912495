import { footerBaseData, footerTopData } from "../../data/footer-data";

function Footer() {
  return (
    <footer className="bg-generalBlack text-white">
      <section className="py-8 px-6 md:px-12">
        <div className="flex flex-col gap-4 md:gap-8 border-white container mx-auto">
          <div className="flex flex-col gap-4 md:flex-row border-t border-white py-8">
            <div className="grow">
              <h1 className="text-lg font-nunitoExtraBold mb-4">
                JOIN OUR COMMUNITIES OF DESIGNERS ON:
              </h1>
              <div className="flex gap-2 md:gap-4 font-nunitoRegular">
                {footerTopData[0].map((leftData) => (
                  <div key={leftData.id} className="flex items-center mr-4">
                    {leftData.firstChildIcon}
                    <span>{leftData.iconSpanText}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="grow">
              <div className="flex flex-col md:flex-row gap-4">
                {footerTopData[1].map((rightData) => (
                  <div key={rightData.id} className="grow">
                    <h3 className="text-lg font-nunitoExtraBold mb-2">
                      {rightData.h3Text}
                    </h3>
                    <ul className="flex flex-col gap-2 pt-2 text-sm font-nunitoRegular">
                      {rightData.listItemTexts.map((listItem, index) => (
                        <li key={index}>{listItem}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 md:flex-row mt-4 md:mt-0 border-t border-white py-8">
            <div className="order-3 md:order-1">
              <h4 className="text-sm mb-4 font-nunitoLight">
                © Decipher Innovate, 2023.
              </h4>
            </div>
            <div className="order-1 md:order-2 grow">
              <ul className="flex items-left md:items-center justify-center md:space-x-4 flex-col md:flex-row font-nunitoRegular text-sm gap-3 md:gap-0">
                {footerBaseData[0].map((rightData, index) => (
                  <li key={index}>{rightData}</li>
                ))}
              </ul>
            </div>
            <div className="order-2 md:order-3 grow flex md:justify-center items-start pt-1">
              {footerBaseData[1].map((rightIconData, index) => (
                <div key={index}>
                  <a href={rightIconData.link} target="_blank" rel="noreferrer">
                    {rightIconData.icon}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
