function BlogsHero() {
  return (
    <section className="bg-generalBlack pt-56 pb-20">
      <div className="text-center md:text-left w-[90%] mx-auto flex flex-col space-y-8 md:space-y-6 text-white">
        <h1 className="font-nunitoExtraBold text-5xl text-center">All Articles</h1>
        <h4 className="font-nunitoRegular text-base text-center">
          Design Insights and Inspiration Await - Delve into SwiftSketch&apos;s Blog for Creative Ideas and Design
          Excellence
        </h4>
      </div>
    </section>
  );
}

export default BlogsHero;
