import { Fragment, useEffect } from 'react';
import TemplatesHero from '../components/templates-components/TemplatesHero';
// import TemplatesMain from '../components/templates-components/TemplatesMain';
import TemplatesSearch from '../components/templates-components/TemplatesSearch';

function TemplatesPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <TemplatesHero />
      <TemplatesSearch />
      {/* <TemplatesMain /> */}
    </Fragment>
  );
}

export default TemplatesPage;
