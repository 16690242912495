import { useState } from 'react';
import { allDesignsSettingsFormData, borderClasses } from '../data/allDesigns-page-data';
import AllDesignsSettingsRootLayout from '../components/dashboard-components/AllDesignsSettingsRootLayout';
import { useParams } from 'react-router-dom';

function AllDesignsPasswordPage() {
  const [formResponse, setFormResponse] = useState('');
  const [responseColor, setResponseColor] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ formData });
  };

  return (
    <AllDesignsSettingsRootLayout>
      <form className="ml-[22%] flex flex-col gap-4" onSubmit={handleSubmit}>
        <main className="w-full px-5 py-4">
          <div className="flex flex-row flex-wrap">
            {allDesignsSettingsFormData.map((input, index) => (
              <div
                key={index}
                className={`${
                  borderClasses[input.key]
                } flex flex-col gap-1 flex-auto md:basis-1/2 md:border-y-[1rem] border-transparent`}
              >
                <div className="w-full flex flex-col">
                  <label className="mb-2 text-white">{input.name}</label>
                  <div className="relative flex">
                    <input
                      className="flex-1 text-black rounded-sm px-5 py-[0.9rem] placeholder:font-nunitoRegular"
                      type={input.type}
                      placeholder={input.placeholder}
                      name={input.key}
                      value={formData[input.key]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </main>
        <aside className="mb-4 md:flex flex-col pt-4 px-4">
          <button className="font-nunitoBold w-full bg-stemGreen text-white p-4 rounded">Update Details</button>
        </aside>
      </form>
    </AllDesignsSettingsRootLayout>
  );
}

export default AllDesignsPasswordPage;
