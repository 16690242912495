import PropTypes from 'prop-types';
import { FaFileImage } from 'react-icons/fa';

function TemplateImageModal({ closeModal, continueToDashboard }) {
  return (
    <section className="absolute top-0 left-0 w-full h-[102%] flex items-center justify-center bg-gray-800 bg-opacity-50">
      <main className="bg-white p-6 rounded w-fit h-fit flex justify-center items-center text-center">
        <aside className="flex flex-col gap-7">
          <div className="flex flex-col gap-2 text-[#737373] text-left">
            <article className="flex gap-2">
              <span className="flex items-center">
                <FaFileImage size={18} className="text-stemGreen" />
              </span>
              <h4 className="text-[14px] underline underline-offset-4">Image Description</h4>
            </article>
            <p className="text-[15px] pt-3 opacity-80">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint, harum...
            </p>
          </div>
          <div className="flex flex-row gap-4">
            <button className="bg-[#999] text-center text-white/90 px-4 py-2 rounded" onClick={closeModal}>
              Close
            </button>
            <button
              className="flex-1 bg-stemGreen text-center text-white/90 px-3 py-2 rounded"
              onClick={continueToDashboard}
            >
              Continue to dashboard
            </button>
          </div>
        </aside>
      </main>
    </section>
  );
}

TemplateImageModal.propTypes = {
  closeModal: PropTypes.func,
  continueToDashboard: PropTypes.func,
};

export default TemplateImageModal;
