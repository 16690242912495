import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import AllDesignsSettingsRootLayout from "../components/dashboard-components/AllDesignsSettingsRootLayout";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { deleteUser } from "../baseURL";
import { cancelData } from "../data/cancel-data";
import Button from "../components/globals/Button";
import ClipLoaders from "../components/globals/ClipLoaders";
import { IoIosWarning } from "react-icons/io";
import {
  RenderErrorToast,
  RenderSuccessToast,
} from "../components/globals/ToastCards";
import { ToastContainer } from "react-toastify";
import { FullLoader } from "../components/globals/Loader";
import { useNavigate } from "react-router-dom";

function DeleteAccountModal({ setIsModalOpen }) {
  const [deleteStatus, setDeleteStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFull, setIsLoadingFull] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const navigate = useNavigate();
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleDataDeletion = async () => {
    setIsLoadingFull(true);
    setIsButtonDisabled(true);

    let jwt = localStorage.getItem("token");
    jwt = jwt.toString();

    try {
      const response = await deleteUser(jwt);

      if (response.data.success) {
        setDeleteStatus(response.data.message);
        RenderSuccessToast(response.data.message);
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 1500);
      } else {
        setDeleteStatus("Error deleting user data.");
        RenderErrorToast("Error deleting user data.");
      }
    } catch (error) {
      console.error("Error deleting user data:", error);
      setDeleteStatus("Error deleting user data.");
      RenderErrorToast("Error deleting user data.");
    }

    setIsLoading(false);
    setIsLoadingFull(false);

    const loaderTimeout = setTimeout(() => {
      setIsModalOpen(false);
      setIsButtonDisabled(false);
    }, 3000);

    return () => clearTimeout(loaderTimeout);
  };

  return (
    <Fragment>
      {isLoadingFull && <FullLoader loaderText="Deleting..." isColored />}
      <ToastContainer />

      <main className="absolute h-fit w-fit flex z-10 items-start justify-start py-20 px-20 bg-gray-800 bg-opacity-50 rounded-sm">
        <section className="w-[400px] text-center bg-white p-6 rounded-md shadow-containerShadow flex flex-col gap-6">
          <aside className="flex flex-col gap-2">
            <div className="flex-1 flex justify-center mb-2">
              <IoIosWarning size={30} className="text-stemGreen" />
            </div>
            <h3 className="text-stemGreen font-nunitoSemiBold">
              Deleting your data is a significant action that cannot be undone
            </h3>
            <p className="text-stemGreen">Are you sure you want to proceed?</p>
          </aside>

          <aside className="flex justify-center gap-4">
            <Button
              btnText="Don't Delete"
              isDark
              isTextSmall
              handleClick={handleModalClose}
              isButtonDisabled={isButtonDisabled}
            />
            <Button
              btnText="Proceed to Delete"
              isLight
              isTextSmall
              handleClick={handleDataDeletion}
            />
          </aside>

          {deleteStatus && (
            <aside>
              <p
                className={
                  deleteStatus.includes("Error")
                    ? "text-red-500"
                    : "text-green-500"
                }
              >
                {deleteStatus}
              </p>
            </aside>
          )}

          {isLoading && (
            <aside className="flex justify-center gap-4 text-stemGreen">
              <span>Deleting User</span>
              <ClipLoaders isColored />
            </aside>
          )}
        </section>
      </main>
    </Fragment>
  );
}

function AllDesignsAccountDeletionPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleDeleteModalPopup = () => {
    setIsButtonClicked(true);

    const loaderTimeout = setTimeout(() => {
      setIsButtonClicked(false);
      setIsModalOpen(true);
    }, 1000);

    return () => clearTimeout(loaderTimeout);
  };

  return (
    <AllDesignsSettingsRootLayout>
      <main className="ml-[22%] relative container px-5 pt-6 mx-auto font-nunitoRegular flex flex-col gap-8">
        <section className=" text-white">
          <h1 className="text-xl font-nunitoExtraBold mb-4">
            Deleting your Account will do the following:
          </h1>

          <ul className="text-sm flex flex-col gap-3">
            {cancelData.map((item, index) => (
              <li key={index} className="flex items-center">
                <AiOutlineCloseCircle
                  size={20}
                  className="text-stemGreen mr-2"
                />
                {item.text}
              </li>
            ))}
          </ul>
        </section>

        <section>
          <button
            className={`${
              isButtonClicked
                ? "flex items-center justify-center gap-3"
                : "inline-block"
            } rounded-md px-8 py-3 text-sm bg-stemGreen text-white`}
            onClick={handleDeleteModalPopup}
          >
            {isButtonClicked && <ClipLoaders />}
            <span>Delete My Account</span>
          </button>
        </section>

        {isModalOpen && (
          <DeleteAccountModal
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        )}
      </main>
    </AllDesignsSettingsRootLayout>
  );
}

DeleteAccountModal.propTypes = {
  setIsModalOpen: PropTypes.func,
};

export default AllDesignsAccountDeletionPage;
