import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function Button(props) {
  const {
    path,
    btnText,
    isLight,
    isDark,
    isBig,
    isTextSmall,
    extraText,
    handleClick,
    isTemplatesButton,
    isActive,
    isButtonDisabled,
    genreIndex,
  } = props;

  return (
    <Link to={path}>
      <button
        className={`${
          isLight
            ? 'bg-stemGreen text-white'
            : isTemplatesButton && isActive
            ? 'bg-white text-stemGreen border-white'
            : isTemplatesButton && !isActive
            ? 'bg-[#374151] text-white/50  border-white'
            : isDark
            ? 'bg-[#374151] text-white/70  border-white'
            : 'bg-white text-stemGreen  border-white'
        } ${isBig ? 'px-8 py-4' : 'px-4 py-2'} ${isTextSmall ? 'font-nunitoRegular text-sm' : 'font-nunitoSemiBold'}  ${
          isTemplatesButton ? 'rounded-[28px]' : 'rounded-md'
        } whitespace-nowrap flex-shrink-0 w-full`}
        onClick={handleClick}
        disabled={isButtonDisabled || (genreIndex === 2 && true)}
      >
        {extraText ? (
          <div className="flex items-center gap-2">
            <span>{btnText}</span>
            <span
              className={`${
                isActive ? 'text-white bg-stemGreen' : 'text-white/50 bg-stemGreen/30'
              } h-10 w-10 flex items-center justify-center rounded-full`}
            >
              {extraText}
            </span>
          </div>
        ) : (
          btnText
        )}
      </button>
    </Link>
  );
}

Button.propTypes = {
  path: PropTypes.string,
  btnText: PropTypes.string,
  extraText: PropTypes.number,
  genreIndex: PropTypes.number,
  isBig: PropTypes.bool,
  isLight: PropTypes.bool,
  isDark: PropTypes.bool,
  isTextSmall: PropTypes.bool,
  isTemplatesButton: PropTypes.bool,
  isActive: PropTypes.bool,
  isButtonDisabled: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default Button;
