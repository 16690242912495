import { useState } from 'react';
import { ResetPasswordInputs } from '../data/forgot-password-data';
import ForgotPasswordTop from '../components/forgot-password-components/ForgotPasswordTop';
import { useParams } from 'react-router-dom';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { sendNewPassword } from '../baseURL';
import { FullLoader } from '../components/globals/Loader';
import GlobalModal from '../components/globals/Modal';
import { IoIosLock } from 'react-icons/io';

function ResetPasswordPage() {
  const { key } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [responseText, setResponseText] = useState('');
  const [formData, setFormData] = useState({
    password: '',
    confirmNewPassword: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  console.log({ key });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmNewPassword) {
      toast.error('Passwords do not match', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
      return;
    }
    setIsLoading(true);

    const response = await sendNewPassword(key, formData.password);

    if (response.status == 200) {
      setIsLoading(false);
      setResponseText(response.data.message);
      setShow(true);
    } else {
      setIsLoading(false);

      toast.error('An Error Occured. Try again', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    }
  };

  return (
    <main className="h-[100vh] flex bg-generalBlack items-center justify-center">
      <ToastContainer />
      {show && <GlobalModal extraText="Login" path="/login" modalText={responseText} />}
      {isLoading && <FullLoader loaderText="Changing Password" />}
      <div className="bg-[#140B2A99] w-[93%] md:w-fit mx-auto shadow-containerShadow rounded-md py-12 px-8 md:px-8 lg:px-12 flex flex-col gap-4">
        <ForgotPasswordTop
          h1Text="Reset Password"
          h4Text="Enter your new password to complete password reset process"
          route="/forgot-password"
        />

        <form className="pt-4 flex flex-col gap-6 font-nunitoRegular" onSubmit={handleSubmit}>
          {ResetPasswordInputs().map((input) => (
            <div key={input.key} className="w-full flex flex-col">
              <label className="mb-3 text-white">{input.name}</label>
              <div className="relative flex">
                <input
                  className="flex-1 text-black rounded-sm pl-10 pr-3 py-3"
                  type={input.type}
                  placeholder={input.placeholder}
                  name={input.key}
                  value={formData[input.key]}
                  onChange={handleChange}
                />
                <IoIosLock className="absolute left-2 top-[12px] text-generalBlack text-2xl" />
                {input.visionIcon && input.visionIcon}
              </div>
            </div>
          ))}
          <button className="mt-2 font-nunitoBold w-full bg-stemGreen text-white p-4 rounded-md">
            Submit New Password
          </button>
        </form>
      </div>
    </main>
  );
}

export default ResetPasswordPage;
