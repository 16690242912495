import AllDesignsRootLayout from "../components/dashboard-components/AllDesignsRootLayout";

function AllDesignsDownloadsPage() {
  return (
    <AllDesignsRootLayout>
      <div className="ml-[22%] px-8 py-12 font-nunitoSemiBold text-white">AllDesignsDownloadsPage</div>
    </AllDesignsRootLayout>
  );
}

export default AllDesignsDownloadsPage;
