const navHeaderData = [
  {
    id: 1,
    headerText: "Image Editor",
  },
  {
    id: 2,
    headerText: "Video Editor",
  },
  {
    id: 3,
    headerText: "Remove Background",
  },
];

const navBarMainData = [
  {
    id: 1,
    linkText: "Home",
    linkPath: "/",
    flexOrder: "order-1",
  },
  {
    id: 2,
    linkText: "Pricing",
    linkPath: "/pricing",
    flexOrder: "order-3 md:order-4",
  },
];

const dropdownListData = [
  [
    {
      id: 1,
      linkText: "Features",
      linkPath: "/features",
    },
    // {
    //   id: 2,
    //   linkText: "Templates",
    //   linkPath: "/templates",
    // },
    {
      id: 3,
      linkText: "Image Editor",
      linkPath: "/image-editor",
    },
    {
      id: 4,
      linkText: "Video Editor",
      linkPath: "/video-editor",
    },
  ],
  [
    {
      id: 1,
      linkText: "About us",
      linkPath: "/about-us",
    },
    {
      id: 2,
      linkText: "Contact us",
      linkPath: "/contact-us",
    },
    {
      id: 3,
      linkText: "Blogs",
      linkPath: "/blogs",
    },
  ],
  [
    {
      id: 3,
      linkText: "Image Editor",
      linkPath: "/image-editor",
    },
    {
      id: 4,
      linkText: "Video Editor",
      linkPath: "/video-editor",
    },
  ],
];

const pathArr = [
  ["/features", "/templates", "/image-editor", "/video-editor"],
  ["/about-us", "/contact-us", "/blogs"],
];

export { navHeaderData, navBarMainData, dropdownListData, pathArr };
