import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import carouselData from '../../data/features-section-data';
import { settings } from '../../config/slider-config';

function Features() {
  return (
    <section className="pt-12 md:pt-16 px-3 md:px-16 bg-generalBlack">
      <div className="container mx-auto">
        <main className="mb-6 md:mb-8 text-center text-white">
          <h4 className="text-orange text-2xl font-nunitoExtraBold mb-6">Powerful Features</h4>
          <h3 className="text-3xl md:text-4xl font-nunitoExtraBold leading-10">
            Get Started with our powerful tools today
          </h3>
        </main>

        <main className="pt-8 md:py-4 lg:py-10 px-8 md:px-4 lg:px-0">
          <Slider {...settings}>
            {carouselData.map((data) => (
              <figure key={data.id} className="w-full relative h-[10rem] md:h-[25rem]">
                <img
                  className="rounded-tl-[3rem] w-full h-full object-contain md:object-cover border-4 md:border-8 border-transparent opacity-50"
                  src={data.imgSrc}
                  alt={data.altText}
                />
                <span className="left-[45px] underline text-base font-nunitoExtraBold absolute bottom-20 md:bottom-24 lg:bottom-20 md:pb-1 md:left-5 text-stemGreen">
                  {data.title}
                </span>
                <span className="w-3/4 md:w-[85%] left-[45px] text-[12px] font-nunitoSemiBold absolute bottom-4 md:bottom-6 md:left-5 text-white">
                  {data.description}
                </span>
              </figure>
            ))}
          </Slider>
        </main>
      </div>
    </section>
  );
}

export default Features;
