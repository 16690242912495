import React from "react";

import { useEffect, useRef, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
// import SocialButtons from "../components/register-components/SocialButtons";
import { checkOutFields } from "../data/checkout-page-data";
import stripelogo from "../assets/images/strippng.png";
import GoBack from "../components/globals/GoBack";
// import CustomSelect from "../components/checkout-components/CustomSelect";
import InputField from "../components/checkout-components/InputField";
import { PaystackButton } from "react-paystack";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  checkUser,
  createStripePayment,
  createUser,
  getExchangeRate,
  getIpInfo,
  verifyPayment,
} from "../baseURL";
import { ToastContainer } from "react-toastify";
import { renderErrorToast, renderSuccessToast } from "../util/toast";
import { Bars } from "react-loader-spinner";
import axios from "axios";
import AllDesignsSettingsRootLayout from "../components/dashboard-components/AllDesignsSettingsRootLayout";
import { jwtDecode } from "jwt-decode";
// import { FlutterWaveButton } from "flutterwave-react-v3";

const stripePromise = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC);

const CheckOutForm = () => {
  const user = jwtDecode(localStorage.getItem("token"));
  // const [isSelectClicked, setIsSelectClicked] = useState(false);
  const [pricingOptions, setPricingOptions] = useState("");
  const divRef = useRef(null);
  const [location, setLocation] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [typeText, setTypeText] = useState("");
  const [paystackPlan, setPaystackPlan] = useState("");
  const { packages } = useSelector((state) => state.packages);
  const [formData, setFormData] = useState({
    name: user.username,
    email: user.email,
  });
  const navigate = useNavigate();
  const { type, duration } = useParams();
  console.log(type, duration);
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    window.scrollTo(0, 0);
    const getNetworkIp = async () => {
      const response = await getIpInfo();
      setLocation(response.country_name);
    };
    // const getExchange = async () => {
    //   const response = await getExchangeRate();
    //   console.log(response);
    // };
    // getExchange();
    getNetworkIp();
    const checkoutPackage = packages.find(
      (item) => item.h5Text.split(" ")[0] == type
    );
    setTypeText(checkoutPackage.h5Text);
    setPricingOptions(
      duration == "Monthly"
        ? checkoutPackage.h2Text[0]
        : checkoutPackage.h2Text[1]
    );
    setPricingOptions(
      duration == "Monthly"
        ? checkoutPackage.h2Text[0]
        : checkoutPackage.h2Text[1]
    );
    setPaystackPlan(
      duration == "Monthly"
        ? checkoutPackage.paystackPlan[0]
        : checkoutPackage.paystackPlan[1]
    );
  }, [duration, packages, type]);

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleInput = (text, tag) => {
    setFormData((prev) => {
      return { ...prev, [tag]: text };
    });
  };
  const paystackProps = {
    email: formData.email,
    plan: paystackPlan,
    metadata: {
      name: formData.name,
    },
    publicKey: import.meta.env.VITE_PAYSTACK_PUBLIC,
    reference: new Date().getTime().toString(),
    onSuccess: (data) => {
      console.log(data);
      const getVerificationInformation = async () => {
        try {
          const response = await verifyPayment({
            reference: data.reference,
            interval: duration.toLowerCase().split("l")[0],
          });
          if (response.status) {
            renderSuccessToast(
              "payment successful, Please Login. Redirecting to login page"
            );
            setTimeout(() => {
              navigate("/login", { replace: true });
            }, [4000]);
          } else {
            renderErrorToast(
              "payment unsuccessful, Please Login. Redirecting to login page"
            );
            setTimeout(() => {
              navigate("/login", { replace: true });
            }, [4000]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      getVerificationInformation();
    },
  };

  //paystack payment
  const handlepy = () => {
    setIsLoading(true);

    divRef.current.children[0].click();
  };

  //stripe payment
  const handleStripePayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        email: formData.email,
        name: formData.name,
      },
    });

    if (error) {
      renderErrorToast(error.message);
      setIsLoading(false);
      return;
    }

    try {
      const data = {
        title: typeText,
        amount: Math.round(parseFloat(pricingOptions.split("$")[1], 10) * 100),
        duration: duration,
        email: formData.email,
        name: formData.name,
        userId: user.userId,
        interval: duration.toLowerCase().split("l")[0],
        paymentMethodId: paymentMethod.id,
      };

      const response = await createStripePayment(data);

      const result = await stripe.confirmCardPayment(response.clientSecret);
      if (result.error) {
        renderErrorToast(result.error);
        setIsLoading(false);
      } else {
        renderSuccessToast(
          "payment successful, Please Login. Redirecting to login page"
        );
        setTimeout(() => {
          navigate("/login", { replace: true });
        }, [4000]);
      }
    } catch (err) {
      setIsLoading(false);
      renderErrorToast(err.response.data.message);
      return;
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        color: "#ffffff", // Change text color to white
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <main className="min-h-[100vh] md:min-h-[100vh] flex bg-generalBlack text-white items-center">
      <ToastContainer />
      <div className="flex flex-col md:flex-row min-h-screen w-full">
        <section className="relative flex-1 flex flex-col space-y-10 pb-16 md:pb-0 bg-[#140B2A99] px-4 py-16 md:p-32 md:pt-20">
          <div className="w-1/4 md:w-full mx-auto">
            <GoBack route="/dashboard/settings/subscriptions/details" />
          </div>
          <div className="flex flex-col gap-4">
            <h2 className="text-center md:text-left text-6xl font-nunitoExtraBold">
              Checkout
            </h2>
            <p>{type} Plan</p>
          </div>
        </section>

        <section className="flex-1 flex flex-col space-y-8 px-4 py-16 md:p-32 md:pt-20">
          <form className="space-y-9 font-nunitoRegular flex flex-col">
            <div className="flex flex-col gap-4">
              <label className="text-white">Billing Information</label>
              <main className="flex flex-col space-y-5">
                {checkOutFields.map((field, index) => (
                  <aside
                    key={index}
                    className={`flex ${
                      index !== 0
                        ? "flex-col md:flex-row space-y-7 md:space-y-0 md:space-x-4"
                        : ""
                    }`}
                  >
                    <InputField
                      type={field.type}
                      placeholder={field.placeholder}
                      tag={field.tag}
                      value={index == 2 ? pricingOptions : formData[field.tag]}
                      disabled={true}
                      onInputChange={handleInput}
                    />
                  </aside>
                ))}
              </main>
            </div>
          </form>
          {location != "Nigeria" && (
            <CardElement options={cardElementOptions} />
          )}
          <div ref={divRef} className="hidden">
            <PaystackButton
              className="bg-white rounded-md py-4 text-stemGreen font-nunitoRegular flex gap-4 items-center justify-center"
              {...paystackProps}
            >
              Pay with{" "}
              <img
                className="w-1/3"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Paystack_Logo.png/1200px-Paystack_Logo.png"
              />
            </PaystackButton>
          </div>
          {location == "Nigeria" && (
            <button
              disabled={isLoading}
              onClick={handlepy}
              className="bg-white rounded-md py-4 text-stemGreen font-nunitoRegular flex gap-4 items-center justify-center"
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <Bars
                    height="15"
                    width="15"
                    color="#800190"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              ) : (
                <span className="flex gap-5 items-center justify-center">
                  Pay with{" "}
                  <img
                    className="w-1/3"
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0b/Paystack_Logo.png/1200px-Paystack_Logo.png"
                  />
                </span>
              )}
            </button>
          )}

          {location != "Nigeria" && (
            <button
              disabled={isLoading}
              className="bg-white rounded-md py-4 text-stemGreen font-nunitoRegular "
              onClick={handleStripePayment}
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <Bars
                    height="15"
                    width="15"
                    color="#800190"
                    ariaLabel="bars-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              ) : (
                <span className="flex gap-5 items-center justify-center">
                  Pay with <img className="w-14 -ml-4" src={stripelogo} />
                </span>
              )}
            </button>
          )}
        </section>
      </div>
    </main>
  );
};

const SubscriptionLoginCheckOut = () => {
  return (
    <AllDesignsSettingsRootLayout checkout={true}>
      <div className="ml-[22%] font-nunitoRegular text-white capitalize p-4">
        <Elements stripe={stripePromise}>
          <CheckOutForm />
        </Elements>
      </div>
    </AllDesignsSettingsRootLayout>
  );
};

export default SubscriptionLoginCheckOut;
