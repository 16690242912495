import { useParams } from "react-router-dom";
import AllDesignsSettingsRootLayout from "../components/dashboard-components/AllDesignsSettingsRootLayout";
import Package from "../components/pricing-components/Package";
import { jwtDecode } from "jwt-decode";

function AllDesignsSubscriptionPage() {
  const { param } = useParams();
  // return null
  const decoded = jwtDecode(localStorage.getItem("token"));

  const getBillingPeriod = () => {
    let billingDate;
    if (decoded.billingStart.includes("Z")) {
      billingDate = decoded.billingStart;
    } else {
      billingDate = parseInt(decoded.billingStart) * 1000;
    }
    const date = new Date(billingDate);
    console.log(date);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const newDate = new Date(year, month + 1, day);

    const newDay = newDate.getDate();
    const newMonth = newDate.getMonth() + 1;
    const newYear = newDate.getFullYear();

    return `${day}/${month + 1}/${year} - ${newDay}/${newMonth}/${newYear} `;
  };
  const getBillingPeriodYear = () => {
    let billingDate;
    if (decoded.billingStart.includes("Z")) {
      billingDate = decoded.billingStart;
    } else {
      billingDate = parseInt(decoded.billingStart) * 1000;
    }
    const date = new Date(billingDate);
    console.log(date);
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const newDate = new Date(year + 1, month, day);

    const newDay = newDate.getDate();
    const newMonth = newDate.getMonth();
    const newYear = newDate.getFullYear() + 1;

    return `${day}/${month + 1}/${year} - ${newDay}/${newMonth}/${newYear} `;
  };

  return (
    <AllDesignsSettingsRootLayout>
      <div className="ml-[22%] font-nunitoRegular text-white capitalize p-4">
        <div className="mt-5">
          <div className="border-2 rounded  border-[#541b5c] p-5">
            <p className="mb-2">
              Subscription :{" "}
              <span className="text-stemGreen font-nunitoBold">
                {decoded.plan}
              </span>
            </p>
            {decoded.isPremium && (
              <p>
                Billing Period :{" "}
                {decoded.interval == "month"
                  ? getBillingPeriod()
                  : getBillingPeriodYear()}
              </p>
            )}
          </div>
        </div>
        <Package user={decoded} page={param} />
      </div>
    </AllDesignsSettingsRootLayout>
  );
}

export default AllDesignsSubscriptionPage;
