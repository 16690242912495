import { Link } from 'react-router-dom';
import { ourTeamData } from '../../data/about-page-data';

function OurTeam() {
  return (
    <section className="bg-generalBlack py-8 md:py-0">
      <main className="bg-[#140B2A99] w-[96%] md:w-[90%] mx-auto rounded-2xl py-16 md:p-16">
        <h4 className="text-orange text-center text-4xl md:text-5xl font-nunitoExtraBold mb-12">Meet our Team</h4>
        <div className="flex flex-row flex-wrap">
          {ourTeamData.map((member) => (
            <div
              key={member.id}
              className="flex flex-col gap-1 flex-auto md:basis-1/3 border-[2rem] md:border-y-[2.5rem] md:border-x-[2rem] border-transparent"
            >
              <figure className="flex items-center justify-center pb-4">
                <img src={member.avatar} alt="Member Image" className="rounded-full" />
              </figure>
              <h4 className="text-white text-xl text-center font-nunitoBold">{member.name}</h4>
              <h5 className="text-white opacity-[.7] text-[13px] text-center font-nunitoRegular">{member.position}</h5>
							<Link to={member.bioLink} className='text-xs text-center underline underline-offset-2 text-white opacity-70'>{member.bioText}</Link>
            </div>
          ))}
        </div>
      </main>
    </section>
  );
}

export default OurTeam;
