import { BsDashLg } from "react-icons/bs";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Button from "../globals/Button";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

function HeroSection() {
  const [isInView, setIsInView] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      setIsInView(!isInView);
    }
  }, [inView]);

  const { text, isDeleting, isFinished } = useTypewriter({
    words: ["Visual Genius", "Textual Clarity", "Magic Edits"],
    loop: true,
  });

  return (
    <section>
      <main
        className={
          "h-[47rem] pt-28 pb-4 bg-generalBlack bg-blend-multiply bg-cover bg-no-repeat bg-center min-w-full md:h-[70vh] lg:h-[40rem]"
        }
      >
        <div className="container mx-auto flex flex-col h-full md:gap-4 py-24 md:py-6">
          <div className="flex flex-col gap-6 md:gap-0 md:pt-20">
            <div className="flex justify-center items-end mx-auto w-[80%] row-span-1 text-center text-white md:w-[80%] lg:leading-snug">
              <div className="flex flex-row">
                <span className="font-light grid place-items-right text-orange text-center md:text-xl">
                  <BsDashLg />
                </span>
                <h1 className="grid place-items-center tracking-[.1rem] whitespace-nowrap text-stemGreen font-nunitoBold text-sm px-4">
                  THE ALL IN ONE DESIGN TOOL
                </h1>
                <span className="md:text-xl text-orange">
                  <BsDashLg />
                </span>
              </div>
            </div>
            <h2 className="mx-auto row-span-1 leading-[64px] text-5xl text-center font-nunitoExtraBold md:text-5xl lg:w-[100%] lg:text-[64px] lg:tracking-[.01em] lg:leading-[60px] pt-3 md:pt-12">
              <span className="text-white inline-block w-[80%] md:w-full">
                Capture the Art of
              </span>
              <div className="text-orange md:pt-8">
                {text}
                {!isFinished && <Cursor />}
              </div>
              <span className="text-white font-nunitoBold text-lg inline-block w-[80%] md:w-full pt-10">
                Unleash Your Creativity. Transform your Moments
              </span>
            </h2>
          </div>

          <div className="pb-[4rem] flex items-center gap-4 justify-center md:items-start pt-12 md:pt-6 md:pb-0">
            <Button btnText="Get Started" to="/" isLight isBig />
            <Button btnText="Edit a Video" to="/" isBig />
          </div>
        </div>
      </main>

      <main className="bg-generalBlack w-full">
        <div
          className={`transition-transform ${
            isInView
              ? "transform scale-100 translate-y-0"
              : "transform scale-[0.94] translate-y-2"
          } duration-500 ease-in-out w-[89%] md:w-3/4 mx-auto bg-contain bg-no-repeat bg-center bg-herosectionBg h-[150px] md:h-[580px]`}
          ref={ref}
        ></div>
      </main>
    </section>
  );
}

export default HeroSection;
