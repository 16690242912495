import { useState } from 'react';
import { BiToggleLeft, BiToggleRight } from 'react-icons/bi';
import { IoMdArrowDropdown } from 'react-icons/io';
import { dropdownOptions, sliderOptions, toggleOptions } from '../../data/preferences-data';

function PreferencesComponent() {
  const [preferences, setPreferences] = useState({
    // Initialize states for all preferences
    fontSize: 14,
    imageQuality: 75,
    videoResolution: '1080p',
    brightness: 50,
    volume: 50,
    language: 'English',
    themeColor: 'Light',
    timeZone: 'GMT',
    notificationSound: 'Default',
    measurementUnits: 'Metric',
    darkMode: false,
    autoSave: true,
    emailNotifications: true,
    offlineMode: false,
    twoFactorAuth: true,
  });

  const handleSliderChange = (e, state) => {
    setPreferences({ ...preferences, [state]: parseInt(e.target.value) });
  };

  const handleDropdownChange = (e, state) => {
    setPreferences({ ...preferences, [state]: e.target.value });
  };

  const handleToggleChange = (state) => {
    setPreferences({ ...preferences, [state]: !preferences[state] });
  };

  return (
    <aside className="py-10 flex flex-col gap-8">
      {sliderOptions.map((option, index) => (
        <div key={index} className="lg:w-5/6 flex items-center justify-between gap-2">
          <span className="flex-1"> {option.label}</span>
          <div className="flex gap-20">
            <input
              type="range"
              min={option.min}
              max={option.max}
              value={preferences[option.state]}
              onChange={(e) => handleSliderChange(e, option.state)}
              className="w-56"
            />
            <span className="flex-1">{preferences[option.state]}</span>
          </div>
        </div>
      ))}

      {dropdownOptions.map((option, index) => (
        <div key={index} className="lg:w-5/6 flex items-center justify-between gap-2">
          <span className="flex-1">{option.label}:</span>
          <select
            value={preferences[option.state]}
            onChange={(e) => handleDropdownChange(e, option.state)}
            className="flex-1 appearance-none py-2 pl-2 pr-8 border border-gray-300 text-black text-center rounded"
          >
            {option.options.map((value, idx) => (
              <option key={idx} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
      ))}

      {toggleOptions.map((option, index) => (
        <div key={index} className="lg:w-5/6 flex items-center justify-between gap-2">
          <span>{option.label}</span>
          <div className="flex gap-20">
            <div className="flex items-center cursor-pointer" onClick={() => handleToggleChange(option.state)}>
              {preferences[option.state] ? <BiToggleRight size={45} /> : <BiToggleLeft size={45} />}
            </div>
            <div className="flex items-center justify-center">
              <span> {preferences[option.state] ? 'On' : 'Off'}</span>
            </div>
          </div>
        </div>
      ))}

      <div className="pt-6 flex justify-center">
        <button className="px-16 py-3 rounded text-white bg-stemGreen">Save Preferences</button>
      </div>
    </aside>
  );
}

export default PreferencesComponent;
