import BlogsBottom from './BlogsBottom';
import BlogsTopSection from './BlogsTopSection';

function BlogsMain() {
  return (
    <>
      <BlogsTopSection />
      <BlogsBottom />
    </>
  );
}

export default BlogsMain;
