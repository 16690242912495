import { createStore } from "polotno/model/store";

const store = createStore({
  key: import.meta.env.VITE_POLONTO_API_KEY,
});
const page = store.addPage();
// page.addElement({
//   x: 50,
//   y: 50,
//   type: "text",
//   fill: "black",
//   text: "hello",
// });

export default store;
