import ExportVideoHeader from '../components/export-video-components/ExportVideoHeader';
import ExportVideoMainArea from '../components/export-video-components/ExportVideoMainArea';
import ExportVideoBottom from '../components/export-video-components/ExportVideoBottom';

function ExportOrSaveVideoPage() {
  return (
    <main className="bg-generalBlack h-screen overflow-hidden">
      <div className="w-[80%] h-full mx-auto flex flex-col gap-3">
        <ExportVideoHeader />
        <ExportVideoMainArea />
        <ExportVideoBottom />
      </div>
    </main>
  );
}

export default ExportOrSaveVideoPage;
