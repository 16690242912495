import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLeftSidebarOpen: false,
};

const leftSidebarSlice = createSlice({
  name: 'leftSidebar',
  initialState,
  reducers: {
    closeSidebar: (state) => {
      state.isLeftSidebarOpen = false;
    },
    openSidebar: (state) => {
      state.isLeftSidebarOpen = true;
    },
  },
});

export const { closeSidebar, openSidebar } = leftSidebarSlice.actions;
export default leftSidebarSlice.reducer;
