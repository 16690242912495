import { useState } from 'react';

function useVisibility() {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible((prevVisible) => !prevVisible);
  };

  return { isVisible, toggleVisibility };
}

export default useVisibility;
