import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AllDesignsRootLayout from "../components/dashboard-components/AllDesignsRootLayout";
import DashboardOverview from "../components/dashboard-components/DashboardOverview";

function AllDesignsOverviewPage() {
  const auth = localStorage.getItem("userswiftsketch");
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth) {
      navigate("/login", { replace: true });
    }
  }, [auth, navigate]);

  return (
    <AllDesignsRootLayout>
      <DashboardOverview />
    </AllDesignsRootLayout>
  );
}

export default AllDesignsOverviewPage;
