import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import { RegisterFormInputs } from "../data/register-data";
import HeaderSection from "../components/register-components/HeaderSection";
import EmailRegister from "../components/register-components/EmailRegister";
import SocialButtons from "../components/register-components/SocialButtons";
import ImageSection from "../components/register-components/ImageSection";
import MobileLogo from "../components/register-components/MobileLogo";
import registerImg from "../assets/images/register-illustration.png";
import NoAccount from "../components/login-components/NoAccount";
import { FullLoader } from "../components/globals/Loader";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { renderErrorToast } from "../util/toast";

const DividerLine = ({ isBold }) => (
  <div
    className={`${
      isBold ? "h-[2px] bg-stemGreen" : "h-[.5px] bg-gray-300"
    } flex-grow`}
  ></div>
);

function RegisterPage({ baseurl }) {
  const [passwordError, setPasswordError] = useState("");
  const [formResponse, setFormResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [responseColor, setResponseColor] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    isAgreed: false,
  });
  console.log(baseurl);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
    setPasswordError("");
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, isAgreed: e.target.checked });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.password.length < 8) {
      setPasswordError("Passwords should be at least 8 characters");
      renderErrorToast("Passwords should be at least 8 characters");
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setPasswordError("Passwords do not match");
      renderErrorToast("Passwords should be at least 8 characters");

      return;
    }

    if (formData.isAgreed !== true) {
      setPasswordError(
        "Cannot continue without agreeing to Terms & Conditions"
      );
      renderErrorToast("Passwords should be at least 8 characters");

      return;
    }
    setIsLoading(true);
    axios
      .post(`${baseurl}/auth/register`, formData)
      .then((res) => {
        setFormResponse(res.data.message);
        toast.success(`${res.data.message}`, {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setResponseColor("text-stemGreen");
        navigate("/login", { replace: true });
      })
      .catch((err) => {
        setIsLoading(false);
        setFormResponse(err.response.data.message);
        toast.error(`${err.response.data.message}`, {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setResponseColor("text-orange");
      });
  };

  return (
    <section
      className={`${
        passwordError || formResponse ? "pt-16 pb-36" : "py-16"
      }  bg-generalBlack flex flex-col md:flex-row gap-12 md:gap-6 px-8 md:px-16`}
    >
      <ToastContainer />

      {isLoading && <FullLoader loaderText="Registering" isColored />}

      <main className="md:h-[52.5rem] w-full md:w-1/2 md:pr-8 flex flex-col gap-3">
        <MobileLogo />
        <HeaderSection text="Bring your creative ideas to life with SwiftSketch." />
        <SocialButtons />
        <EmailRegister checkOutText="Or sign up with email" />

        <form className="pt-4 flex flex-col gap-4" onSubmit={handleSubmit}>
          <div className="flex flex-col md:flex-row md:flex-wrap gap-4">
            {RegisterFormInputs().map((input, index) => (
              <aside
                key={index}
                className="font-nunitoRegular mb-4 md:flex md:flex-grow md:flex-col md:space-x-4"
              >
                <div className="relative w-full flex flex-col">
                  <label className="mb-2 text-white">{input.name}</label>
                  <input
                    className="text-black rounded-sm px-3 py-3 placeholder:font-nunitoRegular"
                    type={input.type}
                    placeholder={input.placeholder}
                    name={input.key}
                    value={formData[input.key]}
                    onChange={handleChange}
                  />
                  {input.visionIcon && input.visionIcon}
                </div>
              </aside>
            ))}
          </div>

          <aside className="mb-4 md:flex flex-col space-y-8 md:space-y-16 pt-8">
            <div className="flex items-center space-x-2">
              <input
                className="h-4 w-4"
                type="checkbox"
                id="isAgreed"
                checked={formData.isAgreed}
                onChange={handleCheckboxChange}
              />
              <label
                htmlFor="isAgreed"
                className="pl-3 text-white font-nunitoRegular"
              >
                I agree with{" "}
                <Link
                  className="inline-flex text-stemGreen"
                  to="/terms-and-conditions"
                >
                  Terms and conditions
                </Link>
              </label>
            </div>
            {formResponse ? (
              <p className={`${responseColor} text-base font-nunitoRegular`}>
                {formResponse}!
              </p>
            ) : (
              passwordError && (
                <p className="text-red-500 text-base font-nunitoRegular">
                  {passwordError}
                </p>
              )
            )}
            <div>
              <button className="font-nunitoBold w-full bg-stemGreen text-white p-4 rounded-md">
                Create free account
              </button>
            </div>
          </aside>
        </form>
        <NoAccount
          pText="Already have an account?"
          linkText="Login"
          linkPath="/login"
        />
      </main>

      <ImageSection imgSrc={registerImg} />
    </section>
  );
}

DividerLine.propTypes = {
  isBold: PropTypes.bool,
};

RegisterPage.propTypes = {
  baseurl: PropTypes.string,
};

export { DividerLine };
export default RegisterPage;
