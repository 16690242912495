import { useEffect, useState } from 'react';
import Button from '../globals/Button';

function TemplatesGenre() {
  const [isTabOneActive, setIsTabOneActive] = useState(false);
  const [isTabTwoActive, setIsTabTwoActive] = useState(false);

  const handleImagesShow = () => {
    setIsTabOneActive(true);
    setIsTabTwoActive(false);
  };

  const handleVideosShow = () => {
    setIsTabTwoActive(true);
    setIsTabOneActive(false);
  };

  useEffect(() => {
    handleImagesShow();
  }, []);

  return (
    <section className="flex w-[88%] mx-auto mb-6 justify-center">
      <aside className="flex gap-2 w-fit py-2 px-2 border border-[#374151] rounded-lg shadow-2xl">
        <Button
          btnText="Images"
          handleClick={handleImagesShow}
          extraText={96}
          isActive={isTabOneActive}
          genreIndex={1}
          isTemplatesButton
        />
        <Button
          btnText="Videos"
          handleClick={handleVideosShow}
          extraText={45}
          isActive={isTabTwoActive}
          genreIndex={2}
          isTemplatesButton
        />
      </aside>
    </section>
  );
}

export default TemplatesGenre;
