import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const VideoEditingPage = () => {
  const navigate = useNavigate();
  const auth = localStorage.getItem("user");
  console.log(auth);

  useEffect(() => {
    if (!auth) {
      navigate("/login", { replace: true });
    }
    window.scrollTo(0, 0);
  }, [auth]);
  return (
    <div className="h-screen">
      <div className="mt-48">VideoEditingPage</div>;
    </div>
  );
};

export default VideoEditingPage;
