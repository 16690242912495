import { Fragment } from 'react';
import Accordion from './Accordion';
import accordionData from '../../data/faq-data';
import SectionRowOne from '../globals/SectionRowOne';

function FAQs() {
  return (
    <Fragment>
      <section className="pt-0 md:pt-8 pb-16 bg-generalBlack">
        <div className="container mx-auto">
          <SectionRowOne h4text="FAQs" h3text="Frequently Asked Questions" />

          <main>
            <div className="mb-8">
              {accordionData.map((accordion) => (
                <Accordion
                  key={accordion.id}
                  number={accordion.id}
                  question={accordion.question}
                  answer={accordion.answer}
                />
              ))}
            </div>
          </main>
        </div>
      </section>
    </Fragment>
  );
}

export default FAQs;
