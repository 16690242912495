import { Fragment } from 'react';
import BlogsHero from '../components/blogs-components/BlogsHero';
import BlogsMain from '../components/blogs-components/BlogsMain';

function BlogsPage() {
  return (
    <Fragment>
      <BlogsHero />
      <BlogsMain />
    </Fragment>
  );
}

export default BlogsPage;
