import carouselImgOne from '../assets/images/carousel-img-1.png';
import carouselImgTwo from '../assets/images/carousel-img-2.png';
import carouselImgThree from '../assets/images/carousel-img-3.png';
import carouselImgFour from '../assets/images/carousel-img-4.png';

const carouselData = [
  {
    id: 1,
    imgSrc: carouselImgOne,
    altText: 'carousel Image One',
    title: 'Powerful Editing Tools',
    description: "Unleash Your Creativity with SwiftSketch's Robust Editing Features for Stunning Visuals.",
  },
  {
    id: 2,
    imgSrc: carouselImgTwo,
    altText: 'carousel Image Two',
    title: 'Dynamic Animation Studio',
    description: "Elevate your storytelling with Dynamic Animation Studio's cutting-edge technology and breathtaking visuals.",
  },
  {
    id: 3,
    imgSrc: carouselImgThree,
    altText: 'carousel Image Three',
    title: 'Intuitive Design Interface',
    description: "Experience seamless creativity with our Intuitive Design Interface, making complex designs simple and accessible for all.",
  },
  {
    id: 4,
    imgSrc: carouselImgFour,
    altText: 'carousel Image Four',
    title: 'Express Text Graphics',
    description: "Bring your words to life with Express Text Graphics, enabling captivating and dynamic text effects effortlessly.",
  },
  {
    id: 5,
    imgSrc: carouselImgTwo,
    altText: 'carousel Image Two',
    title: 'Dynamic Animation Studio',
    description: "Elevate your storytelling with Dynamic Animation Studio's cutting-edge technology and breathtaking visuals.",
  },
  {
    id: 6,
    imgSrc: carouselImgThree,
    altText: 'carousel Image Three',
    title: 'Intuitive Design Interface',
    description: "Experience seamless creativity with our Intuitive Design Interface, making complex designs simple and accessible for all.",
  },
];

export default carouselData;
