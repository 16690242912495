import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';

function ClipLoaders({ isColored }) {
  return <ClipLoader color={isColored ? '#800190' : '#f1f1f1'} size={25} />;
}

ClipLoaders.propTypes = {
  isColored: PropTypes.bool,
};

export default ClipLoaders;
