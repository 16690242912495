import { paragraphs } from '../../data/about-page-data';

function BottomContainer() {
  return (
    <main className="bg-generalBlack">
      <div className="w-full md:w-3/4 px-6 md:px-0 mx-auto pt-2 md:pt-16 pb-16 bg-generalBlack container flex flex-col space-y-10 md:space-y-6">
        {paragraphs.map((paragraph) => (
          <p
            key={paragraph.id}
            className={`${paragraph.color} text-justify text-sm 2xl:text-base font-nunitoRegular leading-[1.6rem] md:leading-[1.95rem]`}
          >
            {paragraph.pText}
          </p>
        ))}
      </div>
    </main>
  );
}

export default BottomContainer;
