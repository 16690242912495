import { Link } from 'react-router-dom';
import mobileLogo from '../../assets/images/swiftsketch-mobile-colored.png';
import GoBack from '../globals/GoBack';

function MobileLogo() {
  return (
    <div className="mb-8 flex flex-col gap-4">
      <GoBack route='/' />
      <Link to="/" className="flex w-20 h-20 md:-translate-x-[3px]">
        <img src={mobileLogo} alt="Creative Ideas" className="w-full h-full object-contain" />
      </Link>
    </div>
  );
}

export default MobileLogo;
