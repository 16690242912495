import { useState } from 'react';
import PropTypes from 'prop-types';
import { FaChevronDown } from 'react-icons/fa';

function Accordion({ number, question, answer }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <main className="mx-auto w-[94%] md:w-5/6 py-6 shadow-2xl border-b border-white">
      <div className="w-full bg-generalBlack rounded-md">
        <div className="flex items-start md:items-center justify-between space-x-2 pt-8 md:pt-10 pb-4 md:pb-5 px-4 cursor-pointer" onClick={toggleAccordion}>
          <div className="flex items-start md:items-center">
            <span className="text-orange text-3xl md:text-4xl font-nunitoExtraBold">0{number}</span>
            <h4 className="text-white text-xl md:text-2xl pl-4 md:pl-8 font-nunitoExtraBold">{question}</h4>
          </div>
          <div className="flex items-center justify-center pt-[0.3rem] md:pt-0">
            <FaChevronDown className={`text-white transform transition-transform ${isOpen ? 'rotate-180' : ''}`} />
          </div>
        </div>
        {isOpen && (
          <div className="text-white font-nunitoRegular leading-7 p-4 border-t">
            <p className='text-sm 2xl:text-base leading-[1.8rem]'>{answer}</p>
          </div>
        )}
      </div>
    </main>
  );
}

Accordion.propTypes = {
  number: PropTypes.number,
  question: PropTypes.string,
  answer: PropTypes.string,
};

export default Accordion;
