import CreativeEditorSDK from "@cesdk/cesdk-js";

import React, { useEffect, useRef, useState } from "react";
import {
  checkDownload,
  updateVideoUploads,
  uploadImages,
  uploadVideos,
} from "../baseURL";
import { Link, useNavigate } from "react-router-dom";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { FullLoader } from "../components/globals/Loader";
import brandLogo from "../assets/images/swiftsketch-desktop-colored.png";

function VideoEditingPage() {
  const user = JSON.parse(localStorage.getItem("userswiftsketch"));
  const token = localStorage.getItem("token");

  const [isLoading, setIsLoading] = useState(false);

  const config = {
    license: "PBgEbk0GjPXBh3ZLpLL1hZRURB06pxSYCBxs3t4tbcXZQC6MtXzbqU0hgfMb_JXi",
    userId: "guides-user",
    theme: "dark",
    ui: {
      elements: {
        view: "default",
        panels: {
          settings: true,
        },
        navigation: {
          position: "top",
          action: {
            // save: true,
            load: true,
            download: true,
            export: true,
          },
        },
        libraries: {
          backgroundTrackLibraryEntries: ["ly.img.image", "ly.img.video"],
        },
      },
    },
    // Enable local uploads in Asset Library
    callbacks: {
      onUpload: async (file, onProgress) => {
        const uri = URL.createObjectURL(file);
        const newVideo = {
          id: file.name,
          meta: {
            uri: uri,
            thumbUri: uri,
          },
        };
        await updateVideoUploads({ userId: user.userId });
        return Promise.resolve(newVideo);
      },

      onSave: (scene) => {
        const element = document.createElement("a");
        const base64Data = btoa(unescape(encodeURIComponent(scene)));
        element.setAttribute(
          "href",
          `data:application/octet-stream;base64,${base64Data}`
        );
        element.setAttribute(
          "download",
          `cesdk-${new Date().toISOString()}.scene`
        );

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();
        console.log("uploaded");
        document.body.removeChild(element);
      },
      onLoad: "upload",
      // onUpload: (file, onProgress) => {
      //   console.log("hahaha");
      //   return Promise.resolve(file);
      // },
      onDownload: "download",
      onExport: async (blobs, options) => {
        setIsLoading(true);
        try {
          const response = await checkDownload(token);
          if (response.status == 200) {
            if (response.data.downloadable) {
              const type = options.mimeType.split("/")[1];

              const element = document.createElement("a");
              const uri = URL.createObjectURL(blobs[0]);
              console.log(uri);
              const reader = new FileReader();
              reader.onloadend = async () => {
                const data = {
                  file: reader.result,
                  upload_preset: "mjr7evpg",
                  folder: `videoexport/${user.email.split("@")[0]}${
                    user.userId
                  }`,
                };
                const sendingInformation = {
                  data: { ...data },
                  user_id: user.userId,
                };
                const response = await uploadVideos(sendingInformation);
                if (response.status == 200) {
                  setIsLoading(false);
                  toast.success(`${response.data.message}`, {
                    position: "top-right",
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                  });
                }
              };
              reader.readAsDataURL(blobs[0]);

              element.setAttribute("href", uri);
              element.setAttribute(
                "download",
                `cesdk-${new Date().toISOString()}.${type}`
              );

              element.style.display = "none";
              document.body.appendChild(element);

              element.click();

              document.body.removeChild(element);
              URL.revokeObjectURL(uri);
            } else {
              setIsLoading(false);

              toast.error(`Download limit reached, upgrade plan`, {
                position: "top-right",
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
            }
          } else {
            setIsLoading(false);

            toast.error(`An error occured`, {
              position: "top-right",
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            });
          }
        } catch (error) {
          setIsLoading(false);

          toast.error(`An error occured`, {
            position: "top-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      },
    },
  };

  const cesdk_container = useRef(null);
  const [cesdk, setCesdk] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!cesdk_container.current) return;
    if (!user) {
      navigate("/login", { replace: true });
    }
    let cleanedUp = false;
    let instance;
    CreativeEditorSDK.create(cesdk_container.current, config).then(
      async (_instance) => {
        instance = _instance;
        if (cleanedUp) {
          instance.dispose();
          return;
        }

        // Do something with the instance of CreativeEditor SDK, for example:
        // Populate the asset library with default / demo asset sources.
        await Promise.all([
          instance.addDefaultAssetSources(),
          instance.addDemoAssetSources({ sceneMode: "Video" }),
        ]);
        await instance.createVideoScene();

        setCesdk(instance);
      }
    );
    const cleanup = () => {
      cleanedUp = true;
      instance?.dispose();
      setCesdk(null);
    };
    return cleanup;
  }, [cesdk_container]);
  return (
    <>
      {isLoading && <FullLoader loaderText="saving to projects" isColored />}
      <ToastContainer />
      <div className="absolute z-50 flex justify-center w-fit left-[40%] mt-2">
        <Link to="/dashboard">
          <img src={brandLogo} className="w-48" />
        </Link>
      </div>
      <div
        ref={cesdk_container}
        style={{ width: "100vw", height: "100vh" }}
      ></div>
    </>
  );
}

export default VideoEditingPage;
