import featuresPageData from '../../data/features-page-data';

function BottomSection() {
  return (
    <section className="px-8 md:px-16 pt-8 md:pt-20 pb-8 bg-generalBlack">
      <div className="container mx-auto flex flex-col gap-28 md:gap-12">
        {featuresPageData.map((feature) => (
          <main key={feature.id} className="mb-0 md:mb-8 flex-1 flex flex-col md:flex-row gap-4 md:gap-12">
            <div className={`flex-1 flex flex-col md:order-${feature.flexOrder}`}>
              <aside className="mb-4">{feature.svgIcon}</aside>
              <aside className="mb-4">
                <h3 className="text-3xl font-nunitoExtraBold text-white">{feature.h3Text}</h3>
              </aside>
              <aside>
                <p className='text-[#E8E9E9] text-base font-nunitoRegular'>{feature.pText}</p>
              </aside>
            </div>

            <div className='flex-1'>
              <figure className='mt-4 md:mt-0'>
                <img src={feature.imgSrc} alt={feature.alText} className="w-full h-full object-cover rounded-lg" />
              </figure>
            </div>
          </main>
        ))}
      </div>
    </section>
  );
}

export default BottomSection;
