import PropTypes from "prop-types";
import AllDesignsHeader from "./AllDesignsHeader";
import {
  allDesignsSidebarData,
  sidebarPathnameArray,
} from "../../data/allDesigns-page-data";
import AllDesignsDefaultSidebar from "./AllDesignsDefaultSidebar";
import LeftSidebar from "../editor-components/LeftSidebar";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { openSidebar } from "../../redux/actions";
import useResize from "../../custom-hooks/useResize";

function AllDesignsRootLayout({ children }) {
  const { pathname } = window.location;
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const { width, breakpoint, isOpen, setIsOpen, handleMenubarToggle } =
    useResize();
  useEffect(() => {
    if (sidebarPathnameArray.includes(pathname) && window.location.reload) {
      dispatch(openSidebar());
    }
  }, []);
  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "unset"; // Re-enable scrolling
    }

    // Cleanup function to reset styles when component unmounts
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [openModal]);

  return (
    <>
      {openModal && (
        <div className="fixed w-full h-full top-0 left-0 bg-[#11111130] z-40 overflow-y-hidden">
          <div className="flex-1 h-screen flex flex-row bg-black px-2 w-[70%]">
            <LeftSidebar leftSidebarData={allDesignsSidebarData} />
            <AllDesignsDefaultSidebar />
          </div>
        </div>
      )}
      <section className="min-h-screen overflow-x-hidden flex flex-col">
        <AllDesignsHeader setModal={setOpenModal} modal={openModal} />

        <main className="mt-24 flex-1 h-full flex flex-row">
          {width > breakpoint && (
            <LeftSidebar leftSidebarData={allDesignsSidebarData} />
          )}
          {width > breakpoint && <AllDesignsDefaultSidebar />}

          <aside className="flex-1 bg-editorMain">{children}</aside>
        </main>
      </section>
    </>
  );
}

AllDesignsRootLayout.propTypes = {
  children: PropTypes.node,
};

export default AllDesignsRootLayout;
