import { useParams } from 'react-router-dom';
import { blogsData } from '../data/blogs-page-data';

function SingleBlogPage() {
  const { blogId } = useParams();
  const blog = blogsData.find((blog) => blog.blogId === parseInt(blogId));

  return (
    <article className="bg-generalBlack pt-56 pb-20">
      <aside className="w-3/4 mx-auto font-nunitoRegular leading-[1.9rem] text-white text-sm">
        {blog && blog.article}
      </aside>
    </article>
  );
}

export default SingleBlogPage;
