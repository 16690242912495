import { createElement } from 'react';
import { ourSummaryData } from '../../data/about-page-data';
import { AboutSvg } from '../../assets/svgs/svgs';
import heroSectionImg from '/herosection-bg.png';

function TopSectionImages() {
  return (
    <main className="bg-generalBlack">
      <div className="relative">
        <div className="flex justify-center px-16 md:px-0">{createElement(AboutSvg)}</div>
        <div className="relative top-20 md:top-16 px-8 md:px-0 w-full md:w-3/4 mx-auto flex flex-wrap gap-4 md:gap-4 md:flex-row text-white">
          {ourSummaryData.map((summary) => (
            <figure
              key={summary.id}
              className="flex-auto text-[#B2B3F1] bg-[#140B2A99] flex flex-col space-y-2 px-4 py-4 md:px-8 md:py-12 rounded-xl"
            >
              <h3 className="text-center text-4xl md:text-6xl font-nunitoExtraBold">{summary.cardHeader}</h3>
              <h5 className="text-center text-sm md:text-base font-nunitoBold">{summary.cardParagraph}</h5>
            </figure>
          ))}
        </div>
      </div>
      <div className="pt-24 pb-20 lg:pb-32">
        <figure className="bg-generalBlack p-2 flex items-center justify-center">
          <img
            src={heroSectionImg}
            alt="About Page Bg Image"
            className="translate-y-[20%] h-full w-[90%] lg:w-[80%] object-cover"
          />
        </figure>
      </div>
    </main>
  );
}

export default TopSectionImages;
