import { Fragment, useEffect, useState } from 'react';
import { BiSolidLeftArrowSquare, BiSolidRightArrowSquare } from 'react-icons/bi';
import { activeTabStyling, inactiveTabStyling, templateTabsData } from '../../data/template-page-data';
import TemplateImages from './TemplateImages';
import { FaHandPointDown } from 'react-icons/fa';
import TemplatesGenre from './TemplatesGenre';

function TabsWrapper() {
  const [activeTab, setActiveTab] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const container = document.getElementById('tabsContainer');

    if (container) {
      setContainerWidth(container.scrollWidth - container.clientWidth);
    }
  }, [scrollPosition]);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handleScroll = (direction) => {
    const container = document.getElementById('tabsContainer');
    const scrollAmount = direction === 'left' ? -150 : 150;
    container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    setScrollPosition(container.scrollLeft + scrollAmount);
  };

  return (
    <Fragment>
      <aside className="w-[90%] mx-auto flex items-center justify-center animate-bounce mt-2 mb-10">
        <div>
          <FaHandPointDown size={100} className="text-stemGreen" />
        </div>
      </aside>

      <TemplatesGenre />

      <main className="relative flex flex-col p-4 gap-8">
        <aside
          className="bg-[#374151] text-white pb-4 z-10 w-[90%] mb-5 mx-auto flex overflow-x-auto scrollbar-hide"
          id="tabsContainer"
        >
          {templateTabsData.map(({ linkText }, index) => (
            <div
              key={index}
              className={`whitespace-nowrap relative cursor-pointer px-8 py-3 border border-[#686B6E] transition-all ${
                activeTab === index ? activeTabStyling : inactiveTabStyling
              }`}
              onClick={() => handleTabClick(index)}
            >
              <p>{linkText}</p>
            </div>
          ))}
        </aside>

        <aside className="absolute w-full top-0 left-0 py-4 pl-4 pr-8 m-2 flex items-center justify-between">
          {scrollPosition > 0 ? (
            <div className="cursor-pointer" onClick={() => handleScroll('left')}>
              <BiSolidLeftArrowSquare size={30} className="text-stemGreen" />
            </div>
          ) : (
            <div></div>
          )}
          {scrollPosition < containerWidth && (
            <div className="cursor-pointer" onClick={() => handleScroll('right')}>
              <BiSolidRightArrowSquare size={30} className="text-stemGreen" />
            </div>
          )}
        </aside>

        <TemplateImages activeTab={activeTab} />
      </main>
    </Fragment>
  );
}

export default TabsWrapper;
