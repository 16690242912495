import { CgMail } from 'react-icons/cg';
import { IoIosLock } from 'react-icons/io';
import useVisibility from '../custom-hooks/useVisibility';
import { VisibilityToggleBtn } from './register-data';

const LoginFormInputs = () => {
  const { isVisible, toggleVisibility } = useVisibility();

  return [
    {
      name: 'Email address',
      type: 'email',
      placeholder: 'Email address',
      key: 'email',
      icon: <CgMail className="absolute left-3 top-[14px] text-generalBlack text-2xl" />,
    },
    {
      name: 'Password',
      type: isVisible ? 'text' : 'password',
      placeholder: 'Password',
      key: 'password',
      icon: <IoIosLock className="absolute left-3 top-[12px] text-generalBlack text-2xl" />,
      visionIcon: <VisibilityToggleBtn isVisible={isVisible} onClick={toggleVisibility} />,
    },
  ];
};

export { LoginFormInputs };
