import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

function NoAccount({ pText, linkText, linkPath }) {
  return (
    <div className="mt-4 flex flex-row space-x-2 items-center justify-center text-base text-white font-nunitoRegular">
      <p>{pText}</p>
      <Link to={linkPath} className="text-stemGreen">
        {linkText}
      </Link>
    </div>
  );
}

NoAccount.propTypes = {
  pText: PropTypes.string,
  linkText: PropTypes.string,
  linkPath: PropTypes.string,
};

export default NoAccount;
