import appDescriptionVid from '../../assets/videos/Food.mp4';
import Button from '../globals/Button';

function Tutorials() {
  return (
    <section className="bg-generalBlack">
      <div className="container mx-auto pt-44  pb-10 md:pb-24">
        <div className="md:w-5/6 bg-black py-16 px-8 md:px-16 md:pt-28 md:pb-16 rounded-xl mx-auto">
          <main className="flex flex-col md:flex-row mb-1 md:mb-12 gap-20">
            <div className="order-2 flex-1 text-white">
              <div>
                <h3 className="text-center md:text-left text-2xl whitespace-nowrap font-nunitoExtraBold mb-4 text-orange -translate-x-3 md:translate-x-0">
                  Video Tutorials
                </h3>
                <h2 className="text-3xl md:text-4xl text-center md:text-left font-nunitoExtraBold leading-10 mb-4">
                  Master the Art of Visual Creation
                </h2>
                <p className="font-nunitoRegular text-left md:text-justify text-sm 2xl:text-base leading-[1.6rem] pl-1 md:pl-0">
                  In this comprehensive video tutorial, we&apos;ll walk you through the essential steps to get started
                  with SwiftSketch. From navigating the user-friendly interface to mastering powerful editing tools,
                  you&apos;ll gain the knowledge and confidence to unleash your creativity and create stunning visual
                  masterpieces. Join us as we dive into the world of SwiftSketch and unlock the potential of your
                  imagination.
                </p>
              </div>
              <div className="mt-8 flex space-x-6 justify-center md:justify-start pt-8 md:pt-0">
                <Button btnText="View all tutorials" isLight isBig />
              </div>
            </div>

            <main className="order-1 flex-1">
              <figure className="w-full h-full">
              <video
                src={appDescriptionVid}
                // controls
                className="rounded-md w-full h-full object-cover"
                alt="Features section video"
                autoPlay
                loop
                muted
                />
              </figure>
            </main>
          </main>
        </div>
      </div>
    </section>
  );
}

export default Tutorials;
