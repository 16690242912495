import { useRef } from 'react';
import Slider from 'react-slick';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import testimonialsData from '../../data/testimonials-data';
import SectionRowOne from '../globals/SectionRowOne';

const Testimonials = () => {
  const prevSliderTriggerRef = useRef(null);
  const nextSliderTriggerRef = useRef(null);
  const sliderRef = useRef(null);

  const handlePrevSliderClick = () => {
    if (sliderRef.current && sliderRef.current.slickPrev) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNextSliderClick = () => {
    if (sliderRef.current && sliderRef.current.slickNext) {
      sliderRef.current.slickNext();
    }
  };

  const prevArrowRef = useRef(null);
  const nextArrowRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <FaChevronRight
        className="text-4xl absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
        ref={nextArrowRef}
      />
    ),
    prevArrow: (
      <FaChevronLeft
        className="text-4xl absolute top-1/2 left-4 transform -translate-y-1/2 cursor-pointer"
        ref={prevArrowRef}
      />
    ),
  };

  return (
    <section className="pt-16 pb-0 md:pb-4 bg-generalBlack testimonials">
      <div className="mb-12">
        <SectionRowOne h4text="Testimonials" h3text="What professionals say about us" isTestimonial />
      </div>
      <div className="container mx-auto">
        <main className="relative h-[30rem]">
          <Slider {...settings} ref={sliderRef}>
            {testimonialsData.map((testimonial) => (
              <div key={testimonial.id} className="focus:outline-none">
                <div className="flex">
                  <aside className="w-1/2">
                    <figure className="w-full h-full">
                      <img
                        src={testimonial.image}
                        alt={`Testimonial by ${testimonial.author}`}
                        className="w-full h-full object-cover"
                      />
                    </figure>
                  </aside>

                  <aside className="w-[70%] md:w-1/2 bg-black py-6 pl-4 pr-2 md:py-0 md:pl-8 md:pr-6 flex items-center justify-center">
                    <div className="flex flex-col gap-1 md:gap-3">
                      <h3 className="font-nunitoRegular text-left md:text-justify text-white text-sm 2xl:text-base leading-[1.6rem] mb-2 md:pr-4">{testimonial.quote}</h3>
                      <h4 className="text-white text-xl font-nunitoBold mb-2 underline underline-offset-4 decoration-wavy decoration-stemGreen">
                        {testimonial.author}
                      </h4>
                      <h5 className="font-nunitoRegular text-white text-sm">
                        {testimonial.position} @{' '}
                        <span className="font-nunitoBold text-white underline underline-offset-4 decoration-stemGreen">{testimonial.company}</span>
                      </h5>
                      <div className="flex gap-3 mt-4 w-1/3">
                        <div ref={prevSliderTriggerRef} onClick={handlePrevSliderClick} className="py-4 cursor-pointer">
                          <FaArrowAltCircleLeft size={24} className="text-stemGreen" />
                        </div>
                        <div ref={nextSliderTriggerRef} onClick={handleNextSliderClick} className="py-4 cursor-pointer">
                          <FaArrowAltCircleRight size={24} className="text-stemGreen" />
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            ))}
          </Slider>
        </main>
      </div>
    </section>
  );
};

export default Testimonials;
