import { Fragment } from 'react';
import Package from '../components/pricing-components/Package';
import TopPricingSection from '../components/pricing-components/TopPricingSection';

function PricingPage() {
  return (
    <Fragment>
      <TopPricingSection />
      <Package />
    </Fragment>
  );
}

export default PricingPage;
