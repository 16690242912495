import useVisibility from '../custom-hooks/useVisibility';
import { VisibilityToggleBtn } from './register-data';

const ForgotPasswordInputs = () => [
  {
    name: 'Email address',
    type: 'email',
    placeholder: 'Email address',
    key: 'email',
  },
];

const ResetPasswordInputs = () => {
  const { isVisible: isPasswordVisible, toggleVisibility: togglePasswordVisibility } = useVisibility();
  const { isVisible: isConfirmPasswordVisible, toggleVisibility: toggleConfirmPasswordVisibility } = useVisibility();

  return [
    {
      name: 'New Password',
      type: isPasswordVisible ? 'text' : 'password',
      placeholder: 'New Password',
      key: 'password',
      visionIcon: <VisibilityToggleBtn isVisible={isPasswordVisible} onClick={togglePasswordVisibility} />,
    },
    {
      name: 'Confirm Password',
      type: isConfirmPasswordVisible ? 'text' : 'password',
      placeholder: 'Confirm New Password',
      key: 'confirmNewPassword',
      visionIcon: (
        <VisibilityToggleBtn isVisible={isConfirmPasswordVisible} onClick={toggleConfirmPasswordVisibility} />
      ),
    },
  ];
};

export { ResetPasswordInputs };
export default ForgotPasswordInputs;
