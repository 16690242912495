import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTabOne, useTabTwo } from '../../redux/actions';

function OptionsDropdown({ getDropdownItemClicked }) {
  const dispatch = useDispatch();

  const handleTabClick = (isClicked) => {
    getDropdownItemClicked(isClicked);
  };

  return (
    <aside className="z-[9999] absolute right-2 top-14 flex flex-col rounded font-nunitoSemiBold text-sm">
      <div
        onClick={() => dispatch(useTabOne())}
        className="rounded-t bg-white text-black hover:cursor-pointer hover:bg-[#686B6E]/50 hover:text-white flex items-center justify-startborder-b border-[#686B6E]"
      >
        <figure onClick={() => handleTabClick(true)} className="py-3 px-8">
          Save video
        </figure>
      </div>
      <div
        onClick={() => dispatch(useTabTwo())}
        className="rounded-b bg-white text-black hover:cursor-pointer hover:bg-[#686B6E]/50 hover:text-white flex items-center justify-start"
      >
        <figure onClick={() => handleTabClick(true)} className="py-3 px-8">
          Transfer video
        </figure>
      </div>
    </aside>
  );
}

OptionsDropdown.propTypes = {
  getTabStep: PropTypes.func,
  getDropdownItemClicked: PropTypes.func,
};

export default OptionsDropdown;
