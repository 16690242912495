import { navHeaderData } from "../../data/navbar-data";

function NavbarHeader() {
  return (
    <header className="font-nunitoMedium md:font-nunitoSemiBold bg-gray-700 text-white py-4 px-5 md:px-8">
      <main className="md:w-1/2 flex gap-3 md:gap-4 text-[11px] md:text-sm whitespace-nowrap">
        {navHeaderData.map((headerData) => (
          <div key={headerData.id}>{headerData.headerText}</div>
        ))}
      </main>
    </header>
  );
}

export default NavbarHeader;
