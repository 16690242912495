import { createSlice } from '@reduxjs/toolkit';

/**
 *
 * initialState of activeTab is 1
 * create a slice that nests 2 reducers, the first to change the state of activeTab to 1
 * ::the second to change the state of activeTab to 2
 * each reducer has its own action that can be dispatched & expects a payload
 *
 */

const initialState = {
  activeTab: 1,
};

const exportVideoSlice = createSlice({
  name: 'videoExportOrShare',
  initialState,
  reducers: {
    useTabOne: (state) => {
      state.activeTab = 1;
    },
    useTabTwo: (state) => {
      state.activeTab = 2;
    },
  },
});

export const { useTabOne, useTabTwo } = exportVideoSlice.actions;
export default exportVideoSlice.reducer;
