import AllDesignsRootLayout from '../components/dashboard-components/AllDesignsRootLayout';

function AllDesignsUploadsPage() {
  return (
    <AllDesignsRootLayout>
      <div className="ml-[22%] px-8 py-12 font-nunitoSemiBold text-white">AllDesignsUploadsPage</div>
    </AllDesignsRootLayout>
  );
}

export default AllDesignsUploadsPage;
