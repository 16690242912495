import { Link } from 'react-router-dom';
import { blogsData } from '../../data/blogs-page-data';
import BonusNotification from '../pricing-components/BonusNotification';

function BlogsTopSection() {
  return (
    <main className="bg-generalBlack pt-4 pb-12">
      <section className="w-[80%] mx-auto flex flex-wrap gap-12 md:gap-8 justify-center">
        {blogsData.map((blog) => (
          <Link
            to={`/blogs/${blog.blogId}`}
            key={blog.blogId}
            className={`${blog.postBg} w-full ${
              blog.isFeatured ? 'md:w-[calc(49.99%-2rem)]' : 'md:w-[calc(33.3%-2rem)]'
            } h-[300px] relative bg-no-repeat bg-blendClr bg-blend-multiply bg-cover rounded-xl flex items-end`}
          >
            <aside className="flex flex-col gap-1 md:gap-2 px-4 md:px-6 py-4 md:py-6">
              <div className="font-nunitoExtraBold text-lg text-white text-left">
                <h4>{blog.title}</h4>
              </div>

              <div className='flex items-center justify-between text-white font-nunitoRegular'>
                <div className='flex items-center justify-between gap-3'>
                  <figure>
                    <img src={blog.authorImg} alt={`${blog.authorName}'s Image`} />
                  </figure>

                  <figure className='flex flex-col'>
                    <span className='font-nunitoExtraBold text-sm'>{blog.authorName}</span>
                    <span className='text-xs opacity-80'>{blog.authorProfession}</span>
                  </figure>
                </div>

                <div>
                  <p className='opacity-70 text-xs'>{blog.postDate}</p>
                </div>
              </div>
            </aside>
            {blog.isFeatured && <BonusNotification isFreeText={blog.featuredText} isBlogsPage />}
          </Link>
        ))}
      </section>
    </main>
  );
}

export default BlogsTopSection;
