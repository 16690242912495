import VideoWrapper from './VideoWrapper';
import dummyVideo from '../../assets/videos/placeholder-video.mp4'

function ExportVideoMainArea() {
  return (
      <section className="flex-1">
        <aside className="m-2 flex">
          <VideoWrapper videoSrc={dummyVideo} />
        </aside>
      </section>
  );
}

export default ExportVideoMainArea;
