import { createElement } from "react";
import { FaFacebook } from "react-icons/fa";
import { AiFillGoogleCircle } from "react-icons/ai";
import { IoEyeSharp, IoEyeOffSharp } from "react-icons/io5";
import useVisibility from "../custom-hooks/useVisibility";
import PropTypes from "prop-types";

const VisibilityToggleBtn = ({ isVisible, onClick }) => {
  return (
    <button
      type="button"
      className="absolute right-4 bottom-[10px] text-generalBlack text-2xl"
      onClick={onClick}
    >
      {isVisible ? <IoEyeSharp /> : <IoEyeOffSharp />}
    </button>
  );
};

const RegisterFormInputs = () => {
  const {
    isVisible: isPasswordVisible,
    toggleVisibility: togglePasswordVisibility,
  } = useVisibility();
  const {
    isVisible: isConfirmPasswordVisible,
    toggleVisibility: toggleConfirmPasswordVisibility,
  } = useVisibility();

  return [
    {
      name: "Name",
      type: "text",
      placeholder: "Name",
      key: "name",
    },
    {
      name: "Email address",
      type: "email",
      placeholder: "Email address",
      key: "email",
    },
    {
      name: "Password",
      type: isPasswordVisible ? "text" : "password",
      placeholder: "Password",
      key: "password",
      visionIcon: (
        <VisibilityToggleBtn
          isVisible={isPasswordVisible}
          onClick={togglePasswordVisibility}
        />
      ),
    },
    {
      name: "Confirm password",
      type: isConfirmPasswordVisible ? "text" : "password",
      placeholder: "Confirm password",
      key: "confirmPassword",
      visionIcon: (
        <VisibilityToggleBtn
          isVisible={isConfirmPasswordVisible}
          onClick={toggleConfirmPasswordVisibility}
        />
      ),
    },
  ];
};

const SocialData = () => [
  {
    icon: createElement(AiFillGoogleCircle, { className: "text-2xl" }),
    text: "Continue with Google",
    type: "google",
    checkoutText: "Pay with Google",
    bgColor: "bg-stemGreen text-white",
    checkoutBgColor: "bg-white text-stemGreen",
  },
  // {
  //   icon: createElement(FaFacebook, { className: 'text-2xl' }),
  //   text: 'Continue with Facebook',
  //   checkoutText: 'Pay with Facebook',
  //   type: 'facebook',
  //   bgColor: 'bg-black text-white',
  //   checkoutBgColor: 'bg-black text-stemGreen',
  // },
];

VisibilityToggleBtn.propTypes = {
  isVisible: PropTypes.bool,
  onClick: PropTypes.func,
};

export { VisibilityToggleBtn, RegisterFormInputs, SocialData };
