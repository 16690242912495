import googleDriveImg from '../assets/images/google-drive.png';
import oneDriveImg from '../assets/images/onedrive.png';
import dropboxImg from '../assets/images/dropbox.png';

const transferOrSaveOptions = [
  {
    id: 1,
    text: ['Mp4', 'Google Drive'],
    path: '/',
    imgSrc: googleDriveImg,
    altText: 'Google Drive Icon',
  },
  {
    id: 2,
    text: ['Gif', 'OneDrive'],
    path: '/',
    imgSrc: oneDriveImg,
    altText: 'Onedrive Icon',
  },
  {
    id: 3,
    text: ['WebM', 'Dropbox'],
    path: '/',
    imgSrc: dropboxImg,
    altText: 'Dropbox Icon',
  },
];

export { transferOrSaveOptions };
