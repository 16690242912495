import { useEffect, useState } from "react";
import { FaEdit, FaSignOutAlt } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { openSidebar } from "../../redux/actions";
import {
  cardItems,
  profileCardPathnameArray,
} from "../../data/allDesigns-page-data";

function UserProfile() {
  const [user, setUser] = useState({ username: "", email: "" });
  const [showCard, setShowCard] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { pathname } = window.location;

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userswiftsketch")));

    if (profileCardPathnameArray.includes(pathname)) {
      dispatch(openSidebar());
    }
  }, []);

  const handleToggleCard = () => {
    setShowCard(!showCard);
  };

  const handleItemClick = (route) => {
    handleToggleCard();
    navigate(route);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userswiftsketch");
    navigate("/login");
  };

  return (
    <main className="font-nunitoMedium flex items-center relative whitespace-nowrap text-[13px]">
      <section
        className="border border-semiDark rounded-[20px] p-2 flex gap-2 cursor-pointer"
        onClick={handleToggleCard}
      >
        <img
          src={user.image}
          alt="User"
          className="rounded-full h-10 w-10 object-cover"
        />
        <div className="flex items-center justify-center">
          <IoIosArrowDown size={20} className="text-gray-500" />
        </div>
      </section>

      {showCard && (
        <section className="flex flex-col gap-4 absolute z-[999] top-16 right-0 bg-gray-800 border border-gray-700 shadow-md p-4 rounded-md">
          <aside className="flex flex-col items-center border-b border-semiDark pb-4">
            <label
              htmlFor="image-upload"
              className="text-xs cursor-pointer text-white text-opacity-60 pt-2 flex flex-col gap-2"
            >
              <figure className="w-fit mx-auto relative">
                <img
                  src={user.image}
                  alt="User"
                  className="rounded-full h-10 w-10 object-cover"
                />
                <div className="border-t border-b-2 border-gray-800 bg-gray-800 absolute bottom-2 -right-3">
                  <FaEdit size={20} className="text-semiDark" />
                </div>
              </figure>

              <span>Change Profile Image</span>
            </label>
            <input type="file" id="image-upload" className="hidden" />
          </aside>

          <aside className="flex flex-col gap-2 text-xs text-white text-opacity-60 border-b border-semiDark pb-4">
            <p>Email: {user.email}</p>
            <p>Name: {user.username}</p>
          </aside>

          <aside className="flex flex-col gap-1 text-white border-b border-semiDark pb-4">
            {cardItems.map((item, index) => (
              <article
                key={index}
                className="flex items-center mb-2"
                onClick={() => handleItemClick(item.path)}
              >
                {item.icon}
                <span className="text-xs text-white text-opacity-60">
                  {item.label}
                </span>
              </article>
            ))}
          </aside>

          <aside className="pb-4">
            <article className="flex items-center" onClick={handleLogout}>
              <FaSignOutAlt className="text-stemGreen mr-2" />
              <span className="text-xs text-white text-opacity-60">Logout</span>
            </article>
          </aside>
        </section>
      )}
    </main>
  );
}

export default UserProfile;
