import { useState } from "react";
import priceSectionData from "../../data/price-section-data";
import Button from "../globals/Button";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

function PricingSection() {
  const [counterOn, setCounterOn] = useState(false);

  return (
    <section className="bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl pt-20 pb-24">
      <ScrollTrigger
        onEnter={() => setCounterOn(!counterOn)}
        onExit={() => setCounterOn(counterOn)}
      >
        <div className="container mx-auto flex flex-col items-center">
          {priceSectionData.map((priceData) => (
            <div
              key={priceData.id}
              className={`${priceData.parentStyle} flex mb-8`}
            >
              <h4 className={`${priceData.firstH4Style}`}>
                {isNaN(priceData.firstH4Text) ? (
                  priceData.firstH4Text
                ) : counterOn ? (
                  <CountUp
                    start={0}
                    end={priceData.firstH4Text}
                    duration={5}
                    delay={0}
                  />
                ) : (
                  <span className="w-full">{priceData.firstH4Text}</span>
                )}
              </h4>
              <h4 className={`${priceData.secondH4Style}`}>
                {isNaN(priceData.secondH4Text) ? (
                  priceData.secondH4Text
                ) : counterOn ? (
                  <CountUp
                    start={0}
                    end={priceData.secondH4Text}
                    duration={5}
                    delay={0}
                  />
                ) : (
                  <span className="w-full">{priceData.secondH4Text}</span>
                )}
              </h4>
            </div>
          ))}
          {/* <div className="flex space-x-4 pt-4"> */}
          <div className="flex justify-center">
            {/* <Button path="/pricing" btnText="Discover More" isBig isLight /> */}
            <Button path="/register" btnText="Register" isBig />
          </div>
        </div>
      </ScrollTrigger>
    </section>
  );
}

export default PricingSection;
