import Button from "./Button";
import getStartedButtonData from "../../data/get-started-data";

function GetStarted() {
  return (
    <section className="py-16 bg-generalBlack">
      <div className="px-4 md:px-12 container mx-auto flex flex-col md:flex-row items-center justify-between">
        <div className="md:w-1/2 mb-8 md:mb-0 flex flex-col gap-4">
          <h3 className="text-3xl md:text-4xl font-nunitoExtraBold mb-4 text-center md:text-left text-white">
            Get Started with SwiftSketch Today
          </h3>
          {/* <div className="flex justify-center md:justify-start">
            <Button path="/pricing" btnText="Continue" isLight />
          </div> */}
        </div>

        <div className="md:w-1/2 flex items-start justify-center">
          <div className="flex space-x-4">
            {getStartedButtonData.map((buttonData) => (
              <button
                key={buttonData.id}
                className={`flex gap-2 items-center py-3 rounded px-4 md:px-8 ${buttonData.BtnExtaStyles}`}
              >
                {buttonData.BtnFirstChild}
                <div>
                  <h4 className="text-left text-sm font-nunitoBold whitespace-nowrap">
                    {buttonData.Btnh4Text}
                  </h4>
                  <h5 className="text-left text-xs font-nunitoRegular whitespace-nowrap">
                    {buttonData.Btnh5Text}
                  </h5>
                </div>
              </button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default GetStarted;
