import Slider from 'react-slick';
import { topTeamsData } from '../../data/about-page-data';

function OurClients() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed: 1,
    cssEase: 'linear',
    pauseOnHover: false,
  };

  return (
    <section className="our-clients pb-6 md:py-6 h-[6rem] bg-generalBlack min-w-full md:h-[8rem] md:pt-4 md:pb-8 md:px-12 lg:px-0">
      <main className="w-full md:w-[80%] h-full container mx-auto">
        <Slider {...settings}>
          {topTeamsData.map((team) => (
            <div
              key={team.id}
              className={`h-[4rem] bg-contain bg-no-repeat bg-center border-x-8 md:border-x-[30px] border-generalBlack ${team.bgImg}`}
            ></div>
          ))}
        </Slider>
      </main>
    </section>
  );
}

export default OurClients;
