import { createSlice } from '@reduxjs/toolkit';
import { recentActivitiesData } from '../../data/recents-page-data';

const initialState = {
  recentActivities: recentActivitiesData,
};

const recentActivitiesSlice = createSlice({
  name: 'recentActivities',
  initialState,
  reducers: {
    addActivity(state, action) {
      state.recentActivities.push(action.payload);
    },
    clearActivities(state) {
      state.recentActivities = [];
    },
  },
});

export const { addActivity, clearActivities } = recentActivitiesSlice.actions;
export default recentActivitiesSlice.reducer;
