import appDescriptionImg from "../../assets/images/description-image.png";
import getStartedButtonData from "../../data/get-started-data";

function AppDescription() {
  return (
    <section className="pt-20 pb-5 md:pb-16 bg-generalBlack">
      <div className="container mx-auto">
        <div className="w-5/6 mx-auto">
          <main className="flex flex-col md:flex-row mb-12 gap-20">
            <div className="flex-1 text-white">
              <div>
                <h3 className="text-center md:text-left text-2xl whitespace-nowrap font-nunitoExtraBold mb-4 text-orange">
                  Elevate Your Visuals
                </h3>
                <h2 className="text-3xl md:text-4xl text-center md:text-left font-nunitoExtraBold leading-10 mb-4">
                  The Ultimate web-based editing experience
                </h2>
                <p className="font-nunitoMedium md:font-nunitoRegular text-sm 2xl:text-base text-left md:text-justify leading-[1.6rem] pl-1 md:pl-0">
                  With SwiftSketch, you&apos;ll embark on a journey that
                  transcends traditional editing. Our intuitive interface and
                  advanced tools provide you with the freedom to unleash your
                  creativity and transform your ideas into stunning works of
                  art. Whether you&apos;re a professional designer, a social
                  media enthusiast, or someone exploring their artistic side,
                  SwiftSketch is here to make your vision come to life.
                </p>
              </div>
              <div className="mt-8 flex space-x-6">
                {getStartedButtonData.map((buttonData) => (
                  <button
                    key={buttonData.id}
                    className={`flex gap-2 items-center py-3 rounded px-4 md:px-8 ${buttonData.BtnExtaStyles}`}
                  >
                    {buttonData.BtnFirstChild}
                    <div>
                      <h4 className="text-left text-sm font-nunitoBold whitespace-nowrap">
                        {buttonData.Btnh4Text}
                      </h4>
                      <h5 className="text-left text-xs font-nunitoRegular whitespace-nowrap">
                        {buttonData.Btnh5Text}
                      </h5>
                    </div>
                  </button>
                ))}
              </div>
            </div>

            <main className="flex-1 items-start">
              <figure className="w-full h-full overflow-hidden">
                <img
                  src={appDescriptionImg}
                  alt="App Screenshot"
                  className="rounded-md w-full h-full md:h-[410px]"
                />
              </figure>
            </main>
          </main>
        </div>
      </div>
    </section>
  );
}

export default AppDescription;
