import { createElement } from 'react';
import { NextArrow, PrevArrow } from '../components/globals/SliderArrows';

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: true,
  pauseOnHover: false,
  nextArrow: createElement(PrevArrow),
  prevArrow: createElement(NextArrow),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1000,
        cssEase: 'linear',
        speed: 500,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        initialSlide: 2,
        speed: 500,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        initialSlide: 2,
        speed: 500,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        prevArrow: null,
        nextArrow: null
      },
    },
  ],
};

export { settings };
