import { Link, useLocation } from 'react-router-dom';
import { LuMenu } from 'react-icons/lu';
import { IoIosClose } from 'react-icons/io';
import { SearchIcon } from '../../assets/svgs/svgs.jsx';
import { dropdownListData, navBarMainData } from '../../data/navbar-data.js';
import brandlogo from '../../assets/images/swiftsketch-desktop-tinted.png';
import mobileLogo from '../../assets/images/swiftsketch-mobile-colored.png';
import Dropdown from './Dropdown.jsx';
import useResize from '../../custom-hooks/useResize.js';
import Button from '../globals/Button.jsx';

const NavigationBarMain = () => {
  const { width, breakpoint, isOpen, setIsOpen, handleMenubarToggle } = useResize();

  const location = useLocation();
  const { pathname } = location;

  const styledList = {
    styles: 'hover:bg-white hover:rounded-md hover:text-stemGreen',
  };

  const getDropdownClickedState = (isClicked) => {
    isClicked && setIsOpen(!isOpen);
  };

  return (
    <nav
      className={`${
        isOpen ? 'pt-4 py-8' : 'py-4'
      } relative flex flex-auto gap-0 md:gap-8 bg-generalBlack border border-gray-800 text-white pl-4 md:py-6 md:px-6`}
    >
      <Link to="/" className="flex-none">
        <img src={width < breakpoint ? mobileLogo : brandlogo} alt="Logo" className="h-10" />
      </Link>

      <div className="flex-grow">
        <div
          onClick={() => handleMenubarToggle()}
          className={`${
            width < breakpoint ? 'flex' : 'hidden'
          } relative left-16 top-1 items-center justify-center w-10 h-10 bg-stemGreen text-black rounded-md`}
        >
          {width < breakpoint && isOpen ? <IoIosClose size={40} /> : <LuMenu size={24} />}
        </div>
        <ul
          className={`${
            width < breakpoint && !isOpen ? 'hidden' : 'flex'
          } mx-auto font-nunitoSemiBold flex-col md:flex-row gap-4 w-3/4 h-full items-left md:items-center justify-center`}
        >
          {navBarMainData.map((navData) => (
            <li
              key={navData.id}
              className={`${navData.flexOrder} ${navData.id === 3 ? 'mb-0' : 'mb-2'} md:mb-0`}
              onClick={handleMenubarToggle}
            >
              <Link
                className={`${pathname === navData.linkPath ? `text-stemGreen` : 'text-white'} ${
                  styledList.styles
                } px-4 py-2`}
                to={navData.linkPath}
              >
                {navData.linkText}
              </Link>
            </li>
          ))}
          <Dropdown
            text="Products"
            dropdownListData={dropdownListData[0]}
            getDropdownClickedState={getDropdownClickedState}
          />
          <Dropdown
            text="Discover"
            dropdownListData={dropdownListData[1]}
            getDropdownClickedState={getDropdownClickedState}
          />
        </ul>
      </div>

      <div className="w-fit flex space-x-2 absolute right-2 md:relative md:top-0 md:left-0">
        <input
          type="text"
          placeholder="Find Template.."
          className="bg-gray-700 w-[120px] md:w-full py-3 md:flex font-nunitoLight text-white px-2 md:py-1 rounded-md outline-none border-none"
        />
        <div className="bg-gray-700 text-white px-3 py-1 rounded-md flex justify-center items-center cursor-pointer">
          <SearchIcon />
        </div>
      </div>

      <div
        className={`${
          width < breakpoint && !isOpen ? 'hidden' : 'flex'
        } flex-1 md:flex-none flex gap-3 md:gap-2 flex-col md:flex-row absolute right-2 top-20 md:relative md:top-0 md:left-0`}
      >
        <Button path="/login" btnText="Login" isLight />
        <Button path="/register" btnText="Sign up Free" />
      </div>
    </nav>
  );
};

export default NavigationBarMain;
