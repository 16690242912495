import TabsWrapper from './TabsWrapper';

function TemplatesSearch() {
  return (
    <section className="font-nunitoRegular pt-3 pb-4 bg-generalBlack">
      <TabsWrapper />
    </section>
  );
}

export default TemplatesSearch;
