const accordionData = [
  {
    id: 1,
    question: 'Can I use SwiftSketch for free?',
    answer:
      'Yes, SwiftSketch offers a 7 day trial plan with limited features. However, for access to the full range of advanced tools and premium assets, we offer subscription-based plans at affordable prices.',
  },
  {
    id: 2,
    question: 'Can I import my own images and videos into SwiftSketch?',
    answer:
      "Yes, you can! SwiftSketch allows you to import and use your own images and videos to personalize your projects. Whether it's a memorable photo or a unique video clip, you can seamlessly integrate your media for a truly customized editing experience.",
  },
  {
    id: 3,
    question: 'Can I collaborate with others on projects in SwiftSketch?',
    answer:
      "Absolutely! SwiftSketch supports collaboration features, enabling you to work seamlessly with others on your projects. Invite team members or friends to collaborate in real-time, making it easy to create stunning videos together. Enhance teamwork and creativity with SwiftSketch's collaborative tools.",
  },
  {
    id: 4,
    question: 'Does SwiftSketch have a mobile app?',
    answer:
      "Yes, it does! Enjoy the flexibility of creating and editing on the go with SwiftSketch's mobile app. Available for both iOS and Android devices, our mobile app lets you carry your projects in your pocket. Stay productive and creative anytime, anywhere with the convenience of SwiftSketch on your mobile device.",
  },
];

export default accordionData;
