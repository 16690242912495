import { DividerLine } from '../../pages/RegisterPage';

function ContactUsLeft() {
  return (
    <section className="flex-1 lg:pt-2">
      <aside className="flex flex-col gap-4 px-5 lg:px-0">
        <div className="flex flex-row gap-2">
          <div className="w-5 flex items-center">
            <DividerLine isBold />
          </div>
          <h5 className="font-nunitoBold text-xl text-stemGreen">Contact Us</h5>
        </div>
        <h2 className="font-nunitoExtraBold text-white text-4xl leading-snug">Fill the form to submit your feedback</h2>
        <p className="font-nunitoRegular text-white text-sm leading-loose">
          Have a question, suggestion, or just want to say hello? We&apos;d love to hear from you! Feel free to get in
          touch using the contact details below or by filling out the contact form. Our dedicated team is here to assist
          you.
        </p>
      </aside>
    </section>
  );
}

export default ContactUsLeft;
