import { Fragment } from 'react';
import BottomSection from '../components/feature-components/BottomSection';
import TopSection from '../components/globals/TopSection';

function FeaturesPage() {
  return (
    <Fragment>
      <TopSection heroText="Powerful features for Designer" hasBtnContainer isFeaturesPage />
      <BottomSection />
    </Fragment>
  );
}

export default FeaturesPage;
