import AllDesignsSettingsRootLayout from '../components/dashboard-components/AllDesignsSettingsRootLayout';

function AllDesignsSettingsPage() {
  return (
    <AllDesignsSettingsRootLayout>
      <div className="ml-[22%] font-nunitoRegular text-white capitalize p-4">Settings</div>
    </AllDesignsSettingsRootLayout>
  );
}

export default AllDesignsSettingsPage;
