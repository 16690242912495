const pricingOptions = [
  { id: 1, type: "Yearly", price: "$20", bestValue: "BEST VALUE | SAVE $20" },
  { id: 2, type: "Monthly", price: "$5", bestValue: "BEST VALUE" },
];

const trialData = [
  { id: 1, text: "Free 7 days trial without watermark" },
  { id: 2, text: "Email reminder before trial ends" },
];

const optionsData = [
  { label: "Nigeria", value: "NG" },
  { label: "United States", value: "US" },
  { label: "Côte d'Ivoire", value: "CI" },
  { label: "Egypt", value: "EG" },
  { label: "Ghana", value: "GH" },
  { label: "Kenya", value: "KE" },
  { label: "Rwanda", value: "RW" },
  { label: "South Africa", value: "ZA" },
  { label: "Democratic Republic of the Congo", value: "CD" },
];

const inputFields = [
  { type: "text", placeholder: "Enter Full name", tag: "name" },
  { type: "email", placeholder: "Enter Email Address", tag: "email" },
  { type: "password", placeholder: "Enter Password", tag: "password" },
  {
    type: "password",
    placeholder: "Confirm Password",
    tag: "confirmPassword",
  },
  { type: "text", amount: true },
];
const checkOutFields = [
  { type: "text", placeholder: "Enter Full name", tag: "name" },
  { type: "email", placeholder: "Enter Email Address", tag: "email" },

  { type: "text", amount: true },
];

export { pricingOptions, checkOutFields, trialData, optionsData, inputFields };
