import AllDesignsRootLayout from '../components/dashboard-components/AllDesignsRootLayout';
import ProjectsComponent from '../components/dashboard-components/ProjectsComponent';

function AllDesignsProjectsPage() {
  return (
    <AllDesignsRootLayout>
      <ProjectsComponent />
    </AllDesignsRootLayout>
  );
}

export default AllDesignsProjectsPage;
