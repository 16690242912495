import thumbnailImg from "../assets/images/template-image-3.png";

/**
 * Completion time should be inputed by the user
 */

const projectsData = [
  {
    projectId: "SSPro1",
    title: "Project A",
    creationDate: "2023-01-15",
    lastUpdated: "2023-03-05",
    completionStatus: "Completed",
    completionTime: "2 months",
    images: ["image1.jpg", "image2.jpg"],
    videos: ["video1.mp4", "video2.mp4"],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro2",
    title: "Project B",
    creationDate: "2022-09-10",
    lastUpdated: "2022-11-25",
    completionStatus: "In Progress",
    completionTime: "3 months (estimated)",
    images: ["image3.jpg", "image4.jpg"],
    videos: ["video3.mp4"],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro3",
    title: "Project C",
    creationDate: "2023-04-02",
    lastUpdated: "2023-06-20",
    completionStatus: "Not Started",
    completionTime: "4 months (estimated)",
    images: ["image5.jpg"],
    videos: [],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro4",
    title: "Project D",
    creationDate: "2022-12-18",
    lastUpdated: "2023-02-10",
    completionStatus: "Completed",
    completionTime: "2.5 months",
    images: ["image6.jpg", "image7.jpg"],
    videos: ["video4.mp4"],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro5",
    title: "Project E",
    creationDate: "2022-11-01",
    lastUpdated: "2023-01-20",
    completionStatus: "In Progress",
    completionTime: "5 months (estimated)",
    images: ["image8.jpg"],
    videos: ["video5.mp4"],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro6",
    title: "Project F",
    creationDate: "2023-02-15",
    lastUpdated: "2023-04-30",
    completionStatus: "Completed",
    completionTime: "3 months",
    images: ["image9.jpg"],
    videos: [],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro7",
    title: "Project G",
    creationDate: "2023-03-10",
    lastUpdated: "2023-05-25",
    completionStatus: "In Progress",
    completionTime: "4 months (estimated)",
    images: ["image10.jpg", "image11.jpg"],
    videos: ["video6.mp4"],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro8",
    title: "Project H",
    creationDate: "2022-08-22",
    lastUpdated: "2022-11-10",
    completionStatus: "Not Started",
    completionTime: "6 months (estimated)",
    images: ["image12.jpg", "image13.jpg"],
    videos: [],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro9",
    title: "Project I",
    creationDate: "2023-05-05",
    lastUpdated: "2023-08-01",
    completionStatus: "Completed",
    completionTime: "3.5 months",
    images: ["image14.jpg"],
    videos: ["video7.mp4"],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro10",
    title: "Project J",
    creationDate: "2022-10-12",
    lastUpdated: "2023-01-05",
    completionStatus: "In Progress",
    completionTime: "7 months (estimated)",
    images: ["image15.jpg", "image16.jpg"],
    videos: ["video8.mp4"],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro11",
    title: "Project K",
    creationDate: "2023-06-18",
    lastUpdated: "2023-09-10",
    completionStatus: "Not Started",
    completionTime: "8 months (estimated)",
    images: ["image17.jpg"],
    videos: ["video9.mp4"],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro12",
    title: "Project L",
    creationDate: "2022-07-25",
    lastUpdated: "2022-10-15",
    completionStatus: "Completed",
    completionTime: "2 months",
    images: ["image18.jpg", "image19.jpg"],
    videos: ["video10.mp4"],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro13",
    title: "Project M",
    creationDate: "2023-08-01",
    lastUpdated: "2023-10-25",
    completionStatus: "In Progress",
    completionTime: "6 months (estimated)",
    images: ["image20.jpg"],
    videos: ["video11.mp4"],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro14",
    title: "Project N",
    creationDate: "2023-09-15",
    lastUpdated: "2023-12-05",
    completionStatus: "Not Started",
    completionTime: "9 months (estimated)",
    images: ["image21.jpg", "image22.jpg"],
    videos: [],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro15",
    title: "Project O",
    creationDate: "2022-05-10",
    lastUpdated: "2022-08-01",
    completionStatus: "Completed",
    completionTime: "1.5 months",
    images: ["image23.jpg"],
    videos: ["video12.mp4"],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro16",
    title: "Project P",
    creationDate: "2023-10-20",
    lastUpdated: "2024-01-15",
    completionStatus: "In Progress",
    completionTime: "5 months (estimated)",
    images: ["image24.jpg", "image25.jpg"],
    videos: ["video13.mp4"],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro17",
    title: "Project Q",
    creationDate: "2022-04-15",
    lastUpdated: "2022-07-10",
    completionStatus: "Not Started",
    completionTime: "10 months (estimated)",
    images: ["image26.jpg"],
    videos: ["video14.mp4"],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro18",
    title: "Project R",
    creationDate: "2023-11-05",
    lastUpdated: "2024-02-01",
    completionStatus: "Completed",
    completionTime: "4 months",
    images: ["image27.jpg"],
    videos: ["video15.mp4"],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro19",
    title: "Project S",
    creationDate: "2022-03-20",
    lastUpdated: "2022-06-15",
    completionStatus: "In Progress",
    completionTime: "2 months (estimated)",
    images: ["image28.jpg", "image29.jpg"],
    videos: ["video16.mp4"],
    thumbnailUrl: thumbnailImg,
  },
  {
    projectId: "SSPro20",
    title: "Project T",
    creationDate: "2023-12-10",
    lastUpdated: "2024-03-01",
    completionStatus: "Not Started",
    completionTime: "11 months (estimated)",
    images: ["image30.jpg"],
    videos: [],
    thumbnailUrl: thumbnailImg,
  },
];

const searchOptions = [
  {
    id: 1,
    value: "all",
    optionText: "All",
  },
  {
    id: 2,
    value: "image",
    optionText: "Images",
  },
  {
    id: 3,
    value: "video",
    optionText: "Videos",
  },
  // {
  //   id: 3,
  //   value: 'completionStatus',
  //   optionText: 'Completion Status',
  // },
];

export { projectsData, searchOptions };
