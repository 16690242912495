import testimonialImgOne from '../assets/images/testimonial-img-2.png';
import testimonialImgTwo from '../assets/images/testimonial-img-3.jpg';
import testimonialImgThree from '../assets/images/mr-emmanuel-jaja.jpg';

const testimonialsData = [
  {
    id: 1,
    image: testimonialImgOne,
    quote:
      "The platform's intuitive interface and powerful features have revolutionized the way I design and develop visually stunning videos.",
    author: 'Veda Kingston',
    position: 'Lead Frontend Developer',
    company: 'Prixure Technologies',
  },
  {
    id: 2,
    image: testimonialImgTwo,
    quote:
      "I run a small business, and video marketing is essential for our growth. Your site has been an incredible ally in our marketing efforts. The personalized templates and easy to use interface have saved us the time and resources while producing professional looking videos",
    author: 'Benhanan Solomon',
    position: 'Market strategist',
    company: 'Anuna consult',
  },
  {
    id: 3,
    image: testimonialImgThree,
    quote:
      "The platform's intuitive interface and powerful features have revolutionized the way I design and develop visually stunning videos. As a student i was on the hunt for a video editing platform that could match creativity and ambition. Your platform is packed with a plethora of features and effects that rival professional editing site.",
    author: 'Emmanuel Jaja',
    position: 'UI/UX designer',
    company: 'Bleem Technologies',
  },
  // {
  //   id: 4,
  //   image: testimonialImgOne,
  //   quote:
  //     "EasyDesign has made Vlogging absolute joy for me! As someone who is always on the move, the editing platform has been a game-changer. I can edit my videos and pictures without compromising on quality. I can't thank you enough for making video editing accessible and fun.",
  //   author: 'Anani Brent',
  //   position: 'Lead Frontend Developer',
  //   company: 'Bruce technology',
  // },
  // {
  //   id: 5,
  //   image: testimonialImgOne,
  //   quote:
  //     "As a filmmaker, i demand precision and versatility in my video editing process which this platform has exceeded my expectations. The range of editing features allows me to fine-tune every aspects of films.",
  //   author: 'James Stewart',
  //   position: 'Filmmaker',
  //   company: 'Filmhub innovate',
  // },
];

export default testimonialsData;
