const sliderOptions = [
  {
    label: 'Font Size',
    state: 'fontSize',
    min: 10,
    max: 20,
  },
  {
    label: 'Image Quality',
    state: 'imageQuality',
    min: 0,
    max: 100,
  },
  {
    label: 'Brightness',
    state: 'brightness',
    min: 0,
    max: 100,
  },
  {
    label: 'Volume',
    state: 'volume',
    min: 0,
    max: 100,
  },
];

const dropdownOptions = [
  {
    label: 'Language',
    state: 'language',
    options: ['English', 'Spanish', 'French'],
  },
  {
    label: 'Theme Color',
    state: 'themeColor',
    options: ['Light', 'Dark', 'Custom'],
  },
  {
    label: 'Video Resolution',
    state: 'videoResolution',
    options: ['1080p', '720p', '480p'],
  },
  {
    label: 'Time Zone',
    state: 'timeZone',
    options: ['GMT', 'EST', 'PST'],
  },
  {
    label: 'Notification Sound',
    state: 'notificationSound',
    options: ['Default', 'Chime', 'Bell'],
  },
  {
    label: 'Measurement Units',
    state: 'measurementUnits',
    options: ['Metric', 'Imperial'],
  },
];

const toggleOptions = [
  {
    label: 'Dark Mode',
    state: 'darkMode',
  },
  {
    label: 'Auto-Save',
    state: 'autoSave',
  },
  {
    label: 'Email Notifications',
    state: 'emailNotifications',
  },
  {
    label: 'Offline Mode',
    state: 'offlineMode',
  },
  {
    label: 'Two-Factor Authentication',
    state: 'twoFactorAuth',
  },
];

export { sliderOptions, dropdownOptions, toggleOptions };
