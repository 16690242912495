const cancelData = [
  {
    text: 'Log you out on all devices',
    icon: 'AiOutlineCloseCircle',
  },
  {
    text: 'Delete all of your account information',
    icon: 'AiOutlineCloseCircle',
  },
  {
    text: 'Delete all of your payment/transaction information',
    icon: 'AiOutlineCloseCircle',
  },
  {
    text: 'Delete all your uploads and downloads history',
    icon: 'AiOutlineCloseCircle',
  },
];

export { cancelData };
