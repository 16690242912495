import { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { contactUsInputs } from "../../data/contact-us-data";
import { Link } from "react-router-dom";
import { FullLoader } from "../globals/Loader";

function ContactUsRight({ baseurl }) {
  const [termsError, setTermsError] = useState("");
  const [formResponse, setFormResponse] = useState("");
  const [responseColor, setResponseColor] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    companyName: "",
    isAgreed: false,
    message: "",
  });
  function switchApiURL() {
    // if (import.meta.env.VITE_ENV === 'dev') return import.meta.env.VITE_API_URL_DEV;
    if (import.meta.env.VITE_ENV === "dev")
      return import.meta.env.VITE_API_URL_DEV;
    return import.meta.env.VITE_API_URL_PROD;
  }
  const baseUrl = switchApiURL();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
    setTermsError("");
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, isAgreed: e.target.checked });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ formData });

    if (formData.isAgreed !== true) {
      setTermsError("Cannot continue without agreeing to Terms & Conditions");
      return;
    }
    if (formData.email == "" || formData.message == "") {
      setTermsError("Please fill in all fields");
      return;
    }

    setIsLoading(true);

    axios
      .post(`${baseUrl}/auth/contact-us`, formData)
      .then((res) => {
        setIsLoading(false);
        setFormResponse(res.data.message);
        setResponseColor("text-stemGreen");
      })
      .catch((err) => {
        setIsLoading(false);

        setFormResponse(err.response.data.message);
        setResponseColor("text-orange");
      });
  };

  return (
    <section className="flex-1 bg-[#140B2A99] p-5 lg:px-8 lg:py-4 rounded sm">
      {isLoading && <FullLoader />}
      <form className="pt-4 flex flex-col gap-4" onSubmit={handleSubmit}>
        <aside className="flex flex-col md:flex-row md:flex-wrap gap-4">
          {contactUsInputs.map((input) => (
            <aside
              key={input.key}
              className="font-nunitoRegular mb-4 md:flex md:flex-grow md:flex-col md:space-x-4"
            >
              <div className="w-full flex flex-col">
                <label className="mb-2 text-white">{input.name}</label>
                <input
                  className="text-black rounded-sm px-3 py-3"
                  type={input.type}
                  placeholder={input.placeholder}
                  name={input.key}
                  value={formData[input.key]}
                  onChange={handleChange}
                />
              </div>
            </aside>
          ))}
        </aside>

        <aside className="flex flex-col gap-3">
          <label className="text-white">Additional feedback</label>
          <textarea
            name="message"
            id="messageBox"
            cols="30"
            placeholder="If you have any additional feedback, please type it in here..."
            className="flex-1 font-nunitoRegular rounded placeholder:font-nunitoRegular px-3 py-5 resize-none"
            rows="6"
            value={formData["message"]}
            onChange={handleChange}
          />
        </aside>

        <aside className="mb-4 md:flex flex-col space-y-8 md:space-y-16 pt-8">
          <div className="flex items-center space-x-2">
            <input
              className="h-4 w-4"
              type="checkbox"
              id="isAgreed"
              checked={formData.isAgreed}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor="isAgreed"
              className="pl-3 text-white font-nunitoRegular"
            >
              I have read and accepted the{" "}
              <Link
                className="inline-flex text-stemGreen"
                to="/terms-and-conditions"
              >
                Privacy Policy
              </Link>
            </label>
          </div>
          {formResponse ? (
            <p className={`${responseColor} text-base font-nunitoRegular`}>
              {formResponse}!
            </p>
          ) : (
            termsError && (
              <p className="text-red-500 text-base font-nunitoRegular">
                {termsError}
              </p>
            )
          )}
          <div>
            <button className="font-nunitoBold w-full bg-stemGreen text-white p-4 rounded-xl">
              Submit Feedback
            </button>
          </div>
        </aside>
      </form>
    </section>
  );
}

ContactUsRight.propTypes = {
  baseurl: PropTypes.string,
};

export default ContactUsRight;
