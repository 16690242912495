import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FaLongArrowAltLeft } from 'react-icons/fa';

function GoBack({ route }) {
  return (
    <Link to={route} className="text-stemGreen opacity-90 font-nunitoBold text-xs">
      <div className="flex flex-row gap-2">
        <aside className="flex items-center">
          <FaLongArrowAltLeft size={12} />
        </aside>
        <div>Go Back</div>
      </div>
    </Link>
  );
}

GoBack.propTypes = {
  route: PropTypes.string,
};

export default GoBack;
