import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { numbersArray, templateTabsData } from '../../data/template-page-data';
import { useNavigate } from 'react-router-dom';
import TemplateImageModal from '../modal-components/TemplateImageModal';
import { Loader } from '../globals/Loader';

function TemplateImages({ activeTab }) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const navigate = useNavigate();

  const openModal = (index) => {
    setSelectedTemplateIndex(index);

    setShowLoader(true);

    setTimeout(() => {
      setShowLoader(false);
      setModalOpen(true);
    }, 1200);
  };

  const closeModal = () => {
    setSelectedTemplateIndex(null);
    setModalOpen(false);
  };

  const continueToDashboard = () => {
    closeModal();
    navigate('/image-editor');
  };

  useEffect(() => {
    setShowLoader(true);

    setTimeout(() => {
      setShowLoader(false);
    }, 1200);
  }, []);

  const TemplateImagesWrapper = () => (
    <main className="bg-generalBlack pt-4 pb-4">
      <section className="mx-auto flex flex-wrap gap-8 justify-center">
        {templateTabsData[activeTab].images.map((template, index) => (
          <div key={template.id} className="relative w-full md:w-[calc(33.3%-2rem)] h-[260px] hover:cursor-pointer">
            <img src={template.url} alt={`Template Image ${template.id}`} className="h-4/5 w-full rounded-[1px]" />
            <button
              onClick={() => openModal(index)}
              className="bg-stemGreen text-[#f1f1f4] px-4 py-2 mt-3 transition-all rounded hover:bg-opacity-70"
            >
              Use template
            </button>

            {selectedTemplateIndex === index && isModalOpen && !showLoader && (
              <TemplateImageModal continueToDashboard={continueToDashboard} closeModal={closeModal} />
            )}

            {selectedTemplateIndex === index && showLoader && (
              <div className="absolute left-0 top-0 bg-gray-800 bg-opacity-50 w-full h-full flex items-center justify-center">
                <Loader loaderText="Retrieving Template..." />
              </div>
            )}
          </div>
        ))}
      </section>
    </main>
  );

  return (
    <section className="w-[92%] mt-6 container mx-auto text-white">
      {numbersArray.map((index) => (
        <aside key={index} className={`${activeTab === index ? 'flex' : 'hidden'}`}>
          <TemplateImagesWrapper />
        </aside>
      ))}
    </section>
  );
}

TemplateImages.propTypes = {
  activeTab: PropTypes.number,
};

export default TemplateImages;
