import { createElement } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { FaTimesCircle } from "react-icons/fa";
import PropTypes from "prop-types";
import BonusNotification from "./BonusNotification";
import { useSelector } from "react-redux";
import { Loader } from "../globals/Loader";

const Button = ({
  btnText,
  isLight,
  isToggled,
  type,
  duration,
  disabled,
  page,
}) => {
  const navigate = useNavigate();
  const handleRedirect = (type, duration) => {
    if (!page) {
      navigate(`/checkout/${type}/${duration}`);
    } else {
      navigate(
        `/dashboard/settings/subscriptions/checkout/${type}/${duration}`
      );
    }
  };
  return (
    <div className="flex">
      <button
        disabled={disabled}
        onClick={() => handleRedirect(type, duration)}
        className={`${
          isLight
            ? `${isToggled ? "bg-black text-white" : "bg-white text-black"}`
            : `${isToggled ? "bg-white text-black" : "bg-black text-white"}`
        } flex-1 text-center border border-white py-3 rounded-md font-nunitoSemiBold`}
      >
        {btnText}
      </button>
    </div>
  );
};
function PricingCard({ isToggled, loading, page, user }) {
  const { packages } = useSelector((state) => state.packages);

  return (
    <section className="bg-generalBlack">
      {loading && <Loader loaderText="Fetching Pricing Information" />}
      <main
        className={`${
          isToggled ? "bg-[#541b5c]" : "bg-[#140B2A99]"
        } w-[92%] md:w-[90%] mx-auto py-12 px-8 flex flex-col justify-center md:flex-row gap-16 md:gap-8 rounded-lg`}
      >
        {packages.map((priceData, index) => (
          <div
            key={priceData._id}
            className={`${
              priceData.isWhite
                ? `${isToggled ? "bg-black text-white" : "bg-white text-black"}`
                : `${
                    isToggled ? "bg-white text-black" : "bg-black text-white"
                  } `
            } relative flex-1 flex flex-col gap-4 rounded-2xl py-8 px-5`}
          >
            <div className="flex flex-col gap-1">
              <h5 className="text-lg font-nunitoExtraBold whitespace-nowrap">
                {priceData.h5Text}
              </h5>
              <p className="text-sm font-nunitoRegular">{priceData.pText}</p>
            </div>
            <div className="flex flex-row gap-1">
              <span className="text-4xl font-nunitoExtraBold">
                {isToggled ? priceData.h2Text[1] : priceData.h2Text[0]}
              </span>
              <span className="flex items-center">
                /{" "}
                {isToggled
                  ? priceData.packageType[1]
                  : priceData.packageType[0]}
              </span>
            </div>
            <Button
              btnText={
                page &&
                priceData.h5Text.toLowerCase() == user.plan.toLowerCase()
                  ? "Selected"
                  : page &&
                    priceData.h5Text.toLowerCase() != user.plan.toLowerCase()
                  ? "Upgrade"
                  : "Get Started Now"
              }
              type={priceData.h5Text.split(" ")[0]}
              duration={
                isToggled ? priceData.packageType[1] : priceData.packageType[0]
              }
              isLight={priceData.id === 2 ? true : false}
              isToggled={isToggled}
              page={page}
              disabled={
                user
                  ? priceData.h5Text.toLowerCase() == user.plan.toLowerCase()
                  : false
              }
            />
            <div className="flex flex-col gap-3 pt-2">
              {priceData.benefits.map((benefit) => (
                <div
                  key={benefit.id}
                  className="flex flex-row gap-3 pl-1 md:pl-0"
                >
                  <div className="flex items-center">
                    {createElement(
                      benefit.icon == "FaCheckCircle"
                        ? FaCheckCircle
                        : FaTimesCircle,
                      {
                        className: `${
                          priceData.isWhite
                            ? `${isToggled ? "text-white" : "text-black"}`
                            : `${isToggled ? "text-black" : "text-white"}`
                        }`,
                      }
                    )}
                  </div>
                  <p className="text-sm font-nunitoRegular">{benefit.text}</p>
                </div>
              ))}
            </div>

            {!page && index !== 0 && (
              <BonusNotification isFreeText={"7 Days Free Trial"} />
            )}
          </div>
        ))}
      </main>
    </section>
  );
}

Button.propTypes = {
  btnText: PropTypes.string,
  isLight: PropTypes.bool,
  isToggled: PropTypes.bool,
};

PricingCard.propTypes = {
  isToggled: PropTypes.bool,
  loading: PropTypes.bool,
};

export default PricingCard;
