import uploadedImageThumbnail from '../assets/images/img-icon.png';
import uploadedVideoThumbnail from '../assets/images/video-icons.png';
import projectIcon from '../assets/images/project-img.jpg';

const recentActivitiesData = [
  {
    id: 1,
    type: 'project',
    name: 'Project A',
    timestamp: new Date('2024-03-18T09:30:00'),
    thumbnail: projectIcon,
  },
  {
    id: 2,
    type: 'upload',
    name: 'Uploaded Image',
    timestamp: new Date('2024-03-17T15:45:00'),
    thumbnail: uploadedImageThumbnail,
  },
  {
    id: 3,
    type: 'project',
    name: 'Project B',
    timestamp: new Date('2024-03-16T11:20:00'),
    thumbnail: projectIcon,
  },
  {
    id: 4,
    type: 'upload',
    name: 'Uploaded Video',
    timestamp: new Date('2024-03-15T14:10:00'),
    thumbnail: uploadedVideoThumbnail,
  },
  {
    id: 5,
    type: 'project',
    name: 'Project C',
    timestamp: new Date('2024-03-14T10:00:00'),
    thumbnail: projectIcon,
  },
];

export { recentActivitiesData };
