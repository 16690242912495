import PropTypes from "prop-types";

function InputField({
  type,
  placeholder,
  disabled,
  value,
  onInputChange,
  tag,
}) {
  return (
    <input
      type={type}
      placeholder={placeholder}
      className="flex-1 text-black rounded-sm px-3 py-3 placeholder:font-nunitoRegular"
      disabled={disabled}
      value={value}
      onChange={(e) => onInputChange(e.target.value, tag)}
    />
  );
}

InputField.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
};

export default InputField;
