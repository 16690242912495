import TopSection from "../globals/TopSection";

// Hide the '-' symbol with logic
function TopPricingSection() {
  return (
    <main className="bg-generalBlack">
      <div className="mx-auto w-full md:w-3/4 px-4 md:px-0">
        <TopSection heroText="Awesome features for skilled-creators" isPricingPage />
        <div className="pt-24 md:pt-16">
          <h4 className="text-xl text-white font-nunitoRegular text-center">Choose a plan that&apos;s right for you</h4>
        </div>
      </div>
    </main>
  );
}

export default TopPricingSection;
