import AllDesignsRootLayout from '../components/dashboard-components/AllDesignsRootLayout';

function AllDesignsSearchPage() {
  return (
    <AllDesignsRootLayout>
      <div className="ml-[18%] p-4 font-nunitoSemiBold text-white">AllDesignsSearchPage</div>
    </AllDesignsRootLayout>
  );
}

export default AllDesignsSearchPage;
