import blessingImg from '../assets/images/mrs-blessing-img.jpg';
import emmanuelImg from '../assets/images/mr-emmanuel-osayede.jpg';
import mosesImg from '../assets/images/mr-moses-irimiya.jpg';

const ourSummaryData = [
  {
    id: 1,
    cardHeader: '2024',
    cardParagraph: 'founded',
  },
  // {
  //   id: 2,
  //   cardHeader: '$203m',
  //   cardParagraph: 'in funding',
  // },
  {
    id: 3,
    cardHeader: '100k',
    cardParagraph: 'users',
  },
  // {
  //   id: 4,
  //   cardHeader: '350k',
  //   cardParagraph: 'companies',
  // },
];

const topTeamsData = [
  {
    id: 1,
    bgImg: 'bg-clientFirstBg',
  },
  {
    id: 2,
    bgImg: 'bg-clientSecondBg',
  },
  {
    id: 3,
    bgImg: 'bg-clientThirdBg',
  },
  {
    id: 4,
    bgImg: 'bg-clientFourthBg',
  },
  {
    id: 5,
    bgImg: 'bg-clientFifthBg',
  },
  // {
  //   id: 6,
  //   bgImg: 'bg-clientSixthBg',
  // },
  // {
  //   id: 7,
  //   bgImg: 'bg-clientSeventhBg',
  // },
  // {
  //   id: 8,
  //   bgImg: 'bg-clientEightBg',
  // },
];

const paragraphs = [
  {
    id: 1,
    pText:
      'At SwiftSketch, we are passionate about the transformative power of design. We believe that creativity knows no bounds and that every individual, regardless of their skill level, should have the opportunity to express themselves artistically. With this vision in mind, we have created a platform that empowers users to unleash their imagination and bring their ideas to life',
    color: 'text-white',
  },
  {
    id: 2,
    pText:
      "SwiftSketch is more than just a design tool; it's a gateway to boundless expression. Our user-friendly interface and robust set of features provide a seamless and intuitive experience, allowing both beginners and professionals to craft stunning visuals, captivating animations, and engaging experiences that leave a lasting impact.",
    color: 'text-stemGreen',
  },
  {
    id: 3,
    pText:
      'Simplicity is at the core of SwiftSketch. We believe that design should be accessible and enjoyable for everyone. Our platform streamlines the creative process, making it easy to navigate, experiment, and iterate. With a wealth of customizable templates, a vast library of design assets, and a wide range of editing tools, SwiftSketch offers endless possibilities for realizing your creative vision.',
    color: 'text-white',
  },
];

const ourTeamData = [
  {
    id: 1,
    avatar: blessingImg,
    name: 'Blessing Emmanuel Jaja',
    position: 'Software Developer',
    // bioText: 'See Bio',
    // bioLink: '/',
  },
  {
    id: 2,
    avatar: emmanuelImg,
    name: 'Emmanuel Osayede',
    position: 'CEO',
    // bioText: 'See Bio',
    // bioLink: '/',
  },
  {
    id: 3,
    avatar: mosesImg,
    name: 'Moses Irimiya',
    position: 'Social Media Manager',
    // bioText: 'See Bio',
    // bioLink: '/',
  },
  // {
  //   id: 4,
  //   avatar: blessingImg,
  //   name: 'Michael Adams',
  //   position: 'CTO',
  //   bioText: 'See Bio',
  //   bioLink: '/',
  // },
  // {
  //   id: 5,
  //   avatar: blessingImg,
  //   name: 'Michael Adams',
  //   position: 'CTO',
  //   bioText: 'See Bio',
  //   bioLink: '/',
  // },
  // {
  //   id: 6,
  //   avatar: blessingImg,
  //   name: 'Michael Adams',
  //   position: 'CTO',
  //   bioText: 'See Bio',
  //   bioLink: '/',
  // },
];

export { ourSummaryData, topTeamsData, paragraphs, ourTeamData };
