import { Link, useNavigate } from "react-router-dom";
import brandLogo from "../../assets/images/swiftsketch-desktop-colored.png";
import { SearchIcon } from "../../assets/svgs/svgs";
import UserProfile from "./UserProfile";
import { FaCrown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { closeSidebar, openSidebar } from "../../redux/actions";
import mobileLogo from "../../assets/images/swiftsketch-mobile-colored.png";
import { VscLayoutSidebarLeft, VscLayoutSidebarLeftOff } from "react-icons/vsc";
import { jwtDecode } from "jwt-decode";
import useResize from "../../custom-hooks/useResize";

function AllDesignsHeader({ setModal, modal }) {
  const decoded = jwtDecode(localStorage.getItem("token"));
  const { isLeftSidebarOpen } = useSelector((state) => state.leftSidebar);
  const { width, breakpoint, isOpen, setIsOpen, handleMenubarToggle } =
    useResize();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const navigateToSubSription = () => {
    navigate("/dashboard/settings/subscriptions/details");
  };
  return (
    <main className="bg-[#0D0F10] fixed z-[999] left-0 w-screen font-nunitoRegular py-1">
      <section className="px-5 m-2 flex items-center justify-between gap-20">
        <aside className="flex-1 text-white flex justify-between">
          <div className="flex gap-4">
            {width < breakpoint ? (
              <div onClick={() => setModal(!modal)}>
                <img
                  src={width < breakpoint ? mobileLogo : brandLogo}
                  alt="Swiftsketch brand Logo"
                  className="h-10"
                />
              </div>
            ) : (
              <Link to="/dashboard">
                <img
                  src={width < breakpoint ? mobileLogo : brandLogo}
                  alt="Swiftsketch brand Logo"
                  className="h-10"
                />
              </Link>
            )}

            <div className="translate-x-[0.4rem] pr-2 flex items-center justify-end w-fit h-10 bg-transparent text-stemGreen hover:cursor-pointer">
              {isLeftSidebarOpen ? (
                <VscLayoutSidebarLeftOff
                  size={28}
                  onClick={() => {
                    dispatch(closeSidebar());
                    navigate("/dashboard");
                  }}
                />
              ) : (
                <VscLayoutSidebarLeft
                  size={28}
                  onClick={() => {
                    dispatch(openSidebar());
                    navigate("/dashboard/preferences");
                  }}
                />
              )}
            </div>
          </div>

          {/* <div className="flex space-x-2 relative">
            <input
              type="text"
              placeholder="Search Dashboard.."
              className="bg-gray-700 w-[120px] md:w-full py-3 md:flex font-nunitoLight text-sm text-white px-2 md:py-1 rounded-md outline-none border-none"
            />
            <div className="bg-gray-700 text-white px-3 py-1 rounded-md flex justify-center items-center cursor-pointer">
              <SearchIcon />
            </div>
          </div> */}
        </aside>

        <aside className="py-2 px-2 flex items-center justify-center gap-4 bg-transparent hover:cursor-pointer">
          <UserProfile />
          {!decoded.isPremium && width > breakpoint && (
            <div
              className="rounded shadow-md p-3 bg-stemGreen text-white text-sm flex gap-3 items-center"
              onClick={navigateToSubSription}
            >
              <span>Upgrade to premium</span>
              <FaCrown size={20} className="mr-2 text-lg" />
            </div>
          )}
        </aside>
      </section>
    </main>
  );
}

export default AllDesignsHeader;
