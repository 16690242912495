import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { allDesignsSettingsTabData } from "../../data/allDesigns-page-data";
import AllDesignsRootLayout from "./AllDesignsRootLayout";

function AllDesignsSettingsRootLayout({ children, checkout }) {
  const location = useLocation();
  const { pathname } = location;

  return (
    <AllDesignsRootLayout>
      <section className="ml-[22%] flex justify-start items-center font-nunitoSemiBold text-white text-sm px-2">
        {!checkout && (
          <aside className="flex justify-between gap-5">
            {allDesignsSettingsTabData.map((tabData) => (
              <Link
                key={tabData.id}
                to={tabData.tabPath}
                className={`${
                  pathname === tabData.tabPath && "border-b-2 border-stemGreen"
                } py-3 px-4`}
              >
                {tabData.tabText}
              </Link>
            ))}
          </aside>
        )}
      </section>

      <section className="px-2 font-nunitoRegular">{children}</section>
    </AllDesignsRootLayout>
  );
}

AllDesignsSettingsRootLayout.propTypes = {
  children: PropTypes.element,
};

export default AllDesignsSettingsRootLayout;
