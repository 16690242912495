import { useSelector } from "react-redux";
import AllDesignsRootLayout from "../components/dashboard-components/AllDesignsRootLayout";
import DashboardHeaderText from "../components/dashboard-components/DashboardHeaderText";
import uploadedImageThumbnail from "../assets/images/img-icon.png";
import projectIcon from "../assets/images/project-img.jpg";
import uploadedVideoThumbnail from "../assets/images/video-icons.png";
import { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { getRecent } from "../baseURL";

// Extra details should be added to each recentActivity object: To be done by @Lanre
const RecentActivityModal = ({
  selectedActivity,
  handleDelete,
  closeModal,
}) => (
  <aside className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 font-nunitoRegular">
    <div className="bg-white rounded-lg p-8">
      <h2 className="text-lg text-center font-semibold mb-4">
        {selectedActivity.name}
      </h2>
      <p className="text-sm text-gray-500 mb-4">
        {selectedActivity.type === "project" ? "Project" : "Upload"} |{" "}
        {selectedActivity.time.toLocaleString()}
      </p>
      <div className="flex justify-center mt-6">
        <button
          onClick={handleDelete}
          className="mr-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Delete
        </button>
        <button
          onClick={closeModal}
          className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
        >
          Close
        </button>
      </div>
    </div>
  </aside>
);

function AllDesignsRecentsPage() {
  const auth = localStorage.getItem("token");
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [recentActivities, setRecentActivities] = useState([]);

  const { isPending, error, data } = useQuery({
    queryKey: ["recents"],
    queryFn: async () => {
      const response = await getRecent(auth);
      setRecentActivities(response.data);
      return response.data;
    },
  });
  function formatDate(isoString) {
    const date = new Date(isoString);
    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getUTCFullYear();

    const ordinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${ordinalSuffix(day)} ${month} ${year}`;
  }

  console.log(recentActivities);

  const openModal = (activity) => {
    setSelectedActivity(activity);
  };

  const closeModal = () => {
    setSelectedActivity(null);
  };

  const handleDelete = () => {
    // Implement delete functionality here
    console.log("Deleting activity:", selectedActivity);
    // You can dispatch an action here to delete the selected activity
    closeModal();
  };

  return (
    <AllDesignsRootLayout>
      <section className="md:ml-[18%] sm:ml-0 px-4 pb-4 text-white font-nunitoRegular">
        <aside className=" pt-8 pb-10 flex justify-between">
          <DashboardHeaderText text="Recent Activities" />
          <div className="flex space-x-2">
            <input
              type="text"
              placeholder="Search Recents.."
              className="text-generalBlack border border-gray-300 p-2 font-nunitoRegular text-sm rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button className="bg-stemGreen text-white text-sm px-4 py-2 rounded">
              Search
            </button>
          </div>
        </aside>
        <aside className="flex-grow overflow-y-auto flex flex-col gap-3 px-4">
          {recentActivities.length > 0 ? (
            recentActivities.map((activity) => (
              <div
                key={activity.id}
                className="flex items-center p-4 border rounded-md border-[#FF6384FF]"
              >
                <div className="flex-shrink-0 mr-4">
                  {activity.type === "project" ? (
                    <img
                      src={projectIcon}
                      alt="Project Thumbnail"
                      className="w-12 h-12 rounded-full"
                    />
                  ) : (
                    <img
                      src={
                        activity.subtype == "video"
                          ? uploadedVideoThumbnail
                          : uploadedImageThumbnail
                      }
                      alt="Upload Thumbnail"
                      className="w-12 h-12 rounded-full"
                    />
                  )}
                </div>
                <div className="flex-grow">
                  <h3 className="text-lg font-semibold text-[#FF6384FF]">
                    {activity.Activity}
                  </h3>
                  <p className="text-xs text-gray-500">
                    {activity.subtype === "video" ? "Video" : "Image"} |{" "}
                    {formatDate(activity.time)}
                  </p>
                </div>
                {/* <button
                  onClick={() => openModal(activity)}
                  className="text-sm text-gray-500 hover:text-gray-700 focus:outline-none focus:text-gray-700"
                >
                  View Details
                </button> */}
              </div>
            ))
          ) : (
            <div className="mt-24">
              <p className="text-stemGreen text-center">
                No Activities Recorded
              </p>
            </div>
          )}
        </aside>
      </section>

      {selectedActivity && (
        <RecentActivityModal
          selectedActivity={selectedActivity}
          handleDelete={handleDelete}
          closeModal={closeModal}
        />
      )}
    </AllDesignsRootLayout>
  );
}

RecentActivityModal.propTypes = {
  selectedActivity: PropTypes.object,
  handleDelete: PropTypes.func,
  closeModal: PropTypes.func,
};

export default AllDesignsRecentsPage;
