import { createElement } from "react";
import { ClockSvg, DocumentSvg, SearchIcon } from "../assets/svgs/svgs";
import { IoGridOutline } from "react-icons/io5";
import { FaCog } from "react-icons/fa";
import { FaMoneyBills } from "react-icons/fa6";
import { BsImage } from "react-icons/bs";
import { BiVideoRecording } from "react-icons/bi";

const allDesignsSidebarData = [
  {
    id: 1,
    text: "Preferences",
    path: "/dashboard/preferences",
  },
  // {
  //   id: 2,
  //   text: "Templates",
  //   path: "/dashboard/templates",
  // },
  {
    id: 3,
    text: "Account and Settings",
    path: "/dashboard/settings/subscriptions/details",
  },
  // {
  //   id: 4,
  //   text: "Uploads",
  //   path: "/dashboard/uploads",
  // },
  // {
  //   id: 5,
  //   text: "Downloads",
  //   path: "/dashboard/downloads",
  // },
];

const allDesignsDefaultSidebarData = [
  {
    id: 1,
    text: "Overview",
    icon: createElement(IoGridOutline),
    path: "/dashboard",
  },
  // {
  //   id: 2,
  //   text: "Search",
  //   icon: createElement(SearchIcon),
  //   path: "/dashboard/search",
  // },
  {
    id: 3,
    text: "Image Editor",
    icon: createElement(BsImage),
    path: "/image-editor",
  },
  {
    id: 4,
    text: "Video Editor",
    icon: createElement(BiVideoRecording),
    path: "/video-editor",
  },
  {
    id: 5,
    text: "Recents",
    icon: createElement(ClockSvg),
    path: "/dashboard/recents",
  },
  {
    id: 6,
    text: "Projects",
    icon: createElement(DocumentSvg),
    path: "/dashboard/projects",
  },
];

const allDesignsSettingsFormData = [
  {
    name: "Name",
    type: "name",
    placeholder: "Name",
    key: "name",
  },
  {
    name: "Email address",
    type: "email",
    placeholder: "Email address",
    key: "email",
  },
  {
    name: "Password",
    type: "password",
    placeholder: "New Password",
    key: "newPassword",
  },
  {
    name: "Confirm new password",
    type: "password",
    placeholder: "Confirm New password",
    key: "confirmNewPassword",
  },
];

const borderClasses = {
  newPassword: "border-r-8",
  name: "border-r-8",
  confirmNewPassword: "border-l-8",
  email: "border-l-8",
};

const allDesignsSettingsTabData = [
  // {
  //   id: 1,
  //   tabText: "Settings",
  //   tabPath: "/dashboard/settings",
  // },
  {
    id: 2,
    tabText: "Billings & Subscription",
    tabPath: "/dashboard/settings/subscriptions/details",
  },
  // {
  //   id: 3,
  //   tabText: "Change Password",
  //   tabPath: "/dashboard/settings/password/update",
  // },
  {
    id: 4,
    tabText: "Delete Account",
    tabPath: "/dashboard/settings/account/deletion",
  },
];

const profileCardPathnameArray = [
  "/dashboard/settings/subscriptions/details",
  "/dashboard/settings",
];

const sidebarPathnameArray = [
  "/dashboard/preferences",
  "/dashboard/templates",
  "/dashboard/settings",
  "/dashboard/settings/subscriptions/details",
  "/dashboard/settings/password/update",
  "/dashboard/settings/account/deletion",
  "/dashboard/uploads",
  "/dashboard/downloads",
];

const cardItems = [
  // {
  //   icon: createElement(FaCog, { className: "text-stemGreen mr-2" }),
  //   label: "settings",
  //   path: "/dashboard/settings",
  // },
  {
    icon: createElement(FaMoneyBills, { className: "text-stemGreen mr-2" }),
    label: "Billings",
    path: "/dashboard/settings/subscriptions/details",
  },
];

export {
  allDesignsSidebarData,
  allDesignsDefaultSidebarData,
  allDesignsSettingsFormData,
  borderClasses,
  allDesignsSettingsTabData,
  profileCardPathnameArray,
  cardItems,
  sidebarPathnameArray,
};
