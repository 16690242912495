import { useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { FaAngleLeft } from 'react-icons/fa';
import OptionsDropdown from './OptionsDropdown';

function ExportVideoHeader() {
  const { activeTab } = useSelector((state) => state.videoExportOrShare);
  const [isDropdownIconClicked, setIsDrodownIconClicked] = useState(false);

  const handleDropdownClicked = () => {
    setIsDrodownIconClicked(!isDropdownIconClicked);
  };

  const getDropdownItemClicked = (isClicked) => {
		isClicked && setIsDrodownIconClicked(!isDropdownIconClicked);
  };

  return (
    <section className="relative flex-1 flex items-center justify-between px-4 pt-2">
      <aside className="flex justify-between items-center gap-4">
        <FaAngleLeft className="hover:cursor-pointer text-[#686B6E]" />
        <h3 className="text-white font-nunitoSemiBold text-sm">
          {activeTab === 1 ? 'Save video file' : 'Transfer video file'}
        </h3>
      </aside>
      <aside>
        <div
          className="hover:cursor-pointer hover:bg-[#686B6E]/20 rounded-full p-2"
          onClick={() => handleDropdownClicked()}
        >
          <BsThreeDotsVertical className="text-[#686B6E]" />
        </div>
      </aside>

      {isDropdownIconClicked ? <OptionsDropdown getDropdownItemClicked={getDropdownItemClicked} /> : ''}
    </section>
  );
}

export default ExportVideoHeader;
