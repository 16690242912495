import { useSelector } from 'react-redux';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';
import { transferOrSaveOptions } from '../../data/export-video-data';

function ExportVideoBottom() {
  const { activeTab } = useSelector((state) => state.videoExportOrShare);

  return (
    <section className="flex-1 flex justify-between text-[#686B6E] px-4 pb-3">
      <aside className="flex flex-col gap-2">
        <h5 className="font-nunitoSemiBold">Select export format</h5>
        <div className="flex justify-between gap-4">
          {transferOrSaveOptions.map((options) => (
            <button
              key={options.id}
              className="flex justify-between items-center gap-4 text-[#dbe1ea] border-[#686B6E] hover:bg-stemGreen hover:border-stemGreen border rounded font-nunitoBold px-4 py-2 text-sm"
            >
              {activeTab === 2 && <img src={options.imgSrc} alt={options.altText} height={20} width={20} />}
              {activeTab === 1 ? options.text[0] : options.text[1]}
            </button>
          ))}
        </div>
      </aside>
      <aside className="flex items-center">
        <button className="flex justify-between items-center gap-4 rounded-md bg-stemGreen px-4 py-2">
          <span className="text-[#dbe1ea] font-nunitoSemiBold">Proceed</span>
          <figure className="flex items-center justify-center">
            <IoIosCheckmarkCircleOutline className="text-[#dbe1ea]" />
          </figure>
        </button>
      </aside>
    </section>
  );
}

export default ExportVideoBottom;
