import { createElement } from "react";
import { FaFacebook, FaLinkedin, FaTelegram, FaYoutube } from "react-icons/fa";

const footerTopData = [
  [
    {
      id: 1,
      firstChildIcon: createElement(FaFacebook, {
        className: "mr-2 text-stemGreen",
      }),
      iconSpanText: "Facebook",
    },
    {
      id: 2,
      firstChildIcon: createElement(FaTelegram, {
        className: "mr-2 text-stemGreen",
      }),
      iconSpanText: "Telegram",
    },
  ],
  [
    {
      id: 1,
      h3Text: "COMPANY",
      listItemTexts: ["About us", "Referral program", "Contact us", ""],
    },
    {
      id: 2,
      h3Text: "FEATURES",
      listItemTexts: ["Video editing", "Background Removal", "Svg Editing"],
    },
    {
      id: 3,
      h3Text: "INTEGRATIONS",
      listItemTexts: ["Figma", "Facebook", "Whatsapp", ""],
    },
    {
      id: 4,
      h3Text: "RESOURCES",
      listItemTexts: ["Video Tutorials", "Blog", "", ""],
    },
  ],
];

const footerBaseData = [
  [
    "End User Private Policy",
    "Privacy Policy",
    "Terms & Conditions",
    "DPA",
    "Security",
  ],
  [
    {
      link: "https://www.facebook.com/Swiftsketch.design?mibextid=JRoKGi",
      icon: createElement(FaFacebook, {
        className: "mr-4 text-stemGreen",
        size: 20,
      }),
    },
    // {link:'/', icon:createElement(FaLinkedin, { className: "mr-4 text-stemGreen", size: 20 })},
    {
      link: "https://youtube.com/@swiftsketch.web1?si=XfwypMYPnyb0xmZl",
      icon: createElement(FaYoutube, {
        className: " text-stemGreen",
        size: 21,
      }),
    },
  ],
];

export { footerTopData, footerBaseData };
