import authorOneImg from '../assets/images/author-avatar-1.png';
import authorTwoImg from '../assets/images/author-avatar-2.png';
import authorThreeImg from '../assets/images/author-avatar-3.png';
import authorFourImg from '../assets/images/author-avatar-4.png';
import authorFiveImg from '../assets/images/author-avatar-5.png';

const blogsData = [
  {
    blogId: 20231,
    title: 'The Power of Color Psychology in Design: A Comprehensive Guide',
    isFeatured: true,
    featuredText: 'FEATURED',
    postBg: 'bg-postOneBg',
    authorImg: authorOneImg,
    authorName: 'Ben solomon',
    authorProfession: 'Designer',
    postDate: '02 May, 2024',
    article:
      `
      Color psychology is the study of how colours can influence human behaviour, emotions, and perceptions.
      It's a powerful tool that designers can use to evoke specific feelings, create visual harmony, and convey messages effectively.
      Different colours have different meanings and can evoke various emotional responses. 
      
      Lets Explore The Psychological Associations of some common colours:
      RED EMOTIONS - Passion, energy, love, anger. Often used to grab attention or create a sense of urgency (e.g., sale signs, warnings.)

      BLUE EMOTIONS - Trust, calm, professionalism. Frequently employed in corporate branding to convey reliability and stability.
      
      YELLOW EMOTIONS- Joy, optimism, warmth. Used to create a cheerful and welcoming atmosphere (e.g., in fast-food restaurants).

      GREEN EMOTIONS - Growth, health, tranquility; Associated with nature and used in eco-friendly and health-related designs.

      PURPLE EMOTIONS -Luxury, creativity, spirituality. Often seen in high-end or artistic brands. 

      'USE COLOUR TO EVOKE THE EMOTIONS YOU WANT YOUR AUDIENCE TO FEEL. FOR A CALMING AND SERENE ATMOSPHERE, OPT FOR BLUES AND GREENS
      WHILE BOLD AND CONTRASTING COLOURS LIKE RED AND YELLOW CAN CREATE EXCITEMENT AND ENERGY.'
      `,
      
  },
  {
    blogId: 20232,
    title: 'Animating with Purpose: Elevate Your Designs with Thoughtful Motion',
    isFeatured: true,
    featuredText: 'FEATURED',
    postBg: 'bg-postTwoBg',
    authorImg: authorTwoImg,
    authorName: 'Tonia Eyeghele',
    authorProfession: 'Graphic Designer',
    postDate: '02 May, 2024',
    article:
      `Animation is a remarkable art form that transcends boundaries, allowing creators to delve in, breathe life into characters, 
      objects, and stories. To truly captivate an audience mood, and convey a compelling narrative, animators must learn to animate with 
      purpose and style.
      STORYTELLING - Animations can be powerful tools for storytelling. When animating with purpose, consider how your animations can 
      enhance the narrative, or convey information to the audience in a compelling, fashionable and enriching way. Emotion and Mood- Animations 
      can evoke emotions and set the mood of a scene or project. Engagement and Attention: Animations can capture and maintain the viewer's attention.
      Clarity and Communication: Animations can simplify complex concepts or processes.

      IN CHARACTER ANIMATION - each movement should reveal something about the character's personality, mood, or motivation. This deeper 
      understanding will make you feel soothed, authentic and relatable. Timing and pacing are critical elements in animation. These 
      choices can accentuate emotions, build tension, or create humor. Sometimes, less is more in animation, encouraging viewers to think 
      Animating with purpose often involves incorporating symbolism and metaphor into your work.

      Details matter in animation. From the texture of an object to the background elements, of your animation should contribute to the 
      story's depth. every aspect of animation is often a collaborative effort. While collaboration and feedback are essential, it's 
      equally crucial to stay true to your creative vision. Maintain a balance within external input.
      `,
  },
  {
    blogId: 20233,
    title: 'Typography Trends: From Classic to Contemporary, Exploring Fonts that Resonate',
    postBg: 'bg-postThreeBg',
    authorImg: authorThreeImg,
    authorName: 'Hinata Sho',
    authorProfession: 'Typography Designer',
    postDate: '02 May, 2023',
    article:
      `
      TYPOGRAPHY, the art of arranging type, has evolved significantly over the years, influencing the way we perceive and interact with 
      written content. In the realm of design, typography plays a crucial role in shaping the visual identity and communication of brands, 
      websites, and various forms of media. From classic to contemporary, let's embark on a journey to explore typography trends and the
      fonts that resonate with today's audiences.

      CLASSIC FONTS have an enduring charm that transcends trends. Serif fonts like TIMES NEW ROMAN and GARAMOND evoke a sense of 
      tradition and authority. Their elegant and legible nature makes them popular choices for formal documents and printed materials. 
      Classic fonts are like the little black dress of typography they never go out of style and can be adapted to various contexts.

      CONTEMPORARY FONTS, also known as modern or current fonts, they reflect the design trends and aesthetics of the present day. 
      These fonts often break away from traditional styles and embrace a more innovative, clean, and minimalistic approach.
      communication of brands, websites, and various forms of media. From classic to contemporary, let's embark on a journey to explore 
      typography trends and the fonts that resonate with today's audiences.

      SERIF FONTS- Classic serif fonts like Times New Roman, Garamond, and Baskerville have timeless appeal.
      SANS-SERIF Fonts- Helvetica, Arial, and Futura are examples of timeless sans-serif fonts.

      They are known for their clean, modern look and readability, making them suitable.

      SCRIPT FONT-: Script fonts, like the elegant and flowing calligraphy styles, bring a touch of personality and elegance to designs. 
      They are often used in invitations, branding etc.

      HANDWRITTEN and BRUSH FONTS: Hand-drawn or brush script fonts are popular for their organic and personalized feel. often used in 
      branding, packaging, and social graphics

      VARIABLE FONTS: This is a cutting-edge trend in typography that allows a single font file to contain multiple styles and variations, 
      like weight, With flexibility & responsiveness.


      CLASSIC FONTS like  PALATINO - A legible and versatile serif font suitable for both print and digital media.

      BODONI: A high-contrast serif font with sharp, dramatic strokes. Often used for headlines and posters.


      CONTEMPORARY FONTS like ROBOTO: A modern sans-serif font designed for readability on screens. Popular for web and app design

      POPPINS - A versatile sans-serif font with a friendly and approachable appearance. Popular for branding and digital design. 
      These classic and contemporary fonts represent a range of styles and can be customized to create unique typographic designs that 
      align with your specific project goals.
      `,
  },
  {
    blogId: 20234,
    title: 'Mastering Visual Hierarchy: Guiding Users through Effective Design Composition',
    postBg: 'bg-postFourBg',
    authorImg: authorFourImg,
    authorName: 'Ajala Micheal',
    authorProfession: 'Designer',
    postDate: '02 May, 2023',
    article:
      `
      In the world of design, whether it's graphic design, web design, or even interior design, mastering visual hierarchy is a 
      fundamental skill that can significantly impact the effectiveness of your creations. Visual hierarchy is the arrangement 
      and prioritization of elements within a design to guide the viewer's attention and convey a clear message.

      THE SIGNIFICANCE OF VISUAL HIERARCHY - Visual hierarchy plays a crucial role in design for several reasons; Humans naturally 
      seek order and patterns in what they see. Visual hierarchy acts as a roadmap, directing the viewer's eye to the most important 
      elements in a design. It Communicates Information as it helps to convey the intended message or story. By emphasizing key elements, 
      you ensure that your viewers grasp the primary message or call to action.

      Before you start designing clearly define the purpose of your project and identify your target audience. Use Consistency in design 
      elements, such as fonts, colors, and spacing, creates a cohesive and professional look. It also helps users predict how different 
      parts of the design function. Don't underestimate the power of whitespace (negative space), Adequate spacing. Select a color 
      palette that resonates with your message and audience. Be mindful of color psychology, ensuring that your color choices evoke the 
      desired emotions or reactions.

      Use readable fonts and consider the legibility of text at different sizes. Ensure that users can easily navigate your design. 
      Create a logical flow that guides when designing interactive elements, provide clear feedback to users.

      In Tips for Mastering Visual Hierarchy- PRIORITIZE CONTENT; Begin by identifying the most important elements in your design.
      What is the primary message or information you want to convey? Make these elements the focal points of your design.

      USE TYPOGRAPHY WISELY - Typography is a powerful tool for establishing hierarchy. Adjust font sizes, weights, and styles to 
      differentiate headings, subheadings, and body text.


      Also master The ELEMENTS OF COMPOSITION BALANCE: Balance is the distribution of visual weight in a design. Achieving balance 
      ensures that no single element overwhelms the others. LIKE
      Contrast- Contrast involves the juxtaposition of elements with differing characteristics, such as color, size, shape, or texture.
      `,
  },
  {
    blogId: 20235,
    title: 'Design Made Easy: Crafting User-Centric Experiences',
    postBg: 'bg-postFiveBg',
    authorImg: authorFiveImg,
    authorName: 'Ridwan Oyeniran',
    authorProfession: 'Brand Designer',
    postDate: '02 May, 2023',
    article:
      `
      Design is not just about aesthetics; it's about creating meaningful and user-centric experiences. Crafting centric experiences is 
      the process in creating and designing experiences that are focused on craftsmanship, designing experiences attention to detail, 
      and a high level of quality.

      Whether you're designing a product, a website, or any form of user interface, putting the user at the center of your design process 
      is paramount. Let's explore how to make design easier by focusing on crafting centric experiences that resonate with users.

      (1.) USER-CENTERED DESIGN (UCD) - User-Centered Design (UCD) is the foundation of crafting centric experiences.

      (2.) Simplicity and Clarity - Keep your designs simple and straightforward. Avoid unnecessary complexity, which can confuse users.

      With VISUAL HIERARCHY - you can establish a clear visual hierarchy by using contrast, typography, and layout. Guide users' 
      attention to the most critical elements and actions on the page;

      Maintain CONSISTENCY in design elements, such as colors, fonts, and navigation, throughout your project. Consistency fosters 
      familiarity and a sense of reliability.

      Remember that design is a creative process, and it's okay to iterate and make changes as you go along. Don't be afraid to 
      experiment and think outside the box. As you gain experience, design will become more intuitive, and the process will become easier.

      Remember, design is not just about making things look good; it's about making things work well for the people who use them.
      `,
  },
];

export { blogsData };
