import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function CustomArrow(props) {
  const { className, style, onClick, direction } = props;
  const [width, setWidth] = useState(window.innerWidth);
  const breakPointVal = 609;

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const arrowStyle =
    width <= breakPointVal
      ? {
          ...style,
          display: 'block',
          background: 'black',
          borderRadius: '50%',
          transform: `translate(${direction === 'prev' ? '-50' : '50'}px, 0)`,
          zIndex: '999',
        }
      : { ...style, display: 'block', background: 'black', borderRadius: '50%' };

  return <div className={className} style={arrowStyle} onClick={onClick} />;
}

CustomArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  direction: PropTypes.oneOf(['prev', 'next']),
};

function PrevArrow(props) {
  return <CustomArrow {...props} direction="prev" />;
}

function NextArrow(props) {
  return <CustomArrow {...props} direction="next" />;
}

export { PrevArrow, NextArrow };
