import HeroSection from "../components/home-components/HeroSection";
import PricingSection from "../components/home-components/PricingSection";
import AppDescription from "../components/home-components/AppDescription";
import FAQs from "../components/home-components/FAQs";
import Tutorials from "../components/home-components/Tutorials";
import Features from "../components/home-components/Features";
import Testimonials from "../components/home-components/Testimonials";
import { useEffect } from "react";
import { getPackages } from "../baseURL";
import { useDispatch } from "react-redux";
import { setPackages } from "../redux/slices/packageSlice";

function HomePage() {
  return (
    <section className="w-full overflow-x-hidden">
      <HeroSection />
      <AppDescription />
      <Features />
      <Tutorials />
      <Testimonials />
      <FAQs />
      <PricingSection />
    </section>
  );
}

export default HomePage;
