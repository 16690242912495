import PropTypes from 'prop-types';
import Button from './Button';

function TopSection(props) {
  const isArray = props.heroText.split(' ');
  const isSplittedArr = [isArray.slice(0, -1).join(' '), isArray[isArray.length - 1]];

  return (
    <section>
      <main
        className={`${props.hasBtnContainer ? 'h-[40rem] lg:h-[35rem]' : 'h-[27rem] lg:h-[23rem]'} pt-24 ${
          props.isPricingPage ? 'md:pt-16' : props.isFeaturesPage ? 'md:pt-8' : 'md:pt-32'
        } ${
          props.isFeaturesPage
            ? 'xl:mt-[8.82rem] md:pt-0 bg-featuresBg bg-contain bg-bottom bg-blend-overlay'
            : 'bg-cover bg-center bg-blend-multiply'
        } bg-generalBlack bg-no-repeat min-w-full md:h-[40vh]`}
      >
        <div className="container mx-auto flex flex-col h-full md:gap-4 py-12 md:py-6">
          <div className="flex flex-col gap-6 md:gap-2 md:pt-20">
            <h2
              className={`leading-[64px] ${
                props.isPricingPage ? 'lg:leading-[86px]' : 'lg:leading-[60px]'
              } mx-auto row-span-1 text-white text-5xl text-center font-nunitoExtraBold md:text-5xl lg:w-[100%] lg:text-[64px] lg:tracking-[.01em] pt-12`}
            >
              {isSplittedArr[0]} <span className="text-orange">{isSplittedArr[1]}</span>
            </h2>
          </div>

          {props.hasBtnContainer && (
            <div className="pb-[4rem] flex items-center justify-center md:items-start pt-12 md:pb-8">
              <Button btnText="Get Started" to="/" isLight isBig />
            </div>
          )}
        </div>
      </main>
    </section>
  );
}

TopSection.propTypes = {
  heroText: PropTypes.string,
  isColoredHeroText: PropTypes.bool,
  hasBtnContainer: PropTypes.bool,
  isPricingPage: PropTypes.bool,
  isFeaturesPage: PropTypes.bool,
};

export default TopSection;
