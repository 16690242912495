import { jwtDecode } from "jwt-decode";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CheckPage = () => {
  const navigate = useNavigate();
  const { check } = useParams();
  function base64UrlDecode(str) {
    return atob(str); // Decode the base64 URL-safe string
  }
  useEffect(() => {
    const decoded = jwtDecode(base64UrlDecode(check));
    localStorage.setItem("userswiftsketch", JSON.stringify(decoded));
    localStorage.setItem("token", base64UrlDecode(check));
    navigate("/dashboard");
  });

  return null;
};

export default CheckPage;
