import PropTypes from 'prop-types';

function VideoWrapper({ videoSrc }) {
  return (
    <div className="flex-1 flex items-center shadow-2xl">
      <video className="w-full h-[390px] rounded" controls>
        <source src={videoSrc} type="video/mp4" />
      </video>
    </div>
  );
}

VideoWrapper.propTypes = {
  videoSrc: PropTypes.string,
};

export default VideoWrapper;
