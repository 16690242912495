import { useEffect, useState } from "react";
import Chart from "chart.js/auto";
// import { chartConfigs, chartsData } from "../../data/charts-data";
import DashboardHeaderText from "./DashboardHeaderText";
import { getOverview, getSizeOverview, getUploadOverview } from "../../baseURL";
import { Bar, Line } from "react-chartjs-2";
// import { getOverview, getSizeOverview, getUploadOverview } from "../baseURL";
let initial = true;

const DashboardOverview = () => {
  const [dataMonth, setDataMonth] = useState([]);
  const [dataValue, setDataValue] = useState([]);
  const [dataVideoValue, setDataVideoValue] = useState([]);
  const [sizeMonth, setSizeMonth] = useState([]);
  const [sizeImageValue, setSizeImageValue] = useState([]);
  const [sizeVideoValue, setSizeVideoValue] = useState([]);
  const [uploadMonth, setUploadMonth] = useState([]);
  const [uploadImageValue, setUploadImageValue] = useState([]);
  const [uploadVideoValue, setUploadVideoValue] = useState([]);

  const colors = {
    generalBlack: "#1A1D21",
    stemGreen: "#800190",
    hoverGreen: "#a700b5",
    nobleBlack: "#686B6E",
    semiDark: "#686B6E",
    readyBgBlend: "rgba(0, 0, 0, .8)",
    orange: "#800190",
    blendClr: "hsla(0, 0%, 0%, 0.3)",
    editorLight: "#131619",
    editorDeep: "#0D0F10",
    editorMain: "#1A1D21F5",
  };

  const chartColors = [
    colors.generalBlack,
    colors.stemGreen,
    colors.hoverGreen,
    colors.nobleBlack,
    colors.semiDark,
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const token = localStorage.getItem("token");

  // console.log(uploadMonth);
  // console.log(uploadImageValue);
  // console.log(uploadVideoValue);

  const chartConfigs = [
    {
      id: "storageUsageChart",
      type: "bar",
      data: {
        labels: sizeMonth,
        datasets: [
          {
            label: "Storage Usage Images (MB)",
            data: sizeImageValue,
            backgroundColor: chartColors[0],
            borderColor: chartColors[1],
            borderWidth: 1,
            hoverBackgroundColor: chartColors[2],
            hoverBorderColor: chartColors[1],
          },
          {
            label: "Storage Usage Videos (MB)",
            data: sizeVideoValue,
            backgroundColor: "rgba(255, 99, 132, 0.6)",
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255, 99, 132, 0.8)",
            hoverBorderColor: "rgba(255, 99, 132, 1)",
          },
        ],
      },
    },
    {
      id: "totalDownloadsChart",
      type: "bar",
      data: {
        labels: dataMonth,
        datasets: [
          {
            label: "Total Downloads (Images)",
            data: dataValue,
            backgroundColor: chartColors[0],
            borderColor: chartColors[1],
            borderWidth: 1,
            hoverBackgroundColor: chartColors[2],
            hoverBorderColor: chartColors[1],
          },
          {
            label: "Total Downloads (Videos)",
            data: dataVideoValue,
            backgroundColor: "rgba(255, 99, 132, 0.6)",
            borderColor: "rgba(255, 99, 132, 1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(255, 99, 132, 0.8)",
            hoverBorderColor: "rgba(255, 99, 132, 1)",
          },
        ],
      },
    },
    {
      // id: "totalUploadsChart",
      // type: "line",
      data: {
        labels: uploadMonth,
        datasets: [
          {
            label: "Total Uploads (Images)",
            data: uploadImageValue,
            fill: false,
            tension: 0.1,
            backgroundColor: chartColors[0],
            borderColor: chartColors[1],
            pointBackgroundColor: chartColors[1],
            pointHoverBackgroundColor: chartColors[1],
          },
          {
            label: "Total Uploads (Videos)",
            data: uploadVideoValue,
            fill: false,
            tension: 0.1,
            backgroundColor: "rgba(255, 99, 132, 0.6)",
            borderColor: "rgba(255, 99, 132, 1)",
            pointBackgroundColor: "rgba(255, 99, 132, 1)",
            pointHoverBackgroundColor: "rgba(255, 99, 132, 1)",
          },
        ],
      },
    },
    {
      id: "totalProjectsChart",
      type: "doughnut",
      data: {
        labels: [
          "Total Projects (Images)",
          "Completed Projects (Images)",
          "Uncompleted Projects (Images)",
          "Total Projects (Videos)",
          "Completed Projects (Videos)",
          "Uncompleted Projects (Videos)",
        ],
        datasets: [
          {
            data: [12, 5, 7, 8, 3, 5],
            backgroundColor: [
              ...chartColors.slice(0, 3),
              "rgba(255, 99, 132, 0.6)",
            ],
            borderColor: [...chartColors.slice(0, 3), "rgba(255, 99, 132, 1)"],
            borderWidth: 1,
          },
        ],
      },
    },
    {
      id: "totalTemplatesUsedChart",
      type: "doughnut",
      data: {
        labels: ["Images", "Videos"],
        datasets: [
          {
            data: [30, 20],
            backgroundColor: chartColors.slice(0, 2),
            borderColor: chartColors.slice(0, 2),
            borderWidth: 1,
          },
        ],
      },
    },
  ];

  const chartsData = [
    {
      id: "storageUsageChart",
      label: "Storage Usage",
    },
    {
      id: "totalDownloadsChart",
      label: "Total Downloads",
    },
    {
      id: "totalUploadsChart",
      label: "Total Uploads",
    },
    {
      id: "totalProjectsChart",
      label: "Total Projects",
    },
    {
      id: "totalTemplatesUsedChart",
      label: "Total Templates Used",
    },
  ];
  const initializeCharts = () => {
    const getInfo = async () => {
      initial = false;
      setDataMonth([]);
      setDataValue([]);
      setDataVideoValue([]);
      setSizeImageValue([]);
      setSizeMonth([]);
      setSizeVideoValue([]);
      setUploadImageValue([]);
      setUploadMonth([]);
      setUploadVideoValue([]);
      try {
        let arr = await getOverview(token);
        let arr2 = await getSizeOverview(token);
        let arr3 = await getUploadOverview(token);
        arr = arr.data;
        arr2 = arr2.data;
        arr3 = arr3.data;
        // console.log("arr", arr);
        for (let i = 0; i < arr.images.length; i++) {
          setDataMonth((prev) => [...prev, months[arr.images[i].month - 1]]);
          setDataValue((prev) => [...prev, arr.images[i].count]);
        }
        for (let i = 0; i < arr.videos.length; i++) {
          setDataVideoValue((prev) => [...prev, arr.videos[i].count]);
        }

        //size loop
        for (let i = 0; i < arr2.images.length; i++) {
          setSizeMonth((prev) => [...prev, months[arr2.images[i].month - 1]]);
          setSizeImageValue((prev) => [...prev, arr2.images[i].totalSize]);
        }
        for (let i = 0; i < arr2.videos.length; i++) {
          setSizeVideoValue((prev) => [...prev, arr2.videos[i].totalSize]);
        }

        //size loop
        for (let i = 0; i < arr3.images.length; i++) {
          setUploadMonth((prev) => [...prev, months[arr3.images[i].month - 1]]);
          setUploadImageValue((prev) => [...prev, arr3.images[i].count]);
        }
        for (let i = 0; i < arr3.videos.length; i++) {
          setUploadVideoValue((prev) => [...prev, arr3.videos[i].count]);
        }
      } catch (e) {
        // console.log(e);
      }
    };
    if (initial) {
      getInfo();
    }
  };

  useEffect(initializeCharts, []);

  return (
    <main className="md:ml-[20%] sm:ml-0">
      <section className="px-5 pt-8 pb-10">
        <DashboardHeaderText text="Overview" />
      </section>
      <section className="px-4 pb-12 gap-12 dashboard-charts-container font-nunitoRegular text-stemGreen flex flex-wrap border-y-12 border-editorMain">
        <div className="chart-container md:w-[calc(48.3%-1.8rem)] flex flex-col gap-4">
          <Bar data={chartConfigs[0].data} height={300} />
          <div className="chart-label font-nunitoBold text-sm pl-4">
            {chartsData[0].label}
          </div>
        </div>
        <div className="chart-container md:w-[calc(48.3%-1.8rem)] flex flex-col gap-4">
          <Bar data={chartConfigs[1].data} height={300} />
          <div className="chart-label font-nunitoBold text-sm pl-4">
            {chartsData[1].label}
          </div>
        </div>
        <div className="chart-container md:w-[calc(48.3%-1.8rem)] flex flex-col gap-4">
          <Line data={chartConfigs[2].data} height={300} />
          <div className="chart-label font-nunitoBold text-sm pl-4">
            {chartsData[2].label}
          </div>
        </div>
      </section>
    </main>
  );
};

export default DashboardOverview;
