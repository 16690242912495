import PropTypes from 'prop-types';

function HeaderSection({ text, isLoginPage }) {
  return (
    <div className="mb-8">
      {isLoginPage && <h2 className="text-white text-4xl leading-snug font-nunitoExtraBold pb-4">Hello</h2>}
      <h4
        className={`${
          isLoginPage
            ? 'text-white text-base leading-snug font-nunitoRegular'
            : 'text-4xl leading-snug font-nunitoExtraBold'
        } text-white`}
      >
        {text}
      </h4>
    </div>
  );
}

HeaderSection.propTypes = {
  text: PropTypes.string,
  isLoginPage: PropTypes.bool,
};

export default HeaderSection;
