import GoBack from '../globals/GoBack';
import PropTypes from 'prop-types';

function ForgotPasswordTop({ h1Text, h4Text, route }) {
  return (
    <div>
      <section className="mb-6">
        <GoBack route={route} />
      </section>
      <section className="text-white flex flex-col gap-3">
        <h1 className="text-4xl font-nunitoExtraBold whitespace-nowrap">{h1Text}</h1>
        <h4 className="text-base font-nunitoRegular opacity-75">{h4Text}</h4>
      </section>
    </div>
  );
}

ForgotPasswordTop.propTypes = {
  h1Text: PropTypes.string,
  h4Text: PropTypes.string,
  route: PropTypes.string,
};
export default ForgotPasswordTop;
