import PropTypes from 'prop-types';
import { termsAndConditionsData } from '../../data/termsAndConditionsData';

function PolicyContent({ stepNum }) {
  return (
    <div className="mx-auto mb-10 text-justify">
      <div className="text-white leading-8 font-nunitoRegular">
        <p>{`${
          stepNum === 1
            ? termsAndConditionsData[0]
            : stepNum === 2
            ? termsAndConditionsData[1]
            : termsAndConditionsData[2]
        }`}</p>
      </div>
    </div>
  );
}

PolicyContent.propTypes = {
  stepNum: PropTypes.number,
};

export default PolicyContent;
