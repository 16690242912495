import NavbarHeader from "../nav-components/NavbarHeader";
import NavigationBarMain from "../nav-components/NavigationBarMain";

function Navbar() {
  return (
    <div className="w-full fixed left-0 top-0 z-50">
      <NavbarHeader />
      <NavigationBarMain />
    </div>
  );
}

export default Navbar;
